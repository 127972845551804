import { apiCall } from '../../../services/api'


export function fetchUserInfo(token, userId, months) {
  return apiCall(`/api/info/${userId}?months=${months.months}`, 'get', token)
}

export function getCustomers(token, data) {
  return apiCall(`/api/sharedcustomers?crit=${data}`, 'get', token)
}

export function saveCustomer(token, data) {
  return apiCall(`/api/person/${data.personId}/sharedcustomers/${data.customerId}`, 'post', token, data)
}

export function deleteCustomer(token, data) {
  return apiCall(`/api/person/${data.personId}/sharedcustomers/${data.customerId}`, 'delete', token)
}

export function getTaxInfo(token, userId, year) {
  return apiCall(`/api/info/${userId}/taxinfo/${year}`, 'get', token)
}
