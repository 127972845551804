import React, {Component} from 'react'
import {getTranslate} from 'react-localize-redux'
import {connect} from 'react-redux'
import './client.scss'

class ClientDetails extends Component {

  render() {
    const {translate, data} = this.props

    return (
        <table className="dkblue client-details">
          <thead>
            <tr>
              <td colSpan="2">
                {translate('customers.fields.general')}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span className="">{translate('customers.fields.name')}</span></td>
              <td className="blue">{data.name || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.organization')}</span></td>
              <td>{data.organization || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.businessid')}</span></td>
              <td>{data.businessid || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.vat')}</span></td>
              <td>{data.vat || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.sotu')}</span></td>
              <td>{data.sotu || '-'}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <td colSpan="2">
                {translate('customers.fields.paymentDetails')}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span className="">{translate('customers.fields.netbankaddress')}</span></td>
              <td>{data.netbankaddress || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.operator')}</span></td>
              <td>{data.operator || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.method')}</span></td>
              <td>{data.sendBillMethod ?
                translate('customers.fields.sendBillMethod.' + data.sendBillMethod) : '-'}
              </td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.termofpayment')}</span></td>
              <td>{data.termofpayment || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.referenceNumber')}</span></td>
              <td>{data.referenceNumber || '-'}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <td colSpan="2">
                {translate('customers.fields.contact')}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span className="">{translate('customers.fields.address')}</span></td>
              <td>{data.address || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.postalcode')}</span></td>
              <td>{data.postalcode || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.city')}</span></td>
              <td>{data.city || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.addresscountry')}</span></td>
              <td>{data.addresscountry || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.phonenumber')}</span></td>
              <td>{data.phonenumber || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.email')}</span></td>
              <td>{data.email || '-'}</td>
            </tr>
            <tr>
              <td><span className="">{translate('customers.fields.email2')}</span></td>
              <td>{data.email2 || '-'}</td>
            </tr>
          </tbody>
        </table>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetails)
