import React, { Component } from 'react'
import { connect } from 'react-redux'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'
import 'moment/locale/fi'
import moment from 'moment'
import 'react-day-picker/lib/style.css'
import ReactTooltip from 'react-tooltip'
import { isNumber } from '../../components/Utils/formHelpers'
import { isMobile } from '../Utils/detectMobile'
import { Field, Formik } from 'formik'
import { dateToIsoDate, isoDateToDate } from '../Utils/dateHelpers'

const TextComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input {...field} {...props} type="text" className="form-control input-lg" />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

const DateComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  disabled,
  locale,
  isValid,
  validateForm,
  submit,
  handleChange,
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const changeDate = async (event, setFieldValue, values, submit) => {
    const newDate = event.format('DD.MM.YYYY')
    await setFieldValue('date', newDate)
    await validateForm()
    submit({ ...values, date: newDate })
  }

  const changeMobileDate = (event) => {
    const date = isoDateToDate(event.target.value)
    setFieldValue('date', date)
    submit({ ...values, date })
  }
  if (!isMobile()) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
          <DayPickerInput
            {...field}
            {...props}
            value={values.date}
            dayPickerProps={{ localeUtils: LocaleUtils, locale }}
            readOnly={true}
            disabled={disabled}
            style={{ display: 'block' }}
            className="form-control "
            placeholder="DD.MM.YYYY"
            format="DD.MM.YYYY"
            onDayChange={(e) => changeDate(e, setFieldValue, values, submit)}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  } else {
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <input
            type="date"
            {...field}
            {...props}
            value={dateToIsoDate(field.value)}
            disabled={disabled}
            placeholder={translate('invoice.row.date')}
            className="form-control input-lg mobile-date"
            style={{ minWidth: '95%' }}
            onChange={changeMobileDate}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
        <ReactTooltip place="top" type="dark" effect="solid" />
      </div>
    )
  }
}

const SelectComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  submit,
  options,
  form: { touched, values, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const optionsElems = options.map((option) => {
    return (
      <option key={option.value} value={option.value}>
        {' '}
        {option.label}
      </option>
    )
  })
  const changed = (e, values, submit, setFieldValue) => {
    setFieldValue(e.target.name, e.target.value)
    submit({ ...values, [e.target.name]: e.target.value })
  }
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <select {...field} {...props} onChange={(e) => changed(e, values, submit, setFieldValue)} className="form-control input-lg">
          {optionsElems}
        </select>
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

const getRoundedVal = (field) => {
  if (field.value) {
    const val = field.value.toString()
    if (val.match(/\d.\d{3}/)) {
      if (field.name === 'price') {
        return (Math.round(field.value * 100) / 100).toString().replace('.', ',')
      }
      return (Math.round(field.value * 100) / 100).toString().replace('.', ',')
    }
    return val.replace('.', ',')
  }
  return ''
}
export const CustomNumberComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form,
  form: { touched, errors, values, setValues, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const changeValue = (event) => {
    event.preventDefault()
    let val = event.currentTarget.value.replace(/[^0-9.,+-]/g, '').replace(',', '.')
    const vals = {
      ...values,
      [field.name]: val,
    }

    const alv = (+values.alv / 100 + 1).toFixed(2)

    if (values.type === 'CONTRACT') {
      vals.billableWorkHours = 1
      if (field.name === 'alvPrice') {
        vals.hourlyprice = val
        vals.price = (val * alv).toFixed(3)
      } else if (field.name === 'price') {
        vals.alvPrice = (val / alv).toFixed(3)
        vals.hourlyprice = vals.alvPrice
      }
    } else {
      if (field.name === 'billableWorkHours') {
        vals.alvPrice = (values.hourlyprice * val).toFixed(3)
        vals.price = (vals.alvPrice * alv).toFixed(3)
      } else if (field.name === 'hourlyprice') {
        vals.alvPrice = (values.billableWorkHours * val).toFixed(3)
        vals.price = (vals.alvPrice * alv).toFixed(3)
      }
    }
    setValues({
      ...vals,
    })
  }
  const val = getRoundedVal(field)
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input {...field} {...props} type="text" className="form-control input-lg" value={val} onChange={(e) => changeValue(e)} />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

export const validate = (values, dates) => {
  const errors = {}
  let date = moment(values.date, 'DD.MM.YYYY', true)
  const curentDateMonthValue = values.date.substring(3)
  if (!date.isValid()) {
    errors.date = 'invalidDate'
  }
  /*
  let invalidDates = false
  for (let i = 0; i < dates.length; i++) {
    const d = dates[i]
    if (d !== curentDateMonthValue) {
      invalidDates = true
    }
  }
  if (invalidDates) {
    errors.date = 'notSameMonth'
  }
  */
  if (isNaN(values.alvPrice)) {
    errors.alvPrice = 'invalidNumber'
  }

  if (!values.destination) {
    errors.destination = 'required'
  }

  if (values.type === 'AMOUNT') {
    if (!values.hourlyprice) {
      errors.hourlyprice = 'required'
    } else if (isNaN(values.hourlyprice)) {
      errors.hourlyprice = 'invalidNumber'
    }
    if (!values.billableWorkHours) {
      errors.billableWorkHours = 'required'
    } else if (isNaN(values.billableWorkHours)) {
      errors.billableWorkHours = 'invalidNumber'
    }
  } else if (values.type === 'CONTRACT') {
    if (isNumber(values.alvPrice)) {
      errors.alvPrice = 'invalidNumber'
    }
    if (isNumber(values.price)) {
      errors.price = 'invalidNumber'
    }
  }
  return errors
}

class RowForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boll: props.bill,
      billableItem: {
        ...props.billableItem,
        hourlyprice: (props.billableItem.alvPrice / (props.billableItem.billableWorkHours || 1)).toFixed(2),
      },
      error: {},
    }
  }

  getTypeOptions() {
    const { translate } = this.props
    return [
      { value: 'CONTRACT', label: translate('invoice.row.types.contract') },
      { value: 'AMOUNT', label: translate('invoice.row.types.amount') },
    ]
  }

  render() {
    const { disabled, locale, translate, submit, initialValues, selectedBill } = this.props
    const dates = selectedBill.billableitems
      ?.filter((billableItem) => billableItem.id !== initialValues.id)
      .map((billableItem) => billableItem.date?.substring(3))
    return (
      <Formik
        initialValues={initialValues}
        validate={(values) => validate(values, dates)}
        enableReinitialize
        onSubmit={async (values, formik) => {
          const isValid = await formik.validateForm(values)
          const keys = Object.keys(isValid)
          if (keys.length === 0) {
            submit(values)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <form className="row-form" onSubmit={handleSubmit}>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="type"
                disabled={disabled}
                label={translate('invoice.row.hPrice')}
                component={SelectComponent}
                options={this.getTypeOptions()}
                translate={translate}
                submit={submit}
                onBlur={handleSubmit}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="date"
                disabled={disabled}
                label={translate('invoice.row.date')}
                component={DateComponent}
                isValid={isValid}
                locale={locale}
                options={this.getTypeOptions()}
                translate={translate}
                submit={handleSubmit}
                validateForm={validateForm}
              />
            </div>
            <div className="col-xs-12">
              <Field
                disabled={disabled}
                name="destination"
                label={translate('invoice.row.destination')}
                component={TextComponent}
                translate={translate}
                onBlur={handleSubmit}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="alvPrice"
                disabled={values.type === 'AMOUNT' || disabled}
                label={translate('invoice.row.VATPrice')}
                component={CustomNumberComponent}
                translate={translate}
                onBlur={handleSubmit}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="price"
                disabled={values.type === 'AMOUNT' || disabled}
                label={translate('invoice.row.priceWithVAT')}
                component={CustomNumberComponent}
                translate={translate}
                onBlur={handleSubmit}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="hourlyprice"
                disabled={values.type !== 'AMOUNT' || disabled}
                label={translate('invoice.row.hPrice')}
                component={CustomNumberComponent}
                translate={translate}
                onBlur={handleSubmit}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="billableWorkHours"
                disabled={values.type !== 'AMOUNT' || disabled}
                label={translate('invoice.row.hours')}
                component={CustomNumberComponent}
                translate={translate}
                onBlur={handleSubmit}
              />
            </div>
            <button type="submit" hidden></button>
          </form>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RowForm)
