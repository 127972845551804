import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'

import { Modal, Button } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { Formik, Field } from 'formik'
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { numberInputComponent } from '../../FormComponents/NumerInput'

import 'react-day-picker/lib/style.css'

const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  disabled,
  placeholder,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className="form-group">
      <FormControlLabel
        control={<Checkbox className="scaled" id={field.name} checked={!!field.value} onChange={props.handleChange} value={!!field.value} />}
        label={placeholder || label}
      />
      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  )
}

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input type="text" className="form-control input-lg" value={field.value} {...field} {...props} />
      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  </div>
)

const CustomDateComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  locale,
  disabled,
  handleChange,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <DayPickerInput
        value={field.value}
        dayPickerProps={{ localeUtils: LocaleUtils, locale }}
        readOnly={true}
        disabled={disabled}
        className="form-control"
        onDayChange={
          disabled
            ? null
            : (day) => {
                setFieldValue(field.name, moment(day).format('DD.MM.YYYY'))
              }
        }
        placeholder="DD.MM.YYYY"
        format="DD.MM.YYYY"
      />
    </div>
    {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
  </div>
)

const getReceipts = (receiptis, user, translate, handleDelete) => {
  if (receiptis.length > 0) {
    const receipts = receiptis.map((receipt) => {
      return (
        <tr key={receipt.id}>
          <td>{receipt.date}</td>
          <td>
            <a href={`/api/extcontractoritems/receipt/${receipt.id}?authtoken=${user.authtoken}`}>{receipt.displayname}</a>
          </td>
          <td>
            <button
              style={{ maxWidth: '100px', height: '30px' }}
              type="button"
              className="pull-right btn btn-warning"
              onClick={() => handleDelete(receipt)}
            >
              {translate('misc.delete')}
            </button>
          </td>
        </tr>
      )
    })
    return (
      <div className="receipts-container dkblue">
        <table width="100%">
          <thead>
            <tr>
              <td>{translate('misc.date')}</td>
              <td>{translate('misc.file')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{receipts}</tbody>
        </table>
      </div>
    )
  }
  return ''
}

class ExternalModal extends Component {
  constructor(props) {
    super(props)
    this.validateFn = this.validateFn.bind(this)
    this.submitValues = this.submitValues.bind(this)
  }

  validateFn = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'validation.required'
    }
    if (!values.amount) {
      errors.amount = 'validation.required'
    }
    if (!values.referenceNumber) {
      errors.referenceNumber = 'validation.required'
    }
    if (!values.accountNumber) {
      errors.accountNumber = 'validation.required'
    }
    if (!values.duedate) {
      errors.duedate = 'validation.required'
    }
    if (!(this.props.receipts && this.props.receipts.length > 0)) {
      errors.receipts = 'validation.required'
    }
    return errors
  }

  submitValues = (values) => {
    const { receipts } = this.props
    this.props.handleSubmit({
      ...values,
      receipts: receipts,
    })
  }

  render() {
    const { translate, show, closeModal, initialValues, onReceiptAdd, upload, receipts, user, locale, disabled, validateForm, handleDelete } =
      this.props

    if (validateForm) {
      validateForm()
    }

    const receiptTable = getReceipts(receipts, user, translate, handleDelete)
    return (
      <Modal show={show} onHide={closeModal} dialogClassName="modal-90w" backdrop={'static'} animation={false}>
        <Formik initialValues={initialValues} validate={this.validateFn} onSubmit={this.submitValues}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validateForm,
            /* and other goodies */
          }) => (
            <div className="valign-helper">
              <Modal.Header closeButton>
                <Modal.Title>{translate('subcontracting.extModalTitle')}</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="name"
                        label={translate('subcontracting.name')}
                        translate={translate}
                        disabled={disabled}
                        component={CustomInputComponent}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="duedate"
                        label={translate('subcontracting.duedate')}
                        translate={translate}
                        locale={locale}
                        disabled={disabled}
                        component={CustomDateComponent}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="amount"
                        label={translate('subcontracting.fullprice')}
                        translate={translate}
                        disabled={disabled}
                        component={numberInputComponent}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="referenceNumber"
                        label={translate('subcontracting.referenceNumber')}
                        translate={translate}
                        disabled={disabled}
                        component={CustomInputComponent}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="accountNumber"
                        label={translate('subcontracting.accountNumber')}
                        translate={translate}
                        disabled={disabled}
                        component={CustomInputComponent}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="empty-label form-control">
                        <Field
                          name="paynow"
                          label={translate('subcontracting.payNow')}
                          handleChange={handleChange}
                          translate={translate}
                          disabled={disabled}
                          component={CustomCheckboxComponent}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row medium-margin">
                    <div className="col-xs-12 col-sm-6">
                      {receiptTable}
                      {!upload.done && upload.progress >= 0 && (
                        <div className="progress-containter">
                          <div className="progress-outer">
                            <div className="progress-inner" style={{ width: upload.progress + '%' }}></div>
                            <div className="progress-text">
                              {upload.fileName} <span className="progress-value">{upload.progress}%</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div
                        disabled={disabled}
                        className="btn btn-success white div-button"
                        style={{
                          margin: '0px',
                        }}
                      >
                        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                          <div className="glyphicon icon-plus"></div>
                          {translate('subcontracting.addBill')}
                          <input
                            onChange={(e) => onReceiptAdd(e, validateForm)}
                            type="file"
                            disabled={disabled}
                            className="file-input"
                            style={{
                              position: 'absolute',
                              padding: '0px',
                              top: '0px',
                              left: '0px',
                              width: '100%',
                              height: '100%',
                              opacity: 0,
                              cursor: disabled ? 'not-allowed' : 'pointer',
                            }}
                          />
                          {errors['receipts'] && <span className="field-error">{translate(errors['receipts'])}</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col-xs-6">
                      <Button variant="cancel" onClick={closeModal}>
                        {translate('misc.cancel')}
                      </Button>
                    </div>
                    <div className="col-xs-6">
                      <Button variant="success" type="submit">
                        {translate('subcontracting.addExtRow')}
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              </form>
            </div>
          )}
        </Formik>
      </Modal>
    )
  }
}

export default ExternalModal
