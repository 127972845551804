import {
  GET_USER,
  LOGIN,
  LOGOUT,
  UPDATE_PROFILE,
  CREATE_CUSTOMER
} from './userActions'

import {
  UPLOAD_TAX_CARD
} from '../api/ProfileUploads/profileUploadsActions'

/* eslint no-undef: 0 */
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER + '_SUCCESS':
      const { id, fullName, phone, email, user_hash } = action.response
      window.intercomSettings = {
        app_id: 'hx3u3l40',
        email: email,
        id: id,
        name: fullName,
        phone: phone,
        user_hash: user_hash
      }
      if( window.Intercom ) {
        window.Intercom('update', window.intercomSettings)
      }
      return {
        ...state,
        authtoken: action.response.authtoken,
        user: action.response
      }
    case GET_USER + '_FAILURE':
      return {
        ...state,
        user: {}
      }
    case LOGIN + '_SUCCESS':
      return {
        ...state,
        authtoken: action.response.authtoken,
        user: action.response
      }
    case LOGIN + '_FAILURE':
      return {
        ...state,
        user: {}
      }
    case UPDATE_PROFILE + '_SUCCESS':
      return {
        ...state,
        user: action.response
      }
    case UPDATE_PROFILE + '_FAILURE':

      return {
        ...state
      }
    case CREATE_CUSTOMER + '_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          sharedCustomers: [
            ...state.user.sharedCustomers,
            action.response
          ]
        }
      }
    case CREATE_CUSTOMER + '_FAILURE':
      return {
        ...state,
        lastCreatedCustomer: {}
      }
    case UPLOAD_TAX_CARD + '_SUCCESS':
      return {
        ...state,
        user: action.response
      }
    case LOGOUT + '_SUCCESS':
      return {}
    default:
      return state
  }
}

export default userReducer
