import {
  FIND_CUSTOMERS,
  SET_CUSTOMERS,
  CLEAR_CUSTOMERS
} from './customersActions'

const customersReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case FIND_CUSTOMERS + '_SUCCESS':
      return {
        ...state,
        result: action.response
      }
    case FIND_CUSTOMERS + '_FAILURE':
      return {
        ...state,
        result: {}
      }
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.data
      }
    case CLEAR_CUSTOMERS: {
      return {
        ...state,
        result: [],
        customers: []
      }
    }
    default:
      return state
  }
}

export default customersReducer
