export const GET_BILLS = 'GET_BILLS'
export const CREATE_BILL = 'CREATE_BILL'

export const DELETE_BILL = 'DELETE_BILL'
export const GET_ARCHIVE = 'GET_ARCHIVE'
export const ADD_ARCHIVE_PAGE = 'ADD_ARCHIVE_PAGE'




