import { fetchUserInfo, getCustomers, saveCustomer, deleteCustomer, getTaxInfo } from './infoApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'

export function getInfo(months) {
  const user = getObject('user')
  return fetchUserInfo(path(['authtoken'], user), path(['id'], user), months)
    .then(info => {
      return { response: info }
    })
    .catch(err => {
      return err
    })
}

export function searchCustomersWithApi(data) {
  const user = getObject('user')
  let query = data.data || ''
  return getCustomers(path(['authtoken'], user), query)
    .then(user => {
      return { response: user }
    })
    .catch(err => {
      return err
    })
}

export function saveSharedCustomerWithApi(data) {
  const user = getObject('user')
  return saveCustomer(path(['authtoken'], user), data.data)
    .then(user => {
      return { response: user }
    })
    .catch(err => {
      return err
    })
}

export function deleteSharedCustomerWithApi(data) {
  const user = getObject('user')
  return deleteCustomer(path(['authtoken'], user), data.data)
    .then(user => {
      return { response: user }
    })
    .catch(err => {
      return err
    })
}

export function getTaxInfoFromApi(data) {
  const user = getObject('user')
  return getTaxInfo(path(['authtoken'], user), user.id, data.year)
    .then(taxInfo => {
      return { response: taxInfo }
    })
    .catch(err => {
      return err
    })
}

export function* getPersonInfoFromApi(data) {
  const { response, error } = yield call(getInfo, data)
  if (response) {
    yield put({ type: 'GET_INFO_SUCCESS', response })
  } else {
    yield put({ type: 'GET_INFO_FAILURE', error })
  }
}

export function* trySearchCustomers(data) {
  const { response, error } = yield call(searchCustomersWithApi, data)
  if (response) {
    yield put({ type: 'SEARCH_CUSTOMERS_SUCCESS', response })
  } else {
    yield put({ type: 'SEARCH_CUSTOMERS_FAILURE', error })
  }
}
export function* trySaveSharedCustomer(data) {
  const { response, error } = yield call(saveSharedCustomerWithApi, data)
  if (response) {
    yield put({ type: 'SAVE_CUSTOMERS_SUCCESS', response })
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!SAVE_CUSTOMERS_SUCCESS', type: 'success' },
    })
    yield put({ type: 'CHECK_USER' })
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!SAVE_CUSTOMERS_FAILURE', type: 'danger' },
    })
    yield put({ type: 'SAVE_CUSTOMERS_FAILURE', error })
  }
}
export function* tryDeleteSharedCustomer(data) {
  const { response, error } = yield call(deleteSharedCustomerWithApi, data)
  if (response || response === null) {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_CUSTOMERS_SUCCESS', type: 'success' },
    })
    yield put({ type: 'DELETE_CUSTOMERS_SUCCESS', response })
    yield put({ type: 'CHECK_USER' })
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_CUSTOMERS_FAILURE', type: 'danger' },
    })
    yield put({ type: 'DELETE_CUSTOMERS_FAILURE', error })
  }
}

export function* tryGetTaxInfo(data) {
  const { response, error } = yield call(getTaxInfoFromApi, data)
  if (response) {
    yield put({ type: 'TAX_INFO_SUCCESS', response })
  } else {
    console.log('error', error)
  }
}

export function* getPersonInfo() {
  yield takeEvery('GET_INFO', getPersonInfoFromApi)
}

export function* serchCustomers() {
  yield takeEvery('SEARCH_CUSTOMERS', trySearchCustomers)
}

export function* updateSharedCustomers() {
  yield takeEvery('SAVE_CUSTOMER', trySaveSharedCustomer)
}
export function* deleteSharedCustomers() {
  yield takeEvery('DELETE_CUSTOMER', tryDeleteSharedCustomer)
}

export function* getTaxInfoSaga() {
  yield takeEvery('TAX_INFO', tryGetTaxInfo)
}
