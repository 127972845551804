export const SEARCH_SUBCONTRACTORS = 'SEARCH_SUBCONTRACTORS'
export const CREATE_SUBCONTRACTOR_ITEM = 'CREATE_SUBCONTRACTOR_ITEM'
export const UPDATE_SUBCONTRACTOR_ITEM = 'UPDATE_SUBCONTRACTOR_ITEM'
export const DELETE_SUBCONTRACTOR_ITEM = 'DELETE_SUBCONTRACTOR_ITEM'
export const APPROVE_SUBCONTRACTOR_ITEM = 'APPROVE_SUBCONTRACTOR_ITEM'
export const DECLINE_SUBCONTRACTOR_ITEM = 'DECLINE_SUBCONTRACTOR_ITEM'
export const SEND_FOR_APPROVAL_ITEM = 'SEND_FOR_APPROVAL_ITEM'
export const CREATE_EXTERNAL_BILL = 'CREATE_EXTERNAL_BILL'
export const CREATE_EXTERNAL_BILL_FOR_ITEM = 'CREATE_EXTERNAL_BILL_FOR_ITEM'
export const CREATE_EXTERNAL_SUBCONTRACTOR_ITEM = 'CREATE_EXTERNAL_SUBCONTRACTOR_ITEM'
export const DELETE_ITEM_RECEIPT = 'DELETE_ITEM_RECEIPT'
export const SENT_FOR_PAYMENT = 'SENT_FOR_PAYMENT'
export const OPEN_ITEM = 'OPEN_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const DELETE_EXT_ITEM = 'DELETE_EXT_ITEM'
export const DELETE_PLAIN_RECEIPT = 'DELETE_PLAIN_RECEIPT'

export const searchSubcontractors = str => ({
  type: SEARCH_SUBCONTRACTORS,
  query: str
})

export const createSubcontracorAction = (item, billId) => ({
  type: CREATE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const updateSubcontracorAction = (item, billId) => ({
  type: UPDATE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const deleteSubcontracorAction = (item, billId) => ({
  type: DELETE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const approveSubcontracorAction = (item, billId) => ({
  type: APPROVE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const declineSubcontracorAction = (item, billId) => ({
  type: DECLINE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const sendForApprovalAction = (item, billId) => ({
  type: SEND_FOR_APPROVAL_ITEM,
  item: item,
  billId: billId
})

export const openAction = (item, billId) => ({
  type: UPDATE_SUBCONTRACTOR_ITEM,
  item: item,
  billId: billId
})

export const createReceipt = (data, callback) => ({
  type: CREATE_EXTERNAL_BILL,
  data: data,
  callback: callback
})

export const createReceiptForItem = (data, id, callback) => ({
  type: CREATE_EXTERNAL_BILL_FOR_ITEM,
  data: data,
  extId: id,
  callback: callback
})

export const createExternalItemAction = (data, billId) => ({
  type: CREATE_EXTERNAL_SUBCONTRACTOR_ITEM,
  data: data,
  billId: billId
})

export const deleteReceiptAction = (receiptId, billId, extId) => ({
  type: DELETE_ITEM_RECEIPT,
  receiptId: receiptId,
  billId: billId,
  extId: extId
})

export const sendForPaymentAction = (extId, billId) => ({
  type: SENT_FOR_PAYMENT,
  extId: extId,
  billId: billId
})

export const openItemAction = (extId, billId) => ({
  type: OPEN_ITEM,
  extId: extId,
  billId: billId
})

export const updateItemAction = (item, billId) => ({
  type: UPDATE_ITEM,
  item: item,
  billId: billId
})

export const deleteItemAction = (extId, billId) => ({
  type: DELETE_EXT_ITEM,
  extId: extId,
  billId: billId
})

export const deletePlainReceiptAction = (receiptId, billId) => ({
  type: DELETE_PLAIN_RECEIPT,
  receiptId: receiptId,
  billId: billId
})
