import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  disabled,
  placeholder,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="no-padding-top nomargin form-control">
        <FormControlLabel
          control={
            <Checkbox
              className="scaled"
              id={field.name}
              checked={!!field.value}
              onChange={props.onChange}
              disabled={disabled}
              value={!!field.value}
            />
          }
          label={placeholder || label}
        />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}
