import { apiCall } from '../../../services/api'

export function calculate(token, data) {
  return apiCall('/api/calc', 'put', token, data)
}
export function calculateFromBill(token, data) {
  return apiCall(`/api/calc/frombill/${data.id}`, 'put', token, {})
}

export function calculateFromPartialBill(token, data) {
  return apiCall(`/api/calc/frombill`, 'put', token, data)
}