import {addTranslation} from 'react-localize-redux'

const translations = [
  'account',
  'calculator',
  'chart',
  'customers',
  'footer',
  'header',
  'homeView',
  'invoice',
  'invoiceRow',
  'invoiceView',
  'login',
  'misc',
  'notifications',
  'privacy',
  'register',
  'salaries',
  'sidebar',
  'templates',
  'tooltips',
  'validation',
  'password',
  'subcontracting',
  'reimbursements'
]

const loadTranslations = (dispatch) => {
  for (let translation of translations) {
    dispatch(addTranslation(require(`./translations/${translation}.json`)))
  }
}

export default loadTranslations
