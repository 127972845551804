import { addSharedCustomer, fetchUser, login, logoutFromApi } from '../../components/User/userApi'
import { takeEvery, throttle } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { browserHistory } from 'react-router'
import { getObject, setObject } from '../../components/Localstorage/localStorageService'
import { path } from 'ramda'
import { startResetPasswordAction } from '../Password/passwordSagas'

export function getUser(token) {
  const user = getObject('user')
  const userToken = token || path(['authtoken'], user)
  return fetchUser(userToken)
    .then((user) => {
      return { response: user }
    })
    .catch((err) => {
      setObject('user', null)
      return err
    })
}

export function logoutUser() {
  const user = getObject('user')
  setObject('user', null)
  setObject('authtoken', null)
  return logoutFromApi(path(['authtoken'], user)).then((user) => {
    return { response: user }
  })
}

export function* checkUserFromApi() {
  const { response, error } = yield call(getUser)
  setObject('user', response)
  if (response && response.status !== 401) {
    yield put({ type: 'GET_USER_SUCCESS', response })
    yield put({ type: 'GET_SHORT_USER_LIST' })
    yield put({ type: 'GET_TEMPLATES' })
    yield put({ type: 'GET_BILLS' })
    yield put({ type: 'CHECK_PIC' })
    yield put({ type: 'SEARCH_CUSTOMERS' })
  } else {
    yield put({ type: 'GET_USER_FAILURE', error })
    const pathname = browserHistory.getCurrentLocation().pathname
    const availavlePaths = ['/login', '/register', '/register-started', '/privacy', '/about', '/calculator', '/registration', '/password']
    if (availavlePaths.filter((a) => !pathname.indexOf(a)) <= 0) {
      browserHistory.push('/login')
    }
  }
  yield put({ type: 'CHECK_USER_CALLED', response })
}

export function* getUserFromApiWithToken(action) {
  yield put({ type: 'LOGOUT' })
  yield take('CHECK_USER_CALLED')
  const { response, error } = yield call(getUser, action.token)
  if (response && response.id) {
    yield put({ type: 'GET_USER_SUCCESS', response })
    const billId = action.billId
    setObject('user', response)
    yield put({ type: 'GET_SHORT_USER_LIST' })
    yield put({ type: 'GET_BILLS' })
    yield put({ type: 'SELECT_BILL', billId: billId })
    yield put({ type: 'GET_TEMPLATES' })
    yield put({ type: 'CHECK_PIC' })
    yield put({ type: 'SEARCH_CUSTOMERS' })
    const url = billId ? `/invoice?id=${billId}` : ''
    browserHistory.push(url)
  } else {
    yield put({ type: 'GET_USER_FAILURE', error })
    const pathname = browserHistory.getCurrentLocation().pathname
    const availavlePaths = ['/login', '/register', '/register-started', '/privacy', '/about', '/calculator', '/registration', '/password']
    const filtered = availavlePaths.filter((a) => !pathname.indexOf(a))
    if (filtered <= 0) {
      browserHistory.push('/login')
    }
  }
}

export function createSharedCustomerWithApi(data, callback) {
  const user = getObject('user')
  return addSharedCustomer(path(['authtoken'], user), data.data.values, data.data.callback)
    .then((result) => {
      return { response: result }
    })
    .catch((err) => {
      return err
    })
}

function loginFromApi(credentials) {
  return login(credentials)
    .then((user) => {
      return { response: user }
    })
    .catch((err) => {
      return err
    })
}

export function* tryLoginWithCredentials(credentials) {
  const res = yield call(loginFromApi, credentials)
  const { response, error } = res
  if (response && response.status !== 401) {
    if (response.strongIdentified === false) {
      yield call(startResetPasswordAction, response)
    } else {
      setObject('user', response)
      yield put({ type: 'GET_SHORT_USER_LIST' })
      yield put({ type: 'GET_TEMPLATES' })
      yield put({ type: 'GET_BILLS' })
      yield put({ type: 'CHECK_PIC' })
      yield put({ type: 'SEARCH_CUSTOMERS' })
      yield put({ type: 'GET_SHORT_USER_LIST' })
      yield put({ type: 'GET_USER_SUCCESS', response })
      browserHistory.push('')
    }
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!LOGIN_FAILURE', type: 'error' },
    })
    yield put({ type: 'LOGIN_FAILURE', error })
  }
}

export function* tryCreateSharedCustomer(data) {
  const { response, error } = yield call(createSharedCustomerWithApi, data)
  if (response) {
    yield put({ type: 'CREATE_CUSTOMER_SUCCESS', response })
    yield put({
      type: 'SEND_NOTIFICATION',
      response: {
        message: 'LOCALIZED!CUSTOMER_CREATED_SUCCESFULLY',
        duration: 3000,
        type: 'success',
      },
    })
  } else {
    yield put({ type: 'CREATE_CUSTOMER_FAILURE', error })
  }
}

function* failureSaga(fail, b) {
  if (fail.type.indexOf('FAILURE') >= 0) {
    const status = path(['error', 'status'], fail)
    if (status === 401) {
      yield put({ type: 'LOGOUT' })
    }
  }
}

function* logoutSaga() {
  const { response } = yield call(logoutUser)
  if (response) {
    yield put({ type: 'LOGOUT_SUCCESS' })
    browserHistory.push('/login')
  }
}

export function* checkUser() {
  yield takeEvery('CHECK_USER', checkUserFromApi)
}

export function* tryLogin() {
  yield throttle(100, 'USER_LOGIN_REQUESTED', tryLoginWithCredentials)
}

export function* logout() {
  yield takeEvery('LOGOUT', logoutSaga)
}

export function* createSharedCustomer() {
  yield throttle(1000, 'CREATE_CUSTOMER', tryCreateSharedCustomer)
}

export function* failures() {
  yield takeEvery('*', failureSaga)
}

export function* getUserWithToken() {
  yield takeEvery('TOKEN_LOGIN_REQUESTED', getUserFromApiWithToken)
}
