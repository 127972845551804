export const START_RESET_PASSWORD = 'START_RESET_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const GET_USER_FOR_PASSWORD = 'GET_USER_FOR_PASSWORD'

export const startResetPassword = email => ({
  type: START_RESET_PASSWORD,
  email: email
})

export const resetPassword = data => ({
  type: RESET_PASSWORD,
  data: data
})
