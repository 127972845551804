import { apiCall } from '../../../services/api'


export function fetchSubContractors(token, query) {
  return apiCall(`/api/person/subcontractors?query=${query}`, 'get', token)
}

export function createSubcontractorItemApiCall(token, billId, item) {
  return apiCall(`/api/bill/${billId}/contractoritems`, 'post', token, item)
}

export function updateSubcontractorItemApiCall(token, billId, item) {
  return apiCall(`/api/bill/${billId}/contractoritems/${item.id}`, 'put', token, item)
}

export function deleteSubcontractorItemApiCall(token, billId, item) {
  return apiCall(`/api/bill/${billId}/contractoritems/${item.id}`, 'delete', token)
}

export function approveSubcontractorItemApiCall(token, item) {
  return apiCall(`/api/contractoritems/${item.id}/approve`, 'put', token, item)
}

export function declineSubcontractorItemApiCall(token, item) {
  return apiCall(`/api/contractoritems/${item.id}/decline`, 'put', token, item)
}

export function sendForApprovalSubcontractorItemApiCall(token, item) {
  return apiCall(`/api/contractoritems/${item.id}/approve-request`, 'put', token, item)
}

export function newBillAttachmentApiCall(token, data, progressCallback) {
  return apiCall(`/api/bill/${data.id}/extcontractoritems/receipts`, 'post', token, data.image, progressCallback)
}

export function newBillAttachmentWithIdApiCall(token, data, id, progressCallback) {
  return apiCall(`/api/bill/${data.id}/extcontractoritems/${id}/receipts`, 'post', token, data.image, progressCallback)
}

export function createExtItemApiCall(token, billId, item) {
  return apiCall(`/api/bill/${billId}/extcontractoritems`, 'post', token, item)
}

export function deleteReceiptApiCall(token, receiptId, billId) {
  return apiCall(`/api/extcontractoritems/receipts/${receiptId}`, 'delete', token)
}

export function sendForPaymentApiCall(token, extId, billId) {
  return apiCall(`/api/bill/${billId}/extcontractoritems/${extId}/sendForPayment`, 'post', token)
}

export function openItemApiCall(token, extId, billId) {
  return apiCall(`/api/bill/${billId}/extcontractoritems/${extId}/open`, 'post', token)
}

export function updateItemApiCall(token, item, billId) {
  return apiCall(`/api/bill/${billId}/extcontractoritems/${item.id}`, 'put', token, item)
}

export function deleteExtItemApiCall(token, extId, billId) {
  return apiCall(`/api/bill/${billId}/extcontractoritems/${extId}`, 'delete', token)
}

export function deletePlainReceiptApiCall(token, receiptId) {
  return apiCall(`/api/extcontractoritems/receipts/${receiptId}`, 'delete', token)
}
