import { connect } from 'react-redux'
import React, { Component } from 'react'
import loadTranslations from '../../services/translations'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import NotificationArea from '../../components/NotificationArea'
import TooltipContainer from '../../components/TooltipContainer'
import LandingRoute from '../../routes/Landing'
import { Helmet } from 'react-helmet'
import './CoreLayout.scss'
import '../../styles/core.scss'
import { isIpad, isMobile } from '../../components/Utils/detectMobile'
import { browserHistory } from 'react-router'
import { setObject } from '../../components/Localstorage/localStorageService'
import jwt from 'jsonwebtoken'

class CoreLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: '0',
      height: '0',
      landingEnabled: false,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  checkUser() {
    const {
      query: { token },
    } = browserHistory.getCurrentLocation()
    if (token) {
      const user = jwt.decode(token)
      setObject('user', {
        ...user,
        token,
      })
    }
  }

  async UNSAFE_componentWillMount() {
    this.checkUser()
    this.props.checkUser()
    this.props.loadTranslations()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.state.landingEnabled) {
      return
    }
  }

  getHelmetContent() {
    /* eslint no-restricted-globals:0 */
    let initialScale = (screen.width / 480).toFixed(4)
    if (isIpad() === true) {
      initialScale = 1
    }
    if (isMobile()) {
      return <meta name="viewport" content={`width=device-width, initial-scale=${initialScale}, user-scalable=no`} />
    } else {
      initialScale = 1
      return <meta name="viewport" content={`width=device-width, initial-scale=${initialScale}, user-scalable=yes`} />
    }
  }

  render() {
    const { landingEnabled } = this.state
    const { children, location, user } = this.props
    const language = (user && user.language) || 'en'

    const helmet = (
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        {this.getHelmetContent()}
      </Helmet>
    )
    if (landingEnabled && location.pathname === '/' && (!user || !user.authtoken)) {
      return (
        <div>
          {helmet}
          <LandingRoute />
        </div>
      )
    }
    return (
      <div>
        {helmet}
        <Sidebar width={+this.state.width}>
          <div className="main-content">
            <div id="wrap">
              <Header />
              <div className="container">
                <div className="core-layout__viewport">{children}</div>
              </div>
            </div>
            <Footer />
          </div>
        </Sidebar>
        <NotificationArea />
        <TooltipContainer />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    location: state.location || {},
    routing: state.routing || {},
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTemplates: () => dispatch({ type: 'GET_TEMPLATES' }),
    checkUser: () => dispatch({ type: 'CHECK_USER' }),
    findCustomers: () => dispatch({ type: 'SEARCH_CUSTOMERS', data: '' }),
    getBills: () => dispatch({ type: 'GET_BILLS' }),
    loadTranslations: () => loadTranslations(dispatch),
    checkProfilePic: () => dispatch({ type: 'CHECK_PIC' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)
