import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import './wageCard.scss'
import ReactTooltip from 'react-tooltip'

class WageCard extends Component {

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  onView = () => {
    const { token, salaryurl } = this.props
    window.open(`${salaryurl}?authtoken=${token}&content-disposition=inline`)
  }

  onPrint = () => {
    const { token, salaryurl } = this.props
    const w = window.open(`${salaryurl}?authtoken=${token}&content-disposition=inline`)
    w.onload = w.print
  }

  render() {
    const {
      id,
      alvtotal,
      payday,
      salary,
      costs,
      translate
    } = this.props
    return (
      <div className="wage-card">
        <div className="card-header">
          <span className="pull-left">
            {id}
          </span>
          <span className="pull-right">{payday}</span>
        </div>
        <div className="pane">
          <div className="dkblue">
            <h1><span className="blue">{salary} €</span></h1>
            <div className="table-row"><span className="field title">
              {translate('salaries.invoice')}
            </span><span className="field text">{alvtotal} €</span></div>
            <div className="table-row"><span className="field title">
              {translate('salaries.fee')}
            </span><span className="field text">{costs} €</span></div>
          </div>
          <i className="icon icon-wallet" />
          <div className="btn-group">
            <button data-for="global-view"
              data-tip
              data-iscapture="true"
              onClick={this.onView} type="button" className="btn btn-square">
              <i className="glyphicon icon-eye" />
            </button>
            <button data-for="global-print"
              data-tip
              data-iscapture="true"
              onClick={this.onPrint} type="button" className="btn btn-square">
              <i className="glyphicon icon-print" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    token: state.user.authtoken,
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WageCard)
