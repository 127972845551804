import { fetchSalaries } from './salariesApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject, setObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'

export function getPersonSalaries() {
  const user = getObject('user')
  return fetchSalaries(path(['authtoken'], user), path(['id'], user))
    .then(salaries => {
      setObject('salaries', salaries)
      return { response: salaries }
    })
    .catch(err => {
      setObject('salaries', null)
      return err
    })
}
export function* getSalariesFromApi() {
  const { response, error } = yield call(getPersonSalaries)
  if (response) {
    yield put({ type: 'GET_SALARIES_SUCCESS', response })
  } else {
    yield put({ type: 'GET_SALARIES_FAILURE', error })
  }
}

export function* getSalaries() {
  yield takeEvery('GET_SALARIES', getSalariesFromApi)
}
