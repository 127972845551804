import { USER_REGISTER_REQUESTED, GET_USER_BY_TOKEN } from './registerActions'

const defaultState = {}
const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUESTED + '_SUCCESS':
      return {
        ...state,
        register: action.response,
      }
    case GET_USER_BY_TOKEN + '_SUCCESS':
      return {
        ...state,
        user: action.response,
      }
    case GET_USER_BY_TOKEN + '_FAILURE':
      return {
        ...state,
        user: {},
      }
    default:
      return state
  }
}

export default registerReducer
