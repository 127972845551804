import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import AccountForm from './AccountForm'
import PasswordForm from './PasswordForm'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Button, Modal } from 'react-bootstrap'
import './account.scss'

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      avatar: null,
      fileName: null,
      progress: { progress: null },
      showTaxCardUploaded: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.lastUpload === null) {
      this.setState({ fileName: null, progress: { progress: 0 } })
    }
  }

  handleSubmitProfile = (data) => {
    const updatedValues = {
      ...data,
      fullName: data.firstName + ' ' + data.lastName,
      personSettings: {
        ...data.personSettings,
      },
    }
    this.props.updateProfile(updatedValues)
  }

  handleSubmitPassword = (data) => {
    const { user } = this.props
    user.password = data.password
    this.props.updateProfile({
      ...user,
      ...data,
    })
  }

  onProgress = (progress) => {
    this.setState({ progress })
    const _this = this
    if (progress.progress === 100) {
      let ut = setTimeout(() => {
        _this.setState({ fileName: null, progress: { progress: null } })
      }, 1000)
      this.setState({ ut })
    }
    if (progress.done) {
      this.setState({
        fileName: null,
        progress: {
          progress: null,
        },
        showTaxCardUploaded: true,
      })
    }
  }

  handlePicChange = (e) => {
    const file = e.target.files[0]
    this.setState({
      fileName: file.name,
      progress: {
        progress: null,
      },
    })
    file && this.props.uploadProfilePic(file, this.onProgress)
    e.target.value = null
  }

  handleTaxCardUpload = (e) => {
    const file = e.target.files[0]
    this.setState({
      fileName: file.name,
      progress: {
        progress: null,
      },
    })
    file && this.props.uploadTaxCard(file, this.onProgress)
    e.target.value = null
  }

  closeShowTaxCardUploaded = () => {
    this.setState({
      ...this.state,
      showTaxCardUploaded: false,
    })
  }

  handlePicDelete = (e) => {}

  render() {
    const percent = (value) =>
      parseFloat(value * 100 || 0)
        .toFixed(2)
        .replace('.', ',')
    const { translate, user, authtoken } = this.props
    const { fileName } = this.state
    const progress = path(['progress', 'progress'], this.state)
    if (!user || !user.authtoken) {
      return null
    }
    const avatar = `url(${this.props.pic})`
    const initialValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      marketingname: user.marketingname,
      address: user.address,
      city: user.city,
      postalcode: user.postalcode,
      profession: user.profession,
      personSettings: {
        taxCard: user.personSettings.taxCard,
        copy_rows: user.personSettings.copy_rows,
        primaryWork: user.personSettings.primaryWork,
      },
      iban: user.iban,
      bic: user.bic,
      taxpercent: percent(user.personSettings.taxpercent),
    }
    return (
      <div className="row">
        <div className="dashboard account">
          <div className="confines">
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('account.title')}
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <div className="pic">
                    <div className="avatar-wrapper">
                      <div
                        className="avatar"
                        style={{
                          backgroundImage: avatar,
                        }}
                      ></div>
                    </div>
                    <div className="controls">
                      <div className="btn-group">
                        <div
                          disabled={!!(progress !== null)}
                          type="button"
                          className="btn btn-square"
                        >
                          <i className="glyphicon icon-camera" />
                          <input
                            onChange={this.handlePicChange.bind(this)}
                            accept="image/*"
                            type="file"
                            disabled={!!(progress !== null)}
                            className="file-input"
                            style={{
                              position: 'absolute',
                              top: '0px',
                              left: '0px',
                              width: '100%',
                              height: '100%',
                              opacity: 0,
                              cursor: progress !== null ? 'not-allowed' : 'pointer',
                            }}
                          />
                        </div>

                        <button
                          onClick={this.handleDelete}
                          type="button"
                          className="btn btn-square"
                        >
                          <i className="glyphicon icon-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <AccountForm
                  progress={progress}
                  onTaxCardUpload={this.handleTaxCardUpload}
                  handleSubmit={this.handleSubmitProfile}
                  user={user}
                  authtoken={authtoken}
                  translate={translate}
                  initialValues={initialValues}
                />

                {fileName !== null && progress !== null && (
                  <div className="col-xs-12 progress-containter">
                    <div className="progress-outer">
                      <div className="progress-inner" style={{ width: progress + '%' }}></div>
                      <div className="progress-text">
                        {fileName} <span className="progress-value">{progress}%</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="panel info-title">{translate('account.chPass')}</div>
              <div className="row">
                <PasswordForm translate={translate} handleSubmit={this.handleSubmitPassword} />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showTaxCardUploaded}
          onHide={this.closeShowTaxCardUploaded}
          animation={false}
          backdrop={'static'}
        >
          <div className="valign-helper">
            <Modal.Header closeButton>
              <Modal.Title>{translate('account.taxcardUpdated.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{translate('account.taxcardUpdated.content')}</Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col-xs-5 pull-right">
                  <Button variant="success" onClick={this.closeShowTaxCardUploaded} type="submit">
                    {translate('account.taxcardUpdated.ok')}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    user: state.user.user,
    authtoken: state.user.authtoken,
    pic: state.profileUploads.pic,
    lastUpload: state.profileUploads.lastUpload,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadProfilePic: (data, progressCallback) =>
      dispatch({ type: 'UPLOAD_PIC', data, progressCallback }),
    uploadTaxCard: (data, progressCallback) =>
      dispatch({ type: 'UPLOAD_TAX_CARD', data, progressCallback }),
    updateProfile: data => dispatch({ type: 'UPDATE_PROFILE', data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
