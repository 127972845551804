import React from 'react'

export const numberInputComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    disabled,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const changeValue = (event) => {
      let val = event.currentTarget.value.replace(/[^0-9.,-]/g, '').replace(',', '.')
      if (!isNaN(val) || val === '-') {
        setFieldValue(field.name, val.replace(',', '.'))
      }
    }
    const val = field.value ? field.value.replace('.', ',') : ''
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <input
            {...field}
            type="text"
            disabled={disabled}
            className="form-control input-lg"
            onChange={changeValue}
            value={val}
          />
          {errors[field.name] && touched[field.name] && (
            <span className="field-error">{translate(errors[field.name])}</span>
          )}
        </div>
      </div>
    )
  }