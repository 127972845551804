import React from 'react'
import { Route } from 'react-router'

import Templates from './Templates.js'

const templates = (
  <Route path="templates" component={Templates}>
  </Route>
)

export default templates
