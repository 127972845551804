import {
  CREATE_ADVANCE_PAYMENT_SUCCESS,
  CREATE_BILLABLE_ITEM,
  CREATE_RECEIPT,
  CURRENT_UPLOAD,
  DELETE_BILLABLE_ITEM,
  DELETE_RECEIPT,
  GET_BILLABLE_ITEM,
  GET_BILLABLE_ITEMS,
  GET_RECEIPT,
  SAVE_BILLABLE_ITEM_FINISHED,
  SAVE_BILLABLE_ITEM_START,
  SELECT_BILL,
  SELECT_NEW_BILL,
  UPDATE_BILL,
  UPDATE_BILLABLE_ITEM,
  UPDATE_DUE_DATE,
  VALIDATE_BILL,
} from './billActions'

import {
  CREATE_EXTERNAL_BILL_FOR_ITEM,
  CREATE_EXTERNAL_SUBCONTRACTOR_ITEM,
  CREATE_SUBCONTRACTOR_ITEM,
  DELETE_EXT_ITEM,
  DELETE_ITEM_RECEIPT,
  DELETE_SUBCONTRACTOR_ITEM,
  SEND_FOR_APPROVAL_ITEM,
  UPDATE_SUBCONTRACTOR_ITEM,
} from '../SubContractor/subContractorActions'
import { filter, find, propEq } from 'ramda'

const defaultState = { upload: {}, saveBillableItem: false }
const billReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_BILLABLE_ITEM_START:
      return {
        ...state,
        saveBillableItem: true,
      }
    case SAVE_BILLABLE_ITEM_FINISHED:
      return {
        ...state,
        saveBillableItem: false,
      }
    case GET_BILLABLE_ITEMS + '_SUCCESS':
      return {
        ...state,
      }
    case GET_BILLABLE_ITEMS + '_FAILURE':
      return {
        ...state,
      }
    case CREATE_BILLABLE_ITEM + '_SUCCESS':
      return {
        ...state,
        selectedBill: action.response,
      }
    case CREATE_BILLABLE_ITEM + '_FAILURE':
      return {
        ...state,
      }
    case UPDATE_BILLABLE_ITEM + '_SUCCESS':
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          billableitems: state.selectedBill.billableitems.map((item) => {
            if (item.id === action.response.id) {
              return action.response
            }
            return item
          }),
        },
      }

    case UPDATE_BILLABLE_ITEM + '_FAILURE':
      return {
        ...state,
      }
    case DELETE_BILLABLE_ITEM + '_SUCCESS':
      const deletedBiID = action.data.id
      const items = filter((item) => item.id !== deletedBiID, state.selectedBill.billableitems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          billableitems: items,
        },
      }
    case DELETE_BILLABLE_ITEM + '_FAILURE':
      return {
        ...state,
      }
    case SELECT_BILL + '_SUCCESS':
      return {
        ...state,
        selectedBill: action.response,
      }

    case CREATE_RECEIPT + '_SUCCESS':
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          billableitems: [
            ...state.selectedBill.billableitems.map((bi) => {
              if (bi.id === action.billableItemId) {
                return {
                  ...bi,
                  receipts: [...bi.receipts, ...action.response],
                }
              } else {
                return bi
              }
            }),
          ],
        },
      }
    case CREATE_RECEIPT + '_FAILURE':
      return {
        ...state,
        lastReceipt: null,
      }
    case GET_RECEIPT + '_SUCCESS':
      return {
        ...state,
      }
    case GET_RECEIPT + '_FAILURE':
      return {
        ...state,
      }
    case DELETE_RECEIPT + '_SUCCESS':
      const biToEdit = find(propEq('id', action.billableItemId), state.selectedBill.billableitems)
      const receipts = filter((item) => item.id !== action.response.id, biToEdit.receipts)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          billableitems: [
            ...state.selectedBill.billableitems.map((bi) => {
              if (bi.id === action.billableItemId) {
                return {
                  ...bi,
                  receipts: [...receipts],
                }
              } else {
                return bi
              }
            }),
          ],
        },
        billableItemToEdit: {
          ...biToEdit,
          receipts: [...receipts],
        },
      }
    case DELETE_RECEIPT + '_FAILURE':
      return {
        ...state,
        lastReceipt: action.response,
      }
    case CURRENT_UPLOAD:
      return {
        ...state,
        upload: action.data,
      }
    case GET_BILLABLE_ITEM + '_SUCCESS':
      return {
        ...state,
        billableItemToEdit: action.response,
      }
    case SELECT_NEW_BILL:
      return {
        ...state,
        selectedBill: action.bill,
      }
    case UPDATE_BILL + '_SUCCESS':
      return {
        ...state,
        selectedBill: action.response,
      }
    case UPDATE_BILL + '_FAILURE':
      return {
        ...state,
        selectedBill: {},
      }
    case UPDATE_DUE_DATE:
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          dueDate: action.data.dueDate,
        },
      }
    case CREATE_SUBCONTRACTOR_ITEM + '_SUCCESS':
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          contractorBillableItems: [...state.selectedBill.contractorBillableItems, action.response],
        },
      }
    case DELETE_SUBCONTRACTOR_ITEM + '_SUCCESS':
      const contractorItems = filter((item) => item.id !== action.response.contractorBillableItemId, state.selectedBill.contractorBillableItems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          contractorBillableItems: [...contractorItems],
        },
      }
    case UPDATE_SUBCONTRACTOR_ITEM + '_SUCCESS':
      let itemsAfterUpdate = filter((item) => item.id !== action.response.id, state.selectedBill.contractorBillableItems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          contractorBillableItems: [...itemsAfterUpdate, action.response],
        },
      }
    case SEND_FOR_APPROVAL_ITEM + '_SUCCESS':
      let contItems = filter((item) => item.id !== action.response.item.id, state.selectedBill.contractorBillableItems)

      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          contractorBillableItems: [...contItems, action.response.item],
        },
      }
    case CREATE_EXTERNAL_SUBCONTRACTOR_ITEM + '_SUCCESS':
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          extContractorBillableItems: [...state.selectedBill.extContractorBillableItems, action.response],
        },
      }
    case DELETE_EXT_ITEM + '_SUCCESS':
      let itemsWithoutDeleted = filter((item) => item.id !== action.extId, state.selectedBill.extContractorBillableItems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          extContractorBillableItems: [...itemsWithoutDeleted],
        },
      }
    case 'UPDATE_ITEM_SUCCESS':
    case 'SENT_FOR_PAYMENT_SUCCESS':
    case 'OPEN_ITEM_SUCCESS':
      let extItems = filter((item) => item.id !== action.response.id, state.selectedBill.extContractorBillableItems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          extContractorBillableItems: [...extItems, action.response],
        },
      }
    case DELETE_ITEM_RECEIPT + '_SUCCESS':
      let extItems2 = filter((item) => item.id !== action.extId, state.selectedBill.extContractorBillableItems)
      const extItem = find(propEq('id', action.extId))(state.selectedBill.extContractorBillableItems)
      let extReceipts = []
      if (extItem && extItem.receipts) {
        extReceipts = filter((item) => item.id !== action.receiptId, extItem.receipts)
        return {
          ...state,
          selectedBill: {
            ...state.selectedBill,
            extContractorBillableItems: [
              ...extItems2,
              {
                ...extItem,
                receipts: [...extReceipts],
              },
            ],
          },
        }
      }
      return state

    case CREATE_EXTERNAL_BILL_FOR_ITEM + '_SUCCESS':
      let extItems3 = filter((item) => item.id !== action.extId, state.selectedBill.extContractorBillableItems)
      const extItem2 = find(propEq('id', action.extId))(state.selectedBill.extContractorBillableItems)
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          extContractorBillableItems: [
            ...extItems3,
            {
              ...extItem2,
              receipts: [...extItem2.receipts, action.response],
            },
          ],
        },
      }
    case VALIDATE_BILL + '_FAILURE':
      return {
        ...state,
        selected_bill_validation: {
          ...action.data,
        },
      }
    case VALIDATE_BILL + '_SUCCESS':
      return {
        ...state,
        selected_bill_validation: {
          ...action.response,
        },
      }
    case CREATE_ADVANCE_PAYMENT_SUCCESS + '_SUCCESS':
      return {
        ...state,
        selectedBill: action.response,
      }
    default:
      return state
  }
}

export default billReducer
