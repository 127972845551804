import {apiCall} from '../../services/api'


export function fetchUser(token) {
  return apiCall('/api/login', 'get', token)
}

export function login(credentials) {
  return apiCall('/api/login', 'post', null, credentials.credentials)
}

export function logoutFromApi(token) {
  return apiCall('/api/login', 'delete', null, token)
}

export function addSharedCustomer(token, data, callback) {
  return apiCall('/api/sharedcustomers', 'post', token, data, callback)
}
