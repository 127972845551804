import { apiCall } from '../../../services/api'

export function getBill(token, id, billId) {
  return apiCall(`/api/person/${id}/bills/${billId}`, 'get', token)
}

export function removeBillableItem(token, data) {
  return apiCall(`/api/billableitem/${data.id}`, 'delete', token)
}

export function validateBillFromApi(token, id) {
  return apiCall(`/api/person/bill/${id}/validate`, 'get', token)
}
