import Select from 'react-select'
import React from 'react'
import { reactSelectStyles } from '../../styles/ReactSelectStyles'
export const RouteSelectWithAddresses = ({
  field, // { name, value, onChange, onBlur }
  translate,
  disabled,
  label,
  selectClass,
  handleChange,
  setKilometers,
  value,
  values,
  routes,
  form,
  self,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const isOpenStr = `menuOpen${field.name}`
  const isOpen = self.state?.[isOpenStr] || false
  const onRouteChange = async (value, field, setFieldValue, values, form) => {
    // field.value = value.value
    if (value) {
      await setFieldValue(field.name, value.value)
      self.setKilometers(field, value, values, setFieldValue)
      self.setState({
        ...self.state,
        [isOpenStr]: false,
      })
    }
  }
  const routeOptions =
    routes?.map((route) => {
      return {
        value: route,
        label: route,
      }
    }) || []
  // eslint-disable-next-line
  const includes = routeOptions.find((val) => val.value === field.value)
  if (!includes && field.value) {
    routeOptions.push({
      value: field.value,
      label: field.value,
    })
  }
  const toggleMenu = (boolean) => {
    console.log('toggle menu',isOpenStr, boolean)
    self.setState({
      ...self.state,
      [isOpenStr]: boolean,
    })
  }
  const selectedOption = routeOptions?.find((routes) => routes.value === field.value) || field?.value
  return (
    <div className="col-xs-12">
      <label>{label}</label>
      <div>
        {props.children}
        <div className={selectClass}>
          <Select
            menuIsOpen={isOpen}
            onFocus={() => toggleMenu(true)}
            onBlur={() => toggleMenu(false)}
            disabled={disabled}
            options={routeOptions}
            value={selectedOption}
            placeholder={translate('misc.select')}
            inputProps={{ className: 'form-control22' }}
            clearable={false}
            isSearchable={false}
            autoload={false}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            styles={reactSelectStyles}
            onCloseResetsInput={false}
            filterOptions={(options, filter, currentValues) => {
              // Do no filtering, just return all options
              return options
            }}
            onChange={(value) => onRouteChange(value, field, setFieldValue, values, form)}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    </div>
  )
}
