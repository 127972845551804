import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { formatNumber, parseNumber } from '../../../components/Utils/formHelpers'
import { isMobile } from '../../Utils/detectMobile'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'
import {
  updateSubcontracorAction,
  deleteSubcontracorAction,
  sendForApprovalAction,
  openAction,
} from '../../../components/api/SubContractor/subContractorActions'
import { deleteModal } from '../../Modal/DeleteModal'
import { confirmModal } from '../../Modal/ConfirmModal'
import { dateToIsoDate, isoDateToDate } from '../../Utils/dateHelpers'
import ReactTooltip from 'react-tooltip'

class ContractorInvoiceRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      row: props.row,
      error: {},
      deleteModalOpen: false,
      sendModalOpen: false,
    }
    this.handleChangeAmount = this.handleChangeAmount.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeMobileDate = this.handleChangeMobileDate.bind(this)
    this.handleChangeDesktopDate = this.handleChangeDesktopDate.bind(this)
    this.submitRow = this.submitRow.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
  }

  componentDidMount() {}

  submitRow(event) {
    event && event.preventDefault()
    this.props.updateSubcontractorItem(this.state.row, this.props.bill.id)
  }

  handleChangeAmount(e) {
    const {
      error: { amount, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      row: {
        ...this.state.row,
        [e.target['name']]: parseNumber(e.target.value),
      },
    })
  }

  handleChange(e) {
    const {
      error: { description, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      row: {
        ...this.state.row,
        [e.target['name']]: e.target.value,
      },
    })
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { row } = nextprops
    this.setState({
      row: {
        ...this.state.row,
        ...row,
      },
    })
  }

  handleChangeMobileDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      row: {
        ...this.state.row,
        date: isoDateToDate(e.target.value),
      },
    })
    this.submitRow()
  }

  handleChangeDesktopDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      row: {
        ...this.state.row,
        date: e.format('DD.MM.YYYY'),
      },
    })
    this.submitRow()
  }

  toggleModal(modal, opened) {
    this.setState({ [modal]: opened })
  }

  getDateComponent(disabled) {
    const { translate, locale } = this.props
    if (!isMobile()) {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div
            data-tip
            data-iscapture="true"
            data-for="global-invoice-disabled-tooltip"
            data-tip-disable={!disabled}
          >
            <DayPickerInput
              value={this.state.row.date}
              dayPickerProps={{ localeUtils: LocaleUtils, locale }}
              readOnly={true}
              disabled={disabled}
              style={{ display: 'block' }}
              className="form-control"
              onDayChange={this.handleChangeDesktopDate}
              placeholder="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
            {this.state.error['date'] && (
              <span className="field-error">
                {translate('validation.' + this.state.error['date'])}
              </span>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div>
            <input
              disabled={disabled}
              placeholder={translate('subcontracting.date')}
              className="form-control input-lg"
              name="date"
              type="date"
              value={dateToIsoDate(this.state.row.date)}
              onChange={this.handleChangeMobileDate}
            />
            {this.state.error['date'] && (
              <span className="field-error">
                {translate('validation.' + this.state.error['date'])}
              </span>
            )}
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      )
    }
  }

  handleDelete() {
    this.toggleModal('deleteModalOpen', false)
    this.props.deleteSubcontractorItem(this.state.row, this.props.bill.id)
  }

  handleSend() {
    const { bill } = this.props
    this.props.sendForApproval(this.state.row, bill)
    this.toggleModal('sendModalOpen', false)
  }

  handleOpen() {
    this.props.openItem(this.state.row, this.props.bill.id)
    this.toggleModal('openModalOpen', false)
  }

  toggleState = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  getButtonsByState() {
    const { translate, bill } = this.props
    const { row } = this.state
    const disabled = !row.canBeEdited
    let buttons = []
    if (!disabled && row.state === 'OPEN') {
      buttons = [
        ...buttons,
        <button
          type="button"
          key="send-button"
          disabled={disabled && row.state === 'OPEN'}
          className="delete btn btn-square dkblue"
          onClick={() => {
            this.toggleModal('sendModalOpen', true)
          }}
        >
          {translate('subcontracting.send')}
          <i className="glyphicon no-border icon-plane"></i>
        </button>,
      ]
      buttons = [
        ...buttons,
        <button
          type="button"
          key="delete-button"
          disabled={disabled && row.state === 'OPEN'}
          className="delete btn btn-square dkblue"
          onClick={() => {
            this.toggleModal('deleteModalOpen', true)
          }}
        >
          {translate('invoice.row.deleteR')}
          <i className="glyphicon no-border icon-trash"></i>
        </button>,
      ]
    }
    if (!disabled) {
      if (
        row.state === 'WAITING_SUBCONTRACTOR' ||
        row.state === 'DECLINED' ||
        row.state === 'APPROVED'
      ) {
        buttons = [
          ...buttons,
          <button
            type="button"
            key="open-button"
            disabled={bill.state !== 'OPEN'}
            className="delete btn btn-square dkblue"
            onClick={() => {
              this.toggleModal('openModalOpen', true)
            }}
          >
            {translate('subcontracting.openRow')}
            <i className="glyphicon no-border icon-plane"></i>
          </button>,
        ]
      }
    }
    return buttons
  }
  render() {
    const { translate, bill } = this.props
    const { row } = this.state
    const disabled = !row.canBeEdited || row.state !== 'OPEN' || bill.state !== 'OPEN'
    const dateInput = this.getDateComponent(disabled)
    const description = `${row.subcontractor.fullName}, ${row.description}, ${formatNumber(
      row.amount,
    )} €`
    const messageForDeleteRow = translate('subcontracting.messageForDeleteBillableItem')
    const DeleteModal = deleteModal(
      this.state.deleteModalOpen,
      messageForDeleteRow,
      translate,
      () => {
        this.toggleModal('deleteModalOpen', false)
      },
      this.handleDelete,
    )
    const messageForSendRow = translate('subcontracting.messageForSendItem')
    const sendModal = confirmModal(
      this.state.sendModalOpen,
      messageForSendRow,
      translate,
      () => {
        this.toggleModal('sendModalOpen', false)
      },
      this.handleSend,
    )

    const messageForOpenRow = translate('subcontracting.messageForOpenItem')
    const openModal = confirmModal(
      this.state.openModalOpen,
      messageForOpenRow,
      translate,
      () => {
        this.toggleModal('openModalOpen', false)
      },
      this.handleOpen,
    )

    const buttons = this.getButtonsByState()
    return (
      <div className="row col-xs-12">
        <div className={this.state.collapsed ? 'invoice-row collapsed' : 'invoice-row'}>
          <div className="title noselect" onClick={this.toggleState.bind(this)}>
            <div>
              <span>{description}</span>
            </div>
            <div style={{ width: '40px' }}>
              <i className="chevron" />
            </div>
          </div>
          <div className="invoice-row-body">
            <form onSubmit={this.submitRow}>
              <div className="col-xs-12 col-sm-6">
                <div className="form-group">
                  <label className="dkblue">{translate('subcontracting.subcontractor')}</label>
                  <div>
                    <input
                      placeholder={translate('subcontracting.subcontractor')}
                      className="form-control input-lg"
                      name="subcontractor"
                      type="text"
                      value={this.state.row.subcontractor.email}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">{dateInput}</div>
              <div className="col-xs-12 col-sm-6">
                <div className="form-group">
                  <label className="dkblue">{translate('subcontracting.price')}</label>
                  <div>
                    <input
                      placeholder={translate('subcontracting.price')}
                      className="form-control input-lg"
                      name="amount"
                      type="text"
                      value={formatNumber(this.state.row.amount)}
                      onChange={this.handleChangeAmount}
                      onBlur={this.submitRow}
                      disabled={disabled}
                    />
                    {this.state.error['amount'] && (
                      <span className="field-error">
                        {translate('validation.' + this.state.error['amount'])}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="form-group">
                  <label className="dkblue">{translate('subcontracting.state')}</label>
                  <div>
                    <input
                      placeholder={translate('subcontracting.state')}
                      className="form-control input-lg"
                      name="state"
                      type="text"
                      value={translate('subcontracting.' + this.state.row.state)}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12">
                <div className="form-group">
                  <label className="dkblue">{translate('subcontracting.description')}</label>
                  <div>
                    <input
                      placeholder={translate('subcontracting.description')}
                      className="form-control input-lg"
                      name="description"
                      type="text"
                      value={this.state.row.description}
                      onChange={this.handleChange}
                      onBlur={this.submitRow}
                      disabled={disabled}
                    />
                    {this.state.error['description'] && (
                      <span className="field-error">
                        {translate('validation.' + this.state.error['description'])}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 margin-bottom">
                <div className="pull-right">{buttons}</div>
              </div>
              <button type="submit" hidden></button>
            </form>
          </div>
        </div>
        {DeleteModal}
        {sendModal}
        {openModal}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSubcontractorItem: (item, billId) => dispatch(updateSubcontracorAction(item, billId)),
    deleteSubcontractorItem: (item, billId) => dispatch(deleteSubcontracorAction(item, billId)),
    sendForApproval: (item, billId) => dispatch(sendForApprovalAction(item, billId)),
    openItem: (item, billId) => dispatch(openAction(item, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorInvoiceRow)
