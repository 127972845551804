export const SELECT_BILL = 'SELECT_BILL'
export const SELECT_NEW_BILL = 'SELECT_NEW_BILL'
export const CREATE_RECEIPT = 'CREATE_RECEIPT'
export const DELETE_RECEIPT = 'DELETE_RECEIPT'
export const GET_RECEIPT = 'GET_RECEIPT'
export const CURRENT_UPLOAD = 'CURRENT_UPLOAD'
export const GET_BILLABLE_ITEM = 'GET_BILLABLE_ITEM'
export const GET_BILLABLE_ITEMS = 'GET_BILLABLE_ITEMS'
export const CREATE_BILLABLE_ITEM = 'CREATE_BILLABLE_ITEM'
export const UPDATE_BILLABLE_ITEM = 'UPDATE_BILLABLE_ITEM'
export const DELETE_BILLABLE_ITEM = 'DELETE_BILLABLE_ITEM'
export const UPDATE_BILL = 'UPDATE_BILL'
export const UPDATE_DUE_DATE = 'UPDATE_DUE_DATE'
export const VALIDATE_BILL = 'VALIDATE_BILL'
export const CREATE_ADVANCE_PAYMENT_SUCCESS = 'CREATE_ADVANCE_PAYMENT_SUCCESS'

export const SAVE_BILLABLE_ITEM_START = 'SAVE_BILLABLE_ITEM_START'
export const SAVE_BILLABLE_ITEM_FINISHED = 'SAVE_BILLABLE_ITEM_FINISHED'

export const selectBillAction = (id) => ({
  type: SELECT_BILL,
  billId: id,
})

export const selectNewBill = (data) => ({
  type: SELECT_NEW_BILL,
  bill: data,
})
