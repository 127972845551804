import React, { Component } from 'react'
import { connect } from 'react-redux'

class RegisterView extends Component {
  render() {
    return <div className="row">{this.props.children}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView)
