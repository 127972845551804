import { fetchProfilePic, uploadPic, uploadCard } from './profileUploadsApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'

export function receiveProfilePic() {
  const user = getObject('user')
  return fetchProfilePic(path(['authtoken'], user), path(['id'], user))
    .then(profilePic => {
      return { response: profilePic }
    })
    .catch(err => {
      return err
    })
}
export function sendProfilePic(data) {
  const user = getObject('user')
  return uploadPic(path(['authtoken'], user), path(['id'], user), data.data, data.progressCallback)
    .then(response => {
      return { response: response }
    })
    .catch(err => {
      return err
    })
}
export function sendTaxCard(data) {
  const user = getObject('user')
  return uploadCard(path(['authtoken'], user), path(['id'], user), data.data, data.progressCallback)
    .then(response => {
      return { response: response }
    })
    .catch(err => {
      return err
    })
}

export function* getPicFromApi() {
  const { response, error } = yield call(receiveProfilePic)
  if (response) {
    yield put({ type: 'GET_PROFILE_PIC_SUCCESS', response })
  } else {
    yield put({ type: 'GET_PROFILE_PIC_FAILURE', error })
  }
}
export function* sendProfilePicWithApi(data) {
  const { response, error } = yield call(sendProfilePic, data)
  if (response) {
    if (response.status && response.status === 413) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!RECEIPT_TOO_LARGE', duration: 4000, type: 'error' },
      })
      yield put({ type: 'UPLOAD_TAX_CARD_FAILURE', error })
    } else {
      yield put({ type: 'UPLOAD_PROFILE_PIC_SUCCESS', response })
      yield call(getPicFromApi)
    }
  } else {
    yield put({ type: 'UPLOAD_PROFILE_PIC_FAILURE', error })
  }
}
export function* sendTaxCardWithApi(data) {
  const { response, error } = yield call(sendTaxCard, data)
  if (response) {
    if (response.status && response.status === 413) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!RECEIPT_TOO_LARGE', duration: 4000, type: 'error' },
      })
      yield put({ type: 'UPLOAD_TAX_CARD_FAILURE', error })
    } else {
      yield put({ type: 'UPLOAD_TAX_CARD_SUCCESS', response })
    }
  } else {
    yield put({ type: 'UPLOAD_TAX_CARD_FAILURE', error })
  }
}

export function* getProfilePic() {
  yield takeEvery('CHECK_PIC', getPicFromApi)
}
export function* uploadProfilePic() {
  yield takeEvery('UPLOAD_PIC', sendProfilePicWithApi)
}
export function* uploadTaxCardPic() {
  yield takeEvery('UPLOAD_TAX_CARD', sendTaxCardWithApi)
}
