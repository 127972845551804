import React, {Component} from 'react'

class Questions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: null
    }
  }

  toggleQuestion = (id) => {
    if (this.state.active === id) {
      this.setState({active: null})

    } else {
      this.setState({active: id})
    }
  }
  render() {
    const allQuestions = [
      {
        question: 'MITÄ SE MAKSAA?',
        answer: `Palvelumaksumme on 6.9% arvonlisäverottomasta
          kokonaislaskutuksestasi. Voit myös neuvotella kanssamme
          palvelumaksun suuruudesta, jos koet että vuotuinen
          kokonaislaskutuksesi tätä edellyttää.
          Laskutuksesta vähennetään lakisääteiset kulut.`
      },
      {
        question: 'ONKO LASKULLE MINIMISUMMAA?',
        answer: `sample text`
      },
      {
        question: 'KERTYYKÖ TYÖSTÄNI ELÄKETTÄ?',
        answer: `sample text`
      },
      {
        question: 'VOINKO LASKUTTAA ULKOMAALAISTA YRITYSTÄ?',
        answer: `sample text`
      },
      {
        question: 'OLENKO YRITTÄJÄ VAI PALKANSAAJA',
        answer: `sample text`
      },
      {
        question: 'MILLOIN LÄHETÄTTE ASIAKKAALLE MAKSUMUISTUTUKSEN?',
        answer: `sample text`
      },
      {
        question: 'TARVITSEEKO MINUN TOIMITTAA VEROKORTTI',
        answer: `sample text`
      },
      {
        question: 'VOIKO ASIAKAS LAITTAA LASKUN KOTITALOUSVÄHENNYKSEEN?',
        answer: `sample text`
      },
      {
        question: 'KUINKA VOIN VÄHENTÄÄ KULUJA?',
        answer: `sample text`
      },
      {
        question: 'MILLOIN SAAN PALKAN TILILLENI?',
        answer: `sample text`
      },
      {
        question: 'MILLOIN LÄHETÄTTE LASKUN ASIAKKAALLE?',
        answer: `sample text`
      },
      {
        question: 'MILLOIN LAITATTE LASKUN PERINTÄÄN?',
        answer: `sample text`
      }
    ]

    const getSomeQuestions = (start, end) => allQuestions.slice(start, end).map((question, key) => {
      return (
         <div className={this.state.active === start + key ? 'question opened' : 'question'} key={start + key}>
           <div className="header" onClick={this.toggleQuestion.bind(this, start + key)}>{question.question}</div>
           <div className="answer">{question.answer}</div>
           <div className="chevron"></div>
        </div>
      )
    })

    const renderQuestions = () => {
      const half = Math.round(allQuestions.length / 2) // css float not looking so good as split
      return (
        <div>
          <div className="col-xs-12 col-md-6">
            {getSomeQuestions(0, half)}
          </div>
          <div className="col-xs-12 col-md-6">
            {getSomeQuestions(half, allQuestions.length)}
          </div>
        </div>
      )
    }
    return (
        <div className="questions">
          <div className="row">
            {renderQuestions()}
          </div>
        </div>
    )
  }
}
export default Questions
