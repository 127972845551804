import React from 'react'
import DayPicker from 'react-day-picker'
import LocaleUtils from 'react-day-picker/moment'
import 'rc-time-picker/assets/index.css'
import 'moment/locale/fi'
import moment from 'moment'
import 'react-day-picker/lib/style.css'
import './modals.scss'
import ReactTooltip from 'react-tooltip'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { customDatePicker } from '../Utils/datePicker'
import { renderDateField } from '../FormComponents/DateField'
ReactTooltip.rebuild()

const validate = (values) => {
  const errors = {}
  if (values.workShiftStartedTime_date) {
    values.workShiftStartedTime = moment(values.workShiftStartedTime_date).format('HH:mm')
  }
  if (values.workShiftEndedTime_date) {
    values.workShiftEndedTime = moment(values.workShiftEndedTime_date).format('HH:mm')
  }
  const requiredFields = ['workShiftStarted', 'workShiftStartedTime', 'workShiftEnded', 'workShiftEndedTime']
  const start = moment(values.workShiftStarted + ' ' + values.workShiftStartedTime, 'DD.MM.YYYY HH:mm')
  const end = moment(values.workShiftEnded + ' ' + values.workShiftEndedTime, 'DD.MM.YYYY HH:mm')

  if (start.isAfter(end)) {
    errors.workShiftEndedTime = 'validation.start_after_end'
  }
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'validation.Required'
    }
  })
  return errors
}

const calculateWorkDuration = (startDate, endDate, startTime, endTime) => {
  const start = moment(`${startDate} ${startTime}`, 'DD.MM.YYYY HH.mm')
  const end = moment(`${endDate} ${endTime}`, 'DD.MM.YYYY HH.mm')
  const diff = end.diff(start)
  return Math.round(moment.duration(diff).asHours() * 100) / 100
}

const renderInputField = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  disabled,
  type,
  handleChange,
  values,
  parseFn,
  disableToolTip,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  let show = !(disabled && !disableToolTip)
  return (
    <div className="form-group">
      <label className="gray">{label}</label>
      <div>
        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={show}>
          <input
            disabled={disabled}
            placeholder={label}
            className="form-control input-lg"
            type={type || 'text'}
            value={field.value}
            name={field.name}
            onChange={
              disabled
                ? null
                : (event) => {
                    parseFn(field.name, event, values, setFieldValue)
                  }
            }
          />
        </div>
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

const setStartDate = (field, value, values, setFieldValue) => {
  const dayValue = moment(value).format('DD.MM.YYYY')
  const time = calculateWorkDuration(dayValue, values.workShiftEnded, values.workShiftStartedTime, values.workShiftEndedTime)
  setFieldValue('workhours', time)
  setFieldValue(field, dayValue)
}

const setEndDate = (field, value, values, setFieldValue) => {
  const dayValue = moment(value).format('DD.MM.YYYY')
  const time = calculateWorkDuration(values.workShiftStarted, dayValue, values.workShiftStartedTime, values.workShiftEndedTime)
  setFieldValue('workhours', time)
  setFieldValue(field, dayValue)
}

const setStartTime = (field, event, values, setFieldValue) => {
  const value = event.target.value
  const time = calculateWorkDuration(values.workShiftStarted, values.workShiftEnded, value, values.workShiftEndedTime)
  setFieldValue('workhours', time)
  setFieldValue(field, value)
}

const setEndTime = (field, event, values, setFieldValue) => {
  const value = event.target.value
  const time = calculateWorkDuration(values.workShiftStarted, values.workShiftEnded, values.workShiftStartedTime, value)
  setFieldValue('workhours', time)
  setFieldValue(field, value)
}



const TimeForm = (props) => {
  const { handleSubmit, disabled, translate, locale, onRequestHide, show, onHide, initialValues } = props

  const onCancel = () => {
    onRequestHide()
  }
  return (
    <Modal className="timeModal details" animation={false} dialogClassName="modal-90w" show={show} onHide={onHide} backdrop={'static'}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <div className="valign-helper">
            <form className="time-form" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{translate('invoice.row.time')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ReactTooltip id="global-invoice-disabled-tooltip" place="top" type="info" effect="solid">
                  {translate('tooltip.invoiceDisabled')}
                </ReactTooltip>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="form-group">
                      <label className="dkblue">{translate('invoice.timeModal.startDate')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <Field
                          disabled={disabled}
                          name="workShiftStarted"
                          translate={translate}
                          locale={locale}
                          values={values}
                          parseFn={setStartDate}
                          component={renderDateField}
                        />

                        {/*
                          <DayPicker
                            locale={locale}
                            localeUtils={LocaleUtils}
                            month={workShiftStarted}
                            selectedDays={workShiftStarted}
                            onDayClick={disabled ? null : changeStartDate}
                          />
                            */}
                      </div>
                      <Field component="input" style={{ display: 'none' }} name="workShiftStarted" />
                    </div>
                    <div className="form-group">
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <Field
                          disabled={disabled}
                          name="workShiftStartedTime"
                          type="time"
                          label={translate('invoice.row.startT')}
                          translate={translate}
                          locale={locale}
                          values={values}
                          parseFn={setStartTime}
                          component={renderInputField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="form-group">
                      <label className="dkblue">{translate('invoice.timeModal.endDate')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <Field
                          disabled={disabled}
                          name="workShiftEnded"
                          translate={translate}
                          locale={locale}
                          values={values}
                          parseFn={setEndDate}
                          component={renderDateField}
                        />
                        {/*
                          <DayPicker style={{ width: '100%' }}
                            locale={locale}
                            localeUtils={LocaleUtils}
                            month={workShiftEndedDate}
                            selectedDays={workShiftEndedDate}
                            onDayClick={disabled ? null : changeReturnDate}
                          />
                          */}
                      </div>
                      <Field component="input" style={{ display: 'none' }} name="workShiftEnded" />
                    </div>
                    <div className="form-group">
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <Field
                          disabled={disabled}
                          name="workShiftEndedTime"
                          type="time"
                          label={translate('invoice.row.endT')}
                          translate={translate}
                          values={values}
                          component={renderInputField}
                          parseFn={setEndTime}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4">
                    <label className="dkblue">{translate('invoice.row.workhours')}</label>
                    <div className="form-group">
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                        <Field
                          disabled={true}
                          name="workhours"
                          translate={translate}
                          values={values}
                          component={renderInputField}
                          disableToolTip={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="row">
                  <div className="col-xs-6">
                    <Button variant="cancel" onClick={onCancel}>
                      {translate('misc.cancel')}
                    </Button>
                  </div>
                  <div className="col-xs-6">
                    <Button variant="success" disabled={disabled} type="submit">
                      {translate('misc.accept')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default TimeForm
