import {BANK_CONFIRMED, EULA_AGREED, GET_USER_BY_TOKEN, USER_REGISTER_REQUESTED} from './registerActions'

import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'
import {agreeEulaApi, bankConfirmedApi, getUserByTokenFromApi, registerPerson} from './registerApi'
import {browserHistory} from 'react-router'
import {path} from 'ramda'
import {setObject} from '../../components/Localstorage/localStorageService'

export function startRegistrationWithApi(data) {
  return registerPerson(data.credentials)
    .then((response) => {
      return { response: response }
    })
    .catch((err) => {
      return err
    })
}

export function getUserByTokenWithApi(data) {
  return getUserByTokenFromApi(data.token)
    .then((person) => {
      return { response: person }
    })
    .catch((err) => {
      return err
    })
}

export function agreeEulaWithApi(data) {
  return agreeEulaApi(data.user)
    .then((person) => {
      return { response: person }
    })
    .catch((err) => {
      return err
    })
}

export function sendBankConfirmation(data) {
  return bankConfirmedApi(data.code)
    .then((person) => {
      return { response: person }
    })
    .catch((err) => {
      return err
    })
}

export function* startRegistrationAction(data) {
  const { response, error } = yield call(startRegistrationWithApi, data)
  if (response) {
    yield put({ type: 'USER_REGISTER_REQUESTED_SUCCESS', response, data: data.data })
    const url = `${response.endpointUrl}?request=${response.request}`
    window.location.href = url
  } else {
    const errors = path(['response', 'data', 'error'], error) || 'contactAdmin'
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: `LOCALIZED!${errors}`, duration: 30000, type: 'error' },
    })
    yield put({ type: 'USER_REGISTER_REQUESTED_FAILURE', error })
  }
}

export function* getUserByTokenAction(data) {
  const { response, error } = yield call(getUserByTokenWithApi, data)
  if (response) {
    yield put({ type: 'GET_USER_BY_TOKEN_SUCCESS', response, data: data.data })
  } else {
    yield put({ type: 'GET_USER_BY_TOKEN_FAILURE', error })
    browserHistory.push('/registration/failed')
  }
}

export function* agreeEulaAction(data) {
  let newVar = yield call(agreeEulaWithApi, data)
  const { response, error } = newVar
  if (response) {
    setObject('user', response)
    yield put({ type: 'CHECK_USER' })
    browserHistory.push('/account')
  } else {
    yield put({ type: 'EULA_AGREED_FAILURE', error })
    browserHistory.push('/registration/failed')
  }
}

export function* confirmBank(data) {
  if (!data?.code?.code) {
    browserHistory.push('/register')
  } else {
    const { response, error } = yield call(sendBankConfirmation, data)
    if (response) {
      setObject('user', response)
      if (response.role === 'ROLE_USER') {
        yield put({ type: 'CHECK_USER' })
        browserHistory.push('/account')
      } else {
        browserHistory.push('/registration/eula')
      }
    } else {
      yield put({ type: 'EULA_AGREED_FAILURE', error })
      browserHistory.push('/registration/failed')
    }
  }
}

export function* startRegistration() {
  yield takeEvery(USER_REGISTER_REQUESTED, startRegistrationAction)
}

export function* getUserByToken() {
  yield takeEvery(GET_USER_BY_TOKEN, getUserByTokenAction)
}

export function* agreeEula() {
  yield takeEvery(EULA_AGREED, agreeEulaAction)
}

export function* bankConfirmed() {
  yield takeEvery(BANK_CONFIRMED, confirmBank)
}
