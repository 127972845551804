import {
  GET_SALARIES
} from './salariesActions'

const salariesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SALARIES + '_SUCCESS':
      return {
        ...state,
        list: action.response
      }
    case GET_SALARIES + '_FAILURE':
      return {
        ...state,
        list: {}
      }
    default:
      return state
  }
}

export default salariesReducer
