import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IndexLink, Link, browserHistory } from 'react-router'
import NinjouImage from './assets/ninjou_logo.png'
import ProfilePic from './assets/profile.png'
import ExitImage from './assets/exit.png'
import IconButton from '@material-ui/core/IconButton'
import {
  getTranslate,
  getLanguages,
  getActiveLanguage,
  setActiveLanguage,
} from 'react-localize-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import './Header.scss'
import ExpandMore from '@material-ui/icons/ExpandMore'
/* eslint jsx-a11y/anchor-is-valid: 0 */
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true, openMenu: false, anchorEl: null, languageOpen: false }
    this.handleClose = this.handleClose.bind(this)
    this.handleLanguage = this.handleLanguage.bind(this)
    this.logout = this.logout.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget,
    })
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.open !== this.state.open
      || nextState.openMenu !== this.state.openMenu
      || nextState.languageOpen !== this.state.languageOpen
      || this.props.currenctLocale !== nextProps.currenctLocale
    }
     */

  handleRequestClose() {
    this.setState({
      openMenu: false,
    })
  }

  handleLanguage(event) {
    this.setState({
      languageOpen: true,
      anchorEl: event.currentTarget,
    })
  }

  changeLanguage = (event, value) => {
    this.setState({
      languageOpen: false,
      anchorEl: null,
    })
    this.props.setLanguage(value)
    this.props.user &&
      this.props.user.authtoken &&
      this.props.updateProfile({ ...this.props.user, language: value })
  }

  handleClose() {
    this.setState({
      languageOpen: false,
      anchorEl: null,
    })
  }

  componentDidMount() {
    const { user } = this.props
    if (!user || !user.authtoken) {
      return null
    }

    const language = this.props.user && this.props.user.language
    language && this.props.setLanguage(language)
  }

  logout() {
    browserHistory.push('/logout')
  }

  render() {
    const { user, translate } = this.props
    const image = `url(${NinjouImage})`
    const currentLang = getActiveLanguage(this.props.locale).code
    if (!user || !user.authtoken) {
      return (
        <nav className="navbar navbar-default navbar-static-top">
          <div className="container-fluid">
            <div className="navbar-header">
              <IndexLink
                style={{ backgroundImage: image }}
                to="/"
                className="navbar-brand ninjou-logo"
              >
                Ninjou
              </IndexLink>
              <ul className="nav navbar-nav">
                <li
                  style={{ fontWeight: 600 }}
                  className="language-switcher dropdown header-profile"
                >
                  <a onClick={this.handleLanguage}>{currentLang}</a>
                  <Menu
                    style={{ textTransform: 'uppercase' }}
                    open={this.state.languageOpen}
                    anchorEl={this.state.anchorEl}
                    value={getActiveLanguage(this.props.locale).code}
                    TransitionComponent={Fade}
                    onClose={this.handleClose}
                  >
                    {getLanguages(this.props.locale).map((language, key) => (
                      <MenuItem
                        style={{ textTransform: 'uppercase' }}
                        onClick={event => this.changeLanguage(event, language.code)}
                        key={key}
                        value={language.code}
                      >
                        {' '}
                        {language.code}{' '}
                      </MenuItem>
                    ))}
                  </Menu>
                </li>
              </ul>
            </div>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="https://ninjou.fi">{translate('header.about')}</a>
              </li>
              <li>
                <Link to="/calculator" activeClassName="route--active">
                  {translate('header.calculator')}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      )
    }
    // authenticated
    return (
      <div>
        <nav className="navbar navbar-default navbar-main navbar-static-top">
          <div className="container-fluid">
            <div className="collapsed navbar-collapse">
              <div>
                <ul className="nav navbar-nav">
                  <li
                    style={{ fontWeight: 600 }}
                    className="language-switcher dropdown header-profile"
                  >
                    <a onClick={this.handleLanguage}>{currentLang}</a>
                    <Menu
                      style={{ textTransform: 'uppercase' }}
                      open={this.state.languageOpen}
                      anchorEl={this.state.anchorEl}
                      value={getActiveLanguage(this.props.locale).code}
                      TransitionComponent={Fade}
                      onClose={this.handleClose}
                    >
                      {getLanguages(this.props.locale).map((language, key) => (
                        <MenuItem
                          style={{ textTransform: 'uppercase' }}
                          onClick={event => this.changeLanguage(event, language.code)}
                          key={key}
                          value={language.code}
                        >
                          {' '}
                          {language.code}{' '}
                        </MenuItem>
                      ))}
                    </Menu>
                  </li>
                  <li className="dropdown header-profile name">
                    <div>
                      <Link to="/account">
                        <img className="img-circle " src={this.props.pic} alt="profile" />
                        <span>{user.fullName}</span>
                      </Link>
                    </div>
                  </li>
                  <li className="logout-link">
                    <Link to="/logout">
                      <img style={{ height: '17px' }} src={ExitImage} alt="sign-out"/>
                      {translate('header.signOut')}
                    </Link>
                  </li>
                  <li className="collapse" style={{ margin: '0 -15px' }}>
                    <div>
                      <IconButton
                        aria-label="More"
                        aria-owns="long-menu"
                        aria-haspopup={true}
                        onClick={this.handleClick}
                      >
                        <ExpandMore color="action" />
                      </IconButton>
                    </div>

                    <Menu
                      open={this.state.openMenu}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleRequestClose}
                    >
                      <MenuItem onClick={this.logout}>{translate('header.signOut')}</MenuItem>
                    </Menu>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    pic: state.profileUploads.pic || ProfilePic,
    translate: getTranslate(state.locale),
    locale: state.locale,
    currenctLocale: getActiveLanguage(state.locale).code,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLanguage: value => dispatch(setActiveLanguage(value)),
    updateProfile: data => dispatch({ type: 'UPDATE_PROFILE', data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
