import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginForm from './loginForm'
import './login.scss'
import NinjouImage from '../assets/technology.png'
import { getTranslate } from 'react-localize-redux'
import { browserHistory, IndexLink } from 'react-router'

class LoginView extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    this.props.login(values)
  }

  componentDidMount() {
    const { user } = this.props
    if (user && user.authtoken) {
      browserHistory.push('')
    }
  }

  render() {
    const image = `url(${NinjouImage})`
    const { translate } = this.props
    return (
      <div className="row">
        <div className="content-bg" style={{ backgroundImage: image }}></div>
        <div className="col-xs-12 login-container no-padding">
          <div className="col-lg-6 welcome-container no-padding" style={{ backgroundImage: image }}>
            <div className="row">
              <div className="col-xs-12">
                <h1>{translate('login.welcomeTitle')}</h1>
              </div>
              <div className="col-xs-12">
                <span>{translate('login.welcomeText')}</span>
              </div>
              <div className="col-xs-10 col-xs-offset-1 col-md-offset-0">
                <IndexLink to="/register" className="register-link">
                  {translate('login.register')}
                </IndexLink>
              </div>
            </div>
          </div>
          <div className="col-lg-6 login-form-container">
            <div className="col-xs-10 col-xs-offset-1">
              <div className="row">
                <LoginForm submitValues={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user || {},
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: (credentials) => dispatch({ type: 'USER_LOGIN_REQUESTED', credentials }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
