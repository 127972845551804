import React from 'react'
import { getEnterpreneurSalary } from './EntepreneurSummary'
import { getWorkerSummary } from './WorkerSummary'

export const summary = (calculated, bill, user, translate) => {
  if (calculated.salaryType === 'WORKER') {
    return getWorkerSummary(calculated, bill, user, translate)
  } else return getEnterpreneurSalary(calculated, bill, user, translate)
}



