import {
  fetchSubContractors,
  createSubcontractorItemApiCall,
  updateSubcontractorItemApiCall,
  deleteSubcontractorItemApiCall,
  approveSubcontractorItemApiCall,
  declineSubcontractorItemApiCall,
  sendForApprovalSubcontractorItemApiCall,
  newBillAttachmentApiCall,
  newBillAttachmentWithIdApiCall,
  createExtItemApiCall,
  deleteReceiptApiCall,
  sendForPaymentApiCall,
  openItemApiCall,
  updateItemApiCall,
  deleteExtItemApiCall,
  deletePlainReceiptApiCall,
} from './subcontractorApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'
import {
  SEARCH_SUBCONTRACTORS,
  CREATE_SUBCONTRACTOR_ITEM,
  UPDATE_SUBCONTRACTOR_ITEM,
  DELETE_SUBCONTRACTOR_ITEM,
  APPROVE_SUBCONTRACTOR_ITEM,
  DECLINE_SUBCONTRACTOR_ITEM,
  SEND_FOR_APPROVAL_ITEM,
  CREATE_EXTERNAL_BILL,
  CREATE_EXTERNAL_BILL_FOR_ITEM,
  CREATE_EXTERNAL_SUBCONTRACTOR_ITEM,
  DELETE_ITEM_RECEIPT,
  SENT_FOR_PAYMENT,
  OPEN_ITEM,
  UPDATE_ITEM,
  DELETE_EXT_ITEM,
  DELETE_PLAIN_RECEIPT,
} from './subContractorActions'

function searchSubContractorsFromApi(obj) {
  const user = getObject('user')
  return fetchSubContractors(path(['authtoken'], user), obj.query)
    .then(item => {
      return { response: item }
    })
    .catch(err => {
      return err
    })
}

function createSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return createSubcontractorItemApiCall(path(['authtoken'], user), obj.billId, obj.item)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: obj.billId }
    })
    .catch(err => {
      return err
    })
}

function updateSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return updateSubcontractorItemApiCall(path(['authtoken'], user), obj.billId, obj.item)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: obj.billId }
    })
    .catch(err => {
      return err
    })
}

function deleteSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return deleteSubcontractorItemApiCall(path(['authtoken'], user), obj.billId, obj.item)
    .then(subcontractors => {
      return {
        response: {
          contractorBillableItemId: obj.item.id,
        },
      }
    })
    .catch(err => {
      return err
    })
}

function approveSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return approveSubcontractorItemApiCall(path(['authtoken'], user), obj.item)
    .then(item => {
      return {
        response: {
          item,
        },
      }
    })
    .catch(err => {
      return err
    })
}

function declineSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return declineSubcontractorItemApiCall(path(['authtoken'], user), obj.item)
    .then(item => {
      return {
        response: {
          item,
        },
      }
    })
    .catch(err => {
      return err
    })
}

function sendForApprovalSubcontractorItemFromApi(obj) {
  const user = getObject('user')
  return sendForApprovalSubcontractorItemApiCall(path(['authtoken'], user), obj.item)
    .then(item => {
      return {
        response: {
          item,
        },
      }
    })
    .catch(err => {
      return err
    })
}

function createReceiptWithApi(data) {
  const user = getObject('user')
  return newBillAttachmentApiCall(path(['authtoken'], user), data.data, data.callback)
    .then(receipt => {
      return { response: receipt }
    })
    .catch(err => {
      return err
    })
}

function createReceiptWithIdWithApi(data) {
  const user = getObject('user')
  return newBillAttachmentWithIdApiCall(
    path(['authtoken'], user),
    data.data,
    data.extId,
    data.callback,
  )
    .then(receipt => {
      return { response: receipt }
    })
    .catch(err => {
      return err
    })
}

function createExtItemFromApi(data) {
  const user = getObject('user')
  return createExtItemApiCall(path(['authtoken'], user), data.billId, data.data)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: data.billId }
    })
    .catch(err => {
      return err
    })
}

function deleteReceiptFromApi(data) {
  const user = getObject('user')
  return deleteReceiptApiCall(path(['authtoken'], user), data.receiptId, data.billId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: data.billId }
    })
    .catch(err => {
      return err
    })
}

function sendFromPaymentFromApi(data) {
  const user = getObject('user')
  return sendForPaymentApiCall(path(['authtoken'], user), data.extId, data.billId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: data.billId }
    })
    .catch(err => {
      return err
    })
}

function openItemFromApi(data) {
  const user = getObject('user')
  return openItemApiCall(path(['authtoken'], user), data.extId, data.billId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: data.billId }
    })
    .catch(err => {
      return err
    })
}

function updateItemFromApi(data) {
  const user = getObject('user')
  return updateItemApiCall(path(['authtoken'], user), data.item, data.billId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem, billId: data.billId }
    })
    .catch(err => {
      return err
    })
}

function deleteExtItemFromApi(data) {
  const user = getObject('user')
  return deleteExtItemApiCall(path(['authtoken'], user), data.extId, data.billId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem }
    })
    .catch(err => {
      return err
    })
}

function deletePlainReceiptFromApi(data) {
  const user = getObject('user')
  return deletePlainReceiptApiCall(path(['authtoken'], user), data.receiptId)
    .then(contractorBillableItem => {
      return { response: contractorBillableItem }
    })
    .catch(err => {
      return err
    })
}

function* searchSubContractors(obj) {
  const { response, error } = yield call(searchSubContractorsFromApi, obj)
  if (response) {
    yield put({ type: `${SEARCH_SUBCONTRACTORS}_SUCCESS`, response })
  } else {
    yield put({ type: `${SEARCH_SUBCONTRACTORS}_FAILURE`, error })
  }
}

function* createSubcontractorItem(item) {
  const { response, error } = yield call(createSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${CREATE_SUBCONTRACTOR_ITEM}_SUCCESS`, response })
    yield put({ type: 'CALCULATE_FROM_BILL', data: { id: item.billId } })
  } else {
    yield put({ type: `${CREATE_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* updateSubcontractorItem(item) {
  const { response, error } = yield call(updateSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${UPDATE_SUBCONTRACTOR_ITEM}_SUCCESS`, response })
    yield put({ type: 'CALCULATE_FROM_BILL', data: { id: item.billId } })
  } else {
    yield put({ type: `${UPDATE_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* deleteSubcontractorItem(item) {
  const { response, error } = yield call(deleteSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${DELETE_SUBCONTRACTOR_ITEM}_SUCCESS`, response })
    yield put({ type: 'CALCULATE_FROM_BILL', data: { id: item.billId } })
  } else {
    yield put({ type: `${DELETE_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* approveSubcontractorItem(item) {
  const { response, error } = yield call(approveSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${APPROVE_SUBCONTRACTOR_ITEM}_SUCCESS`, response })
  } else {
    yield put({ type: `${APPROVE_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* declineSubcontractorItem(item) {
  const { response, error } = yield call(declineSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${DECLINE_SUBCONTRACTOR_ITEM}_SUCCESS`, response })
  } else {
    yield put({ type: `${DECLINE_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* sendForApprovalSubcontractorItem(item) {
  const { response, error } = yield call(sendForApprovalSubcontractorItemFromApi, item)
  if (response) {
    yield put({ type: `${SEND_FOR_APPROVAL_ITEM}_SUCCESS`, response })
  } else {
    yield put({ type: `${SEND_FOR_APPROVAL_ITEM}_FAILURE`, error })
  }
}

function* tryCreateAttachment(data) {
  const billId = data.data.id
  const { response, error } = yield call(createReceiptWithApi, data)
  if (response) {
    if (response.status && response.status === 413) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!RECEIPT_TOO_LARGE', duration: 4000, type: 'error' },
      })
      yield put({ type: 'CREATE_EXTERNAL_BILL_FAILURE', error })
    } else {
      yield put({ type: 'CREATE_EXTERNAL_BILL_SUCCESS', response, billId: billId })
    }
  } else {
    yield put({ type: 'CREATE_EXTERNAL_BILL_FAILURE', error })
  }
}

function* tryCreateAttachmentWithId(data) {
  const billId = data.data.id
  const { response, error } = yield call(createReceiptWithIdWithApi, data)
  if (response) {
    if (response.status && response.status === 413) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!RECEIPT_TOO_LARGE', duration: 4000, type: 'error' },
      })
      yield put({ type: 'CREATE_EXTERNAL_BILL_FOR_ITEM_FAILURE', error })
    } else {
      yield put({
        type: 'CREATE_EXTERNAL_BILL_FOR_ITEM_SUCCESS',
        response,
        billId: billId,
        extId: data.extId,
      })
    }
  } else {
    yield put({ type: 'CREATE_EXTERNAL_BILL_FOR_ITEM_FAILURE', error })
  }
}

function* tryCreateExternalItem(data) {
  const { response, error } = yield call(createExtItemFromApi, data)
  if (response) {
    yield put({
      type: `${CREATE_EXTERNAL_SUBCONTRACTOR_ITEM}_SUCCESS`,
      response,
      billId: data.billId,
    })
    yield put({ type: 'CALCULATE_FROM_BILL', data: { id: data.billId } })
  } else {
    yield put({ type: `${CREATE_EXTERNAL_SUBCONTRACTOR_ITEM}_FAILURE`, error })
  }
}

function* deleteReceiptItem(data) {
  const { response, error } = yield call(deleteReceiptFromApi, data)
  if (response) {
    yield put({
      type: `${DELETE_ITEM_RECEIPT}_SUCCESS`,
      response,
      billId: data.billId,
      receiptId: data.receiptId,
      extId: data.extId,
    })
  } else {
    yield put({ type: `${DELETE_ITEM_RECEIPT}_FAILURE`, error })
  }
}

function* sendForPaymentItem(data) {
  const { response, error } = yield call(sendFromPaymentFromApi, data)
  if (response) {
    yield put({ type: `${SENT_FOR_PAYMENT}_SUCCESS`, response, billId: data.billId })
  } else {
    yield put({ type: `${SENT_FOR_PAYMENT}_FAILURE`, error })
  }
}

function* openItem(data) {
  const { response, error } = yield call(openItemFromApi, data)
  if (response) {
    yield put({ type: `${OPEN_ITEM}_SUCCESS`, response, billId: data.billId })
  } else {
    yield put({ type: `${OPEN_ITEM}_FAILURE`, error })
  }
}

function* updateItem(data) {
  const { response, error } = yield call(updateItemFromApi, data)
  if (response) {
    yield put({ type: `${UPDATE_ITEM}_SUCCESS`, response, billId: data.billId })
  } else {
    yield put({ type: `${UPDATE_ITEM}_FAILURE`, error })
  }
}

function* deleteExtItem(data) {
  const { response, error } = yield call(deleteExtItemFromApi, data)
  if (response) {
    yield put({
      type: `${DELETE_EXT_ITEM}_SUCCESS`,
      response,
      extId: data.extId,
      billId: data.billId,
    })
  } else {
    yield put({ type: `${DELETE_EXT_ITEM}_FAILURE`, error })
  }
}

function* deletePlainReceiptItem(data) {
  const { response, error } = yield call(deletePlainReceiptFromApi, data)
  if (response) {
    yield put({
      type: `${DELETE_PLAIN_RECEIPT}_SUCCESS`,
      response,
      receiptId: data.receiptId,
      billId: data.billId,
    })
  } else {
    yield put({ type: `${DELETE_PLAIN_RECEIPT}_FAILURE`, error })
  }
}

export function* findSubContractorsSaga() {
  yield takeEvery(SEARCH_SUBCONTRACTORS, searchSubContractors)
}

export function* createSubcontractorItemSaga() {
  yield takeEvery(CREATE_SUBCONTRACTOR_ITEM, createSubcontractorItem)
}

export function* updateSubcontracorItemSaga() {
  yield takeEvery(UPDATE_SUBCONTRACTOR_ITEM, updateSubcontractorItem)
}

export function* deleteSubcontracorItemSaga() {
  yield takeEvery(DELETE_SUBCONTRACTOR_ITEM, deleteSubcontractorItem)
}

export function* approveSubcontracorItemSaga() {
  yield takeEvery(APPROVE_SUBCONTRACTOR_ITEM, approveSubcontractorItem)
}

export function* declineSubcontracorItemSaga() {
  yield takeEvery(DECLINE_SUBCONTRACTOR_ITEM, declineSubcontractorItem)
}

export function* sendForApprovalSubcontracorItemSaga() {
  yield takeEvery(SEND_FOR_APPROVAL_ITEM, sendForApprovalSubcontractorItem)
}

export function* saveBillAttachmentSaga() {
  yield takeEvery(CREATE_EXTERNAL_BILL, tryCreateAttachment)
}

export function* saveBillAttachmentForItemSaga() {
  yield takeEvery(CREATE_EXTERNAL_BILL_FOR_ITEM, tryCreateAttachmentWithId)
}

export function* createExternalItemSaga() {
  yield takeEvery(CREATE_EXTERNAL_SUBCONTRACTOR_ITEM, tryCreateExternalItem)
}

export function* deleteReceiptSaga() {
  yield takeEvery(DELETE_ITEM_RECEIPT, deleteReceiptItem)
}

export function* sendForPayment() {
  yield takeEvery(SENT_FOR_PAYMENT, sendForPaymentItem)
}

export function* openItemSaga() {
  yield takeEvery(OPEN_ITEM, openItem)
}

export function* updateItemSaga() {
  yield takeEvery(UPDATE_ITEM, updateItem)
}

export function* deleteExtItemSaga() {
  yield takeEvery(DELETE_EXT_ITEM, deleteExtItem)
}

export function* deletePlainReceiptSaga() {
  yield takeEvery(DELETE_PLAIN_RECEIPT, deletePlainReceiptItem)
}
