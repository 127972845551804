import {connect} from 'react-redux'
import React, {Component} from 'react'
import {NotificationContainer, NotificationManager} from 'react-notifications'
import {getTranslate} from 'react-localize-redux'
import 'react-notifications/lib/notifications.css'
import './notificationArea.scss'

class NotificationArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: []
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.props.notification !== nextprops.notification) {
      const {notification, translate} = nextprops
      let {message, title, duration} = nextprops.notification
      if (/^LOCALIZED!.*$/.test(notification.message)) {
        notification.message = notification.message.substr(10)
        message = translate('notification.' + notification.message)
      }
      NotificationManager[notification.type](message, title, duration, () => {})
    }
  }

  render() {
    return (
      <NotificationContainer/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notification: state.notification,
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationArea)
