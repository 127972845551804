import React from 'react'
import { customDatePicker } from '../Utils/datePicker'
import moment from 'moment'

export const renderDateField = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  disabled,
  locale,
  form,
  calculateWorkTime,
  form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const onDateChange = async (day, field) => {
    if (!day) {
      return
    }
    if (day.isValid()) {
      const format = day.format('DD.MM.YYYY')
      await setFieldValue(field, format)
      values[field] = format
    }
    if (calculateWorkTime) {
      if (values.time && values.startdate && values.returndate && values.returntime) {
        const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
        const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
        const diffInMinuts = end.diff(start, 'minutes')
        const travelTime = values.travelTimeInMinutes || 0
        values.workTimeInMinutes = diffInMinuts - travelTime
        setFieldValue('workTimeInMinutes', values.workTimeInMinutes)
        setFieldValue('totalTime', diffInMinuts)
      }
    }
  }
  const handler = (event) => onDateChange(event, field.name)
  const dayPickerInput = customDatePicker(field.value, translate('invoice.date'), handler, locale, disabled)
  return (
    <div className="form-group">
      <div>
        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
          {dayPickerInput}
        </div>
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}
