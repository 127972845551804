import {
  SEARCH_SUBCONTRACTORS
} from './subContractorActions'

const subcontractorReducer = (state = {list:[]}, action) => {
  switch (action.type) {
    case SEARCH_SUBCONTRACTORS + '_SUCCESS':
      return {
        ...state,
        list: action.response
      }
    case SEARCH_SUBCONTRACTORS + '_FAILURE':
      return {
        ...state,
        list: {}
      }
    default:
      return state
  }
}

export default subcontractorReducer
