import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import allCountries from '../../../components/Utils/countries.json'
import { Field, Formik } from 'formik'
import { Button } from 'react-bootstrap'
import '../../Customers/components/customers.scss'
import '../../../components/Client/client.scss'

const validBusinessId = (value) => {
  const businessIdPattern = /^[0-9]{7,7}[-][0-9]$/
  if (!businessIdPattern.test(value)) {
    return false
  }
  var array = value.split('')
  var checker = [7, 9, 10, 5, 8, 4, 2]
  var sum = 0
  for (var i = 0; i < checker.length; i++) {
    sum += parseInt(array[i]) * checker[i]
  }
  var checkNumber = sum % 11
  if (checkNumber >= 2) {
    checkNumber = 11 - checkNumber
  }
  if (+array[8] !== checkNumber) {
    return false
  }
  return true
}

const validSotu = value => {
  const sotu = /^(0[1-9]|[12]\\d|3[01])(0[1-9]|1[0-2])([5-9]\\d+|\\d\\d-|[01]\\dA)\\d{3}[\\dA-Z]$/
  return sotu.test(value)
}

const validateFn = values => {
  const errors = {}

  const requiredFields = [
    'name',
    'termofpayment',
    'address',
    'postalcode',
    'city',
    'email',
    'phonenumber',
  ]

  if (values.sendBillMethod === 'EBILL') {
    if (!values.netbankaddress) {
      errors.netbankaddress = 'validation.required'
    }
    if (!values.operator) {
      errors.operator = 'validation.required'
    }
  }
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'validation.required'
    } else if (
      field === 'termofpayment' &&
      (values.termofpayment < 1 || values.termofpayment > 31)
    ) {
      errors.termofpayment = 'validation.invalidTermofpayment'
    } else if (
      field === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'validation.invalidEmail'
    }
  })
  if (values.partyRole === 'COMPANY') {
    if (!values.vat && !validBusinessId(values.businessid)) {
      errors.businessid = 'validation.invalidBusinessId'
    }
    if (!values.vat && !values.businessid) {
      errors.businessid = 'validation.businessidOrVat'
      errors.vat = 'validation.businessidOrVat'
    }
  } else {
    if (values.personSotu && !validSotu(values.personSotu)) {
      errors.personSotu = 'validation.invalidSotu'
    }
  }
  return errors
}

const InputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input
        type="text"
        className="form-control input-lg"
        placeholder={label}
        {...field}
        {...props}
      />
      {errors[field.name] && touched[field.name] && (
        <span className="field-error">{translate(errors[field.name])}</span>
      )}
    </div>
  </div>
)

class AddClientForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchResult: [],
      modalOpen: false,
      searchString: '',
      typing: false,
      typingTimeOut: 0,
      notSearched: true,
      showCreateCustomer: false,
    }
    this.doSearch = this.doSearch.bind(this)
  }

  doSearch = data => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }
    if (!data) return
    this.props.findCustomers(data)
    this.setState({ typing: false })
  }

  onSearch = e => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      searchString: e.target.value,
      typing: true,
      notSearched: false,
      typingTimeout: setTimeout(() => {
        this.doSearch(this.state.searchString)
      }, 200),
    })
  }
  callSearch = e => {
    if (e.keyCode === 13 || typeof e.keyCode === 'undefined') {
      this.doSearch(this.state.searchString)
      this.setState({ typing: false, searchResult: [] })
    }
  }

  showCreateCustomer = () => {
    this.setState({
      ...this.state,
      showCreateCustomer: !this.state.showCreateCustomer,
    })
  }

  customerSelected = (event, customer) => {
    const { selectCustomer } = this.props
    event.preventDefault()
    this.props.clearCustomers()
    selectCustomer(customer)
  }

  getSearchCustomerForm = (translate, addCustomerForm) => {
    const { searchResult, notSearched } = this.props
    const { showCreateCustomer } = this.state
    const disableIfNotSearched = notSearched && this.state.notSearched
    const clients = (searchResult || []).map((client, key) => (
      <tr key={key}>
        <td>
          <button className="link-button" onClick={event => this.customerSelected(event, client)}>
            {client.name}
          </button>
        </td>
        <td className="hide-mobile black">{client.organization}</td>
        <td className="black">{client.businessid}</td>
        <td className="hide-mobile black">{client.phonenumber}</td>
        <td className="hide-mobile black">{client.email}</td>
        <td className="black">
          {client.sendBillMethod
            ? translate('customers.fields.sendBillMethod.' + client.sendBillMethod)
            : '-'}
        </td>
        <td>
          <button className="link-button" onClick={event => this.customerSelected(event, client)}>
            Valitse asiakas
          </button>
        </td>
      </tr>
    ))
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <input
                className="form-control search"
                onChange={this.onSearch}
                onKeyDown={this.callSearch}
                placeholder={translate('customers.search')}
                type="text"
              />
              <span
                className="glyphicon glyphicon-search top-10 form-control-feedback"
                aria-hidden="true"
              ></span>
            </div>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{translate('customers.fields.name')}</th>
                <th className="hide-mobile">{translate('customers.fields.organization')}</th>
                <th>{translate('customers.fields.businessid')}</th>
                <th className="hide-mobile">{translate('customers.fields.phonenumber')}</th>
                <th className="hide-mobile">{translate('customers.fields.email')}</th>
                <th>{translate('customers.fields.method')}</th>
                <th>{translate('customers.fields.choose')}</th>
              </tr>
            </thead>
            <tbody>{clients}</tbody>
          </table>
        </div>
        <div className="row margin-top-bottom">
          <Button
            disabled={disableIfNotSearched}
            variant="cancel"
            onClick={this.showCreateCustomer}
          >
            {translate('customers.createNewCustomerNotFound')}
          </Button>
        </div>
        {showCreateCustomer ? addCustomerForm : ''}
      </div>
    )
  }
  render() {
    const { handleSubmit, translate, hideSearch } = this.props
    /*
    truth table when not to create customer
    0 0 1
    0 1 1
    1 0 1
    1 1 0
    */

    const initialValues = {
      costUnits: [],
      people: [],
      wait: true,
      name: '',
      partyRole: 'COMPANY',
      businessid: '',
      vat: '',
      netbankaddress: '',
      operator: '',
      sendBillMethod: 'EBILL',
      termofpayment: 14,
      referenceNumber: '',
      organization: '',
      address: '',
      postalcode: '',
      city: '',
      addresscountry: 'FIN',
      phonenumber: '',
      email: '',
      email2: '',
    }
    const counties = allCountries.map(country => (
      <option value={country['alpha-3']} key={country['alpha-3']}>
        {country.name}{' '}
      </option>
    ))

    const addCustomerForm = (
      <Formik
        initialValues={initialValues}
        validate={validateFn}
        onSubmit={(values, { setSubmitting }) => {
          this.props.clearCustomers()
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <form className="clearfix account-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.general')}</label>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="name"
                    label={translate('customers.fields.name')}
                    translate={translate}
                    component={InputComponent}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.type')}</label>
                    <div>
                      <Field name="partyRole" component="select" className="form-control input-lg">
                        <option value="COMPANY">
                          {translate('customers.fields.partyRole.COMPANY')}
                        </option>
                        <option value="CUSTOMER">
                          {translate('customers.fields.partyRole.CUSTOMER')}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="businessid"
                    label={translate('customers.fields.businessid')}
                    translate={translate}
                    component={InputComponent}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="vat"
                    label={translate('customers.fields.vat')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="personSotu"
                    label={translate('customers.fields.sotu')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.paymentDetails')}</label>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="netbankaddress"
                    label={translate('customers.fields.netbankaddress')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="operator"
                    label={translate('customers.fields.operator')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.method')}</label>
                    <div>
                      <Field
                        placeholder={translate('customers.fields.method')}
                        name="sendBillMethod"
                        component="select"
                        className="form-control input-lg"
                      >
                        <option value="PAPERBILL">
                          {translate('customers.fields.sendBillMethod.PAPERBILL')}
                        </option>
                        <option value="EMAIL">
                          {translate('customers.fields.sendBillMethod.EMAIL')}
                        </option>
                        <option value="EBILL">
                          {translate('customers.fields.sendBillMethod.EBILL')}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="termofpayment"
                    type="number"
                    label={translate('customers.fields.termofpayment')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="referenceNumber"
                    label={translate('customers.fields.referenceNumber')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="organization"
                    label={translate('customers.fields.organization')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.contact')}</label>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="address"
                    label={translate('customers.fields.address')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="postalcode"
                    label={translate('customers.fields.postalcode')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="city"
                    label={translate('customers.fields.city')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <div className="form-group">
                    <label>{translate('customers.fields.addresscountry')}</label>
                    <div>
                      <Field
                        name="addresscountry"
                        component="select"
                        className="form-control input-lg"
                      >
                        {counties}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="phonenumber"
                    label={translate('customers.fields.phonenumber')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="email"
                    label={translate('customers.fields.email')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12">
                  <Field
                    name="email2"
                    label={translate('customers.fields.email2')}
                    translate={translate}
                    component={InputComponent}
                  />
                </div>
              </div>
              <div className="col-xs-12" style={{ marginTop: '25px' }}>
                <div className="col-sm-offset-6 col-sm-6 col-xs-12">
                  <Button variant="success" className="btn btn-accept pull-right " type="submit">
                    {translate('customers.fields.buttonCreate')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )

    const searchCustomer = !hideSearch
      ? this.getSearchCustomerForm(translate, addCustomerForm)
      : addCustomerForm

    return <div>{searchCustomer}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    translate: getTranslate(state.locale),
    searchResult: state.customers.result,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createCustomer: data => dispatch({ type: 'CREATE_CUSTOMER', data }),
    findCustomers: data => dispatch({ type: 'FIND_CUSTOMERS', data }),
    clearCustomers: () => dispatch({ type: 'CLEAR_CUSTOMERS' }),
    sendErrorMessage: message =>
      dispatch({
        type: 'SEND_NOTIFICATION',
        response: { message: `LOCALIZED!${message}`, type: 'error' },
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm)
