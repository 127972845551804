import {apiCall} from '../../../services/api'

export function fetchTemplates(token, id) {
  return apiCall(`/api/person/${id}/templates`, 'get', token)
}

export function update(token, id, data) {
  return apiCall(`/api/person/${id}/templates/${data.data.id}`, 'put', token, data.data)
}

export function disable(token, id, data) {
  return apiCall(`/api/person/${id}/templates/${data.data.id}`, 'delete', token)
}
