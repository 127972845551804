import {
  calculateFromBill,
  changeBill,
  changeBillableItem,
  copyBill,
  createAdvancePayment,
  fetchArchive,
  fetchBills,
  fetchReceipt,
  getBillableItem,
  newBill,
  newBillableItem,
  newReceipt,
  openBill,
  removeBill,
  removeReceipt,
} from './billsApi'
import { takeEvery, throttle } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import moment from 'moment'
import { path } from 'ramda'
import { browserHistory } from 'react-router'

export function getBillsFromApi() {
  const user = getObject('user')
  return fetchBills(path(['authtoken'], user), path(['id'], user))
    .then((bills) => {
      return { response: bills }
    })
    .catch((err) => {
      return err
    })
}

export function getArchiveFromApi(data) {
  const user = getObject('user')
  return fetchArchive(path(['authtoken'], user), path(['id'], user), data.data.page)
    .then((bills) => {
      return { response: bills }
    })
    .catch((err) => {
      return err
    })
}

export function createBillWithApi(data) {
  const user = getObject('user')
  return newBill(path(['authtoken'], user), path(['id'], user), data)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function updateBillWithApi(data) {
  const user = getObject('user')
  if (data.data.state === 'PENDING') {
    return checkAndSend(data)
  }
  return changeBill(path(['authtoken'], user), path(['id'], user), data.data)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function checkAndSend(data) {
  const user = getObject('user')
  let error = ''
  const day = moment(data.data.billingdate, 'DD.MM.YYYY', true)
  if (!day.isValid() || day.isBefore(moment().startOf('day'))) {
    error = 'LOCALIZED!INVALID_DATE'
  }
  if (error) {
    data.data.error = error
    data.data.state = 'OPEN'
    return { response: data.data, error }
  }
  return calculateFromBill(path(['authtoken'], user), data.data)
    .then((calculated) => {
      return changeBill(path(['authtoken'], user), path(['id'], user), data.data)
        .then((bill) => {
          return { response: bill }
        })
        .catch((err) => {
          return err
        })
    })
    .catch((err) => {
      if (err.response.data && err.response.data.error) {
        data.data.error = err.response.data.error
        data.data.state = 'OPEN'
        return { response: data.data, error }
      }
    })
}

export function deleteBillWithApi(data) {
  const user = getObject('user')
  return removeBill(path(['authtoken'], user), path(['id'], user), data.data)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function openBillWithApi(data) {
  const user = getObject('user')
  return openBill(path(['authtoken'], user), data.data)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function createBillableItemWithApi(data) {
  const user = getObject('user')
  return newBillableItem(path(['authtoken'], user), path(['id'], user), data.data)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function getBillableItemWithApi(data) {
  const user = getObject('user')
  return getBillableItem(path(['authtoken'], user), data.id)
    .then((billableItem) => {
      return { response: billableItem }
    })
    .catch((err) => {
      return err
    })
}

export function updateBillableItemWithApi(data) {
  const user = getObject('user')
  return changeBillableItem(path(['authtoken'], user), path(['id'], user), data.data, data.callback)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function createReceiptWithApi(data) {
  const user = getObject('user')
  return newReceipt(path(['authtoken'], user), data.data, data.progressCallback)
    .then((receipt) => {
      return { response: receipt }
    })
    .catch((err) => {
      return err
    })
}

export function deleteReceiptWithApi(data) {
  const user = getObject('user')
  return removeReceipt(path(['authtoken'], user), data.data.id)
    .then((receipt) => {
      return { response: receipt }
    })
    .catch((err) => {
      return err
    })
}

function copyBillWithApi(bill) {
  const user = getObject('user')
  return copyBill(path(['authtoken'], user), user.id, bill.id)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      return err
    })
}

export function getReceiptWithApi(data) {
  const user = getObject('user')
  return fetchReceipt(path(['authtoken'], user), data.data)
    .then((receipt) => {
      return { response: receipt }
    })
    .catch((err) => {
      return { error: err }
    })
}

function createAdvancePaymentWithApi(data) {
  const user = getObject('user')
  const {
    data: { bill, advancePaymentAmount },
  } = data
  return createAdvancePayment(user.authtoken, user.id, bill.id, advancePaymentAmount)
    .then((bill) => {
      return { response: bill }
    })
    .catch((err) => {
      console.log('err', err.error)
      return { error: err?.error?.response?.data?.error || 'CANNOT_CREATE_ADVANCE_PAYMENT' }
    })
}

export function* tryGetBills() {
  const { response, error } = yield call(getBillsFromApi)
  if (response) {
    yield put({ type: 'GET_BILLS_SUCCESS', response })
  } else {
    yield put({ type: 'GET_BILLS_FAILURE', error })
  }
}

export function* tryGetArchive(data) {
  const { response, error } = yield call(getArchiveFromApi, data)
  if (response) {
    yield put({ type: 'GET_ARCHIVE_SUCCESS', response })
  } else {
    yield put({ type: 'GET_ARCHIVE_FAILURE', error })
  }
}

export function* tryCreateBill(data) {
  const { response, error } = yield call(createBillWithApi, data.data)
  if (response) {
    const path = '/invoice?id=' + response.id
    browserHistory.push(path)
  } else {
    yield put({ type: 'CREATE_BILL_FAILURE', error })
  }
}

export function* tryUpdateDueDate(data) {
  const { response, error } = yield call(updateBillWithApi, data)
  if (response) {
    yield put({ type: 'UPDATE_DUE_DATE_SUCCESS', response })
    if (response.error) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: response.error, duration: 4000, type: 'error' },
      })
    }
  } else {
    yield put({ type: 'UPDATE_DUE_DATE_FAILURE', error })
  }
}

export function* tryUpdateBill(data) {
  const { response, error } = yield call(updateBillWithApi, data)
  if (response) {
    yield put({ type: 'UPDATE_BILL_SUCCESS', response })
    yield put({ type: 'CALCULATE_FROM_BILL', data: data.data })
    if (response.error) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: response.error, duration: 4000, type: 'error' },
      })
    }
    if (data.data.state === 'PENDING') {
      yield put({ type: 'GET_INFO', months: 24 })
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!APROVE_REQUEST_SUCCESS', duration: 3000, type: 'success' },
      })
    }
  } else {
    const errors = path(['response', 'data', 'message'], error)
    if (errors) {
      const allErrors = JSON.parse(errors) || []
      for (let i = 0; i < allErrors.length; i++) {
        const reason = allErrors[i]
        yield put({
          type: 'SEND_NOTIFICATION',
          response: { message: `LOCALIZED!${reason}`, duration: 10000, type: 'error' },
        })
      }
    }
    yield put({ type: 'UPDATE_BILL_FAILURE', error })
  }
}

export function* trySendToApprove(data) {
  const { response, error } = yield call(updateBillWithApi, data)
  if (response) {
    yield put({ type: 'UPDATE_BILL_SUCCESS', response })
    yield put({ type: 'GET_BILLS', response })
  } else {
    const errors = path(['response', 'data', 'message'], error)
    if (errors) {
      const allErrors = JSON.parse(errors) || []
      for (let i = 0; i < allErrors.length; i++) {
        const reason = allErrors[i]
        yield put({
          type: 'SEND_NOTIFICATION',
          response: { message: `LOCALIZED!${reason}`, duration: 10000, type: 'error' },
        })
      }
    }
    yield put({ type: 'UPDATE_BILL_FAILURE', error })
  }
}

export function* tryDeleteBill(data) {
  const { response, error } = yield call(deleteBillWithApi, data)
  if (response || response === null) {
    yield put({ type: 'DELETE_BILL_SUCCESS', response })
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!DELETE_BILL_SUCCESS', duration: 3000, type: 'success' },
    })
    yield put({ type: 'GET_INFO', months: 24 })
    yield call(tryGetBills)
    const location = browserHistory.getCurrentLocation()
    if (location.pathname === '/invoice') {
      browserHistory.goBack()
    }
  } else {
    yield put({ type: 'DELETE_BILL_FAILURE', error })
  }
}

export function* tryOpenBill(data) {
  const { response, error } = yield call(openBillWithApi, data)
  if (response || response === null) {
    yield put({ type: 'UPDATE_BILL_SUCCESS', response })
    yield put({ type: 'GET_BILLS', response })
    yield put({ type: 'OPEN_BILL_SUCCESS', response })
    yield put({ type: 'GET_INFO', months: 24 })
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!OPEN_BILL_SUCCESS', duration: 3000, type: 'success' },
    })
  } else {
    yield put({ type: 'OPEN_BILL_FAILURE', error })
    const data = error.response?.data?.message || 'ERROR'
    console.log('error', error.message, error.response)
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: `LOCALIZED!${data}`, duration: 10000, type: 'error' },
    })
  }
}

export function* tryCreateBillableItem(data) {
  yield put({ type: 'SAVE_BILLABLE_ITEM_START' })
  const { response, error } = yield call(createBillableItemWithApi, data)
  if (response) {
    yield put({ type: 'CALCULATE_FROM_BILL', data: response })
    yield put({ type: 'CREATE_BILLABLE_ITEM_SUCCESS', response })
    if (data.callback) {
      data.callback(response)
    }
  } else {
    yield put({ type: 'CREATE_BILLABLE_ITEM_FAILURE', error })
  }
  yield put({ type: 'SAVE_BILLABLE_ITEM_FINISHED' })
}

export function* tryGetSingleBillableItem(data) {
  const { response, error } = yield call(getBillableItemWithApi, data)
  if (response) {
    yield put({ type: 'GET_BILLABLE_ITEM_SUCCESS', response })
  } else {
    yield put({ type: 'GET_BILLABLE_ITEM_FAILURE', error })
  }
}

export function* tryUpdateBillableItem(data) {
  const { response, error } = yield call(updateBillableItemWithApi, data)
  if (response) {
    const billData = { id: data.data.billId }
    if (!data.callback) {
      yield put({ type: 'CALCULATE_FROM_BILL', data: billData })
    }
    yield put({ type: 'UPDATE_BILLABLE_ITEM_SUCCESS', response })
  } else {
    yield put({ type: 'UPDATE_BILLABLE_ITEM_FAILURE', error })
  }
}

export function* tryCreateReceipt(data) {
  const billableItemId = data.data.id
  const { response, error } = yield call(createReceiptWithApi, data)
  if (response) {
    if (response.status && response.status === 413) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: { message: 'LOCALIZED!RECEIPT_TOO_LARGE', duration: 4000, type: 'error' },
      })
      yield put({ type: 'CREATE_RECEIPT_FAILURE', error })
    } else {
      yield put({ type: 'GET_BILLABLE_ITEM', id: billableItemId })
      yield put({ type: 'CREATE_RECEIPT_SUCCESS', response, billableItemId: billableItemId })
    }
  } else {
    yield put({ type: 'CREATE_RECEIPT_FAILURE', error })
  }
}

export function* tryGetReceipt(data) {
  const { response, error } = yield call(getReceiptWithApi, data)
  if (response) {
    yield put({ type: 'UPDATE_RECEIPT_SUCCESS', response })
  } else {
    yield put({ type: 'UPDATE_RECEIPT_FAILURE', error })
  }
}

export function* tryDeleteReceipt(data) {
  const billableItemId = data.billableItemId
  const { response } = yield call(deleteReceiptWithApi, data)
  if (response) {
    yield put({
      type: 'DELETE_RECEIPT_SUCCESS',
      response: data.data,
      billableItemId: billableItemId,
    })
  } else {
    yield put({ type: 'DELETE_RECEIPT_FAILURE', response: data.data })
  }
}

export function* tryDuplicateBill(event) {
  const { response } = yield call(copyBillWithApi, event.id)
  if (response) {
    const path = `/invoice?id=${response.id}`
    browserHistory.push(path)
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!CANNOT_COPY_BILL', duration: 3000, type: 'warning' },
    })
  }
}

export function* tryCreateAdvancePayment(data) {
  const { response, error } = yield call(createAdvancePaymentWithApi, data)
  if (response) {
    yield put({
      type: 'CREATE_ADVANCE_PAYMENT_SUCCESS',
      response: data.data,
    })
    yield put({
      type: 'SELECT_BILL',
      billId: data.data?.bill?.id,
    })
    yield put({
      type: 'SEND_NOTIFICATION',
      response: {
        message: 'LOCALIZED!CREATE_ADVANCE_PAYMENT_SUCCESS',
        duration: 3000,
        type: 'info',
      },
    })
    data.data.callback()
  } else {
    console.log('error', error)
    yield put({
      type: 'SEND_NOTIFICATION',
      response: {
        message: `LOCALIZED!${error}`,
        duration: 3000,
        type: 'warning',
      },
    })
  }
}

export function* getBills() {
  yield takeEvery('GET_BILLS', tryGetBills)
}

export function* createBill() {
  yield throttle(500, 'CREATE_BILL', tryCreateBill)
}

export function* updateBill() {
  yield takeEvery('UPDATE_BILL', tryUpdateBill)
}

export function* deleteBill() {
  yield takeEvery('DELETE_BILL', tryDeleteBill)
}

export function* sendOpenRequest() {
  yield takeEvery('OPEN_BILL', tryOpenBill)
}

export function* createBillableItem() {
  yield takeEvery('CREATE_BILLABLE_ITEM', tryCreateBillableItem)
}

export function* getSingleBillableItem() {
  yield takeEvery('GET_BILLABLE_ITEM', tryGetSingleBillableItem)
}

export function* updateBillableItem() {
  yield takeEvery('UPDATE_BILLABLE_ITEM', tryUpdateBillableItem)
}

export function* getArchive() {
  yield takeEvery('GET_ARCHIVE', tryGetArchive)
}

export function* createReceipt() {
  yield throttle(500, 'CREATE_RECEIPT', tryCreateReceipt)
}

export function* deleteReceipt() {
  yield takeEvery('DELETE_RECEIPT', tryDeleteReceipt)
}

export function* getReceipt() {
  yield takeEvery('GET_RECEIPT', tryGetReceipt)
}

export function* updateDueDate() {
  yield takeEvery('UPDATE_DUE_DATE', tryUpdateDueDate)
}

export function* duplicateBill() {
  yield takeEvery('DUPLICATE_BILL', tryDuplicateBill)
}

export function* sendToApprove() {
  yield takeEvery('SEND_TO_APPROVE', trySendToApprove)
}

export function* createAdvancePaymentSaga() {
  yield takeEvery('CREATE_ADVANCE_PAYMENT', tryCreateAdvancePayment)
}
