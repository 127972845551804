import React, { Component } from 'react'
import RowForm from './RowForm'
import TimeForm from './TimeForm'
import ReimbursementForm from './ReimbursementForm'
import ReimbursementOfExpencesForm from './ReimbursementOfExpencesForm'
import moment from 'moment'
import { difference, equals, forEachObjIndexed, path } from 'ramda'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import './editor.scss'
import { deleteModal } from '../Modal/DeleteModal'
import { isoTimeToTime, timeToIsoTime } from '../Utils/dateHelpers' // --> OFF
/* eslint  no-mixed-operators: 0 */
class BillRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      removedReceipts: [],
      saved: false,
      data: this.props.data || {},
      fileName: null,
      progress: null,
      reimbursementOfExpencesModalOpen: false,
      reimbursementModalOpen: false,
      deleteModalOpen: false,
      billableItem: {},
    }
    this.toggleAll = this.toggleAll.bind(this)
  }

  shouldComponentUpdate(nextprops, nextstate) {
    const should =
      path(['billableItem', 'updated'], nextprops) !== path(['billableItem', 'updated'], this.state) ||
      this.state.collapsed !== nextstate.collapsed ||
      this.state.timeModalOpen !== nextstate.timeModalOpen ||
      this.state.reimbursementOfExpencesModalOpen !== nextstate.reimbursementOfExpencesModalOpen ||
      this.state.reimbursementModalOpen !== nextstate.reimbursementModalOpen ||
      this.state.deleteModalOpen !== nextstate.deleteModalOpen ||
      this.props.locale !== nextprops.locale
    return should
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let state = {}
    if (nextProps.collapseAll) {
      if ((this.state.collapseAll && this.state.collapseAll.time !== nextProps.collapseAll.time) || !this.state.collapseAll) {
        state = {
          collapseAll: nextProps.collapseAll,
          collapsed: nextProps.collapseAll.collapsed,
        }
      }
    }
    this.setState({
      billableItem: nextProps.billableItem,
      ...state,
    })
  }

  componentDidMount() {
    this.setState({ ...this.state, collapsed: this.props.billableItem.collapsed })
  }

  toggleState = () => {
    this.setState({ ...this.state, collapsed: !this.props.billableItem.collapsed })
    this.props.updateBillableItem({
      ...this.props.billableItem,
      collapsed: !this.props.billableItem.collapsed,
    })
  }

  toggleModal = (modal, opened, e) => {
    this.setState({ ...this.state, [modal]: opened })
  }

  handleSubmitRow = (data) => {
    data.alvPrice = +data.alvPrice
    data.price = Math.round(+(data.alvPrice + (data.alv * data.alvPrice) / 100) * 10000) / 10000
    if (!data.billableWorkHours) {
      delete data.billableWorkHours
    }

    const removedReceipts = difference(this.props.billableItem.receipts, data.receipts)
    removedReceipts.forEach((receipt) => {
      this.props.deleteReceipt(receipt, this.props.billableItem.id)
    })

    const item = {
      ...data,
      time: isoTimeToTime(data.time),
      returntime: isoTimeToTime(data.returntime),
    }
    let callback = null
    if (this.state.billableItem.alv !== data.alv) {
      callback = () => this.props.onUpdate(item)
    }
    this.props.updateBillableItem(item, callback)
  }

  handleSubmitTime = (data) => {
    this.toggleModal('timeModalOpen', false)
    this.handleSubmitRow({
      ...data,
      workShiftStartedTime: isoTimeToTime(data.workShiftStartedTime),
      workShiftEndedTime: isoTimeToTime(data.workShiftEndedTime),
    })
  }

  handleSubmitReimbursement = (data) => {
    this.toggleModal('reimbursementModalOpen', false)
    this.handleSubmitRow(data)
  }

  clearTravelReimbursement = () => {
    const data = {
      ...this.props.billableItem,
      ...this.reimbursementDefaultData,
    }
    delete data.addressStart
    delete data.wayPoint
    delete data.addressStop

    delete data.vehicleRegisterNumber
    delete data.roundtrip
    delete data.kilometers
    delete data.stayOverNight
    delete data.startdate
    delete data.time
    delete data.returndate
    delete data.returntime
    data.fulldaycompensation = 0
    data.partialdaycompensation = 0
    data.foreignAllowance = 0

    delete data.lunchcompensation
    this.handleSubmitRow(data)
  }

  clearReimbursement = () => {
    const data = {
      ...this.props.billableItem,
      ...this.reimbursementDefaultData,
    }
    delete data.hotelcost
    delete data.hotelcost_alv10
    delete data.hotelcost_alv14
    delete data.hotelcost_alv24

    delete data.travelcost
    delete data.travelcost_alv10

    delete data.travelcost
    delete data.othercost
    delete data.othercost_alv24

    this.handleSubmitRow(data)
  }

  handleSubmitReimbursementsOfExpences = (data) => {
    this.toggleModal('reimbursementOfExpencesModalOpen', false)
    this.handleSubmitRow(data)
  }

  handleDelete = () => {
    this.closeDeleteModal()
    this.props.onDelete(this.props.data)
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpen: null,
    })
  }

  openDeleteModal = () => {
    this.setState({
      deleteModalOpen: true,
    })
  }
  toggleAll = () => {
    const { collapseFn } = this.props
    const collaps = !this.props.billableItem.collapsed
    collapseFn({ collapsed: collaps, time: new Date().getTime() })
    this.toggleState()
  }

  reimbursementDefaultData = {
    kilometers: 0,
    vehicle: '0',
    stayOverNight: false,
    fulldaycompensation: 0,
    partialdaycompensation: 0,
    lunchcompensation: 0,
    showInResult: true,
    receipts: [],
    vehicleRegisterNumber: undefined,
    route: undefined,
    startdate: undefined,
    time: undefined,
    returndate: undefined,
    returntime: undefined,
    foreignAllowance: undefined,
    hotelcost: undefined,
    travelcost: undefined,
    othercost: undefined,
  }

  clearTime = () => {
    const data = this.props.billableItem
    delete data.workShiftStarted
    delete data.workShiftStartedTime
    delete data.workShiftEnded
    delete data.workShiftEndedTime
    this.handleSubmitRow(data)
  }

  roundSum = (sum) => {
    return `${(Math.round(sum * 100) / 100).toFixed(2)} €`
  }

  render() {
    const { translate, disabled, billableItem, locale, selectedBill, upload, user, bill, billReimbursementsFromCustomer } = this.props
    const { collapsed } = this.state
    const title = []
    billableItem.date && title.push(billableItem.date)
    billableItem.destination && title.push(billableItem.destination)
    !isNaN(billableItem.alvPrice) && title.push(this.roundSum(billableItem.alvPrice))
    billableItem.totalPrice && title.push(this.roundSum(billableItem.totalPrice.toFixed(2)))
    billableItem.vehicleRegisterNumber && title.push(billableItem.vehicleRegisterNumber)

    const messageForDeleteRow = translate('invoice.messageForDeleteBillableItem')
    const { deleteModalOpen } = this.state
    const DeleteModal = deleteModal(
      deleteModalOpen,
      messageForDeleteRow,
      translate,
      () => {
        this.toggleModal('deleteModalOpen', false)
      },
      this.handleDelete
    )
    const dirtyReimbursementForm = (() => {
      let dirty = false
      forEachObjIndexed((value, key) => {
        if (key === 'startdate') {
          if (billableItem.startdate !== value && billableItem.startdate !== billableItem.date) {
            dirty = true
          }
        } else if (typeof value === 'object') {
          if (!equals(selectedBill[key], value)) {
            dirty = true
          }
        } else if (selectedBill[key] !== value) {
          dirty = true
        }
      }, this.reimbursementDefaultData)
      return dirty
    })()
    const dirtyTimeForm = (() => {
      return billableItem.workShiftStarted || billableItem.workShiftStartedTime || billableItem.workShiftEnded || billableItem.workShiftEndedTime
    })()
    ReactTooltip.rebuild()
    return (
      <div className="row" id={`billable-item-${billableItem.id}`}>
        <div className={collapsed ? 'invoice-row collapsed' : 'invoice-row'}>
          <div className="title noselect" onClick={this.toggleState.bind(this)} onDoubleClick={this.toggleAll}>
            <div>
              <span>{title.join(', ')}</span>
            </div>
            <div style={{ width: '40px' }}>
              <i className="chevron" />
            </div>
          </div>
          <div className="invoice-row-body col-xs-12">
            <RowForm
              disabled={disabled}
              bill={bill}
              locale={locale}
              billableItem={billableItem}
              selectedBill={selectedBill}
              billableWorkHours={billableItem.billableWorkHours || 1}
              billReimbursementsFromCustomer={billReimbursementsFromCustomer}
              initialValues={{
                startdate: billableItem.startdate ? billableItem.startdate : moment().format('DD.MM.YYYY'),
                returndate: billableItem.returndate ? billableItem.returndate : moment().add(1, 'days').format('DD.MM.YYYY'),
                time: billableItem.time ? billableItem.time : '09:00',
                destination: billableItem.destination,
                type: 'AMOUNT',
                returntime: billableItem.returntime ? billableItem.returntime : '09:00',
                ...billableItem,
                hourlyprice:
                  billableItem.type === 'AMOUNT'
                    ? +(billableItem.alvPrice / (billableItem.billableWorkHours || 1)).toFixed(3)
                    : +billableItem.alvPrice,
                billableWorkHours: billableItem.type === 'AMOUNT' ? billableItem.billableWorkHours || 1 : billableItem.billableWorkHours || 1,
              }}
              enableReinitialize="true"
              form={'rowForm_' + billableItem.id}
              submit={this.handleSubmitRow}
              translate={translate}
            />
            <div className="col-xs-12 additional">
              <div className="form-group">
                <span className="blue">{translate('invoice.row.time')}</span>
                {dirtyTimeForm ? (
                  disabled ? (
                    <button
                      onClick={() => {
                        this.toggleModal('timeModalOpen', true)
                      }}
                      className="btn bill-row-button pull-right btn-primary"
                    >
                      Näytä
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          this.toggleModal('timeModalOpen', true)
                        }}
                        className="btn bill-row-button pull-right btn-primary"
                      >
                        Muokkaa
                      </button>

                      <button onClick={this.clearTime} className="margin-right-20 btn bill-row-button pull-right btn-warning">
                        Tyhjennä
                      </button>
                    </>
                  )
                ) : (
                  <button
                    onClick={() => {
                      this.toggleModal('timeModalOpen', true)
                    }}
                    className="btn bill-row-button pull-right btn-primary"
                  >
                    Muokkaa
                  </button>
                )}
                {dirtyTimeForm ? (
                  <div className="row dkblue" style={{ maxWidth: '500px', marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-xs-3">
                      <div style={{ fontWeight: 600 }}>{billableItem.workShiftStarted}</div>
                      <div style={{ fontSize: '14px' }}>{translate('invoice.row.startD')}</div>
                    </div>
                    <div className="col-xs-2">
                      <div style={{ fontWeight: 600 }}>{billableItem.workShiftStartedTime}</div>
                      <div style={{ fontSize: '14px' }}>{translate('invoice.row.startT')}</div>
                    </div>
                    <div className="col-xs-3">
                      <div style={{ fontWeight: 600 }}>{billableItem.workShiftEnded}</div>
                      <div style={{ fontSize: '14px' }}>{translate('invoice.row.endD')}</div>
                    </div>
                    <div className="col-xs-2">
                      <div style={{ fontWeight: 600 }}>{billableItem.workShiftEndedTime}</div>
                      <div style={{ fontSize: '14px' }}>{translate('invoice.row.endT')}</div>
                    </div>
                    <div className="col-xs-2">
                      <div style={{ fontWeight: 600 }}>{billableItem.workhours}</div>
                      <div style={{ fontSize: '14px' }}>{translate('invoice.row.workhours')}</div>
                    </div>
                  </div>
                ) : null}
              </div>
              {this.state.timeModalOpen && (
                <TimeForm
                  disabled={disabled}
                  onRequestHide={(e) => {
                    this.toggleModal('timeModalOpen', false, e)
                  }}
                  handleSubmit={this.handleSubmitTime}
                  show={this.state.timeModalOpen}
                  onHide={(e) => {
                    this.toggleModal('timeModalOpen', false, e)
                  }}
                  form={'timeForm'}
                  locale={locale}
                  translate={translate}
                  initialValues={{
                    ...billableItem,
                    workShiftStarted: billableItem.workShiftStarted ? billableItem.workShiftStarted : billableItem.date,
                    workShiftEnded: billableItem.workShiftEnded ? billableItem.workShiftEnded : billableItem.date,
                    workShiftStartedTime: timeToIsoTime(billableItem.workShiftStartedTime) || '09:00',
                    workShiftEndedTime: timeToIsoTime(billableItem.workShiftEndedTime) || '09:00',
                  }}
                />
              )}
            </div>
            <div className="col-xs-12 additional">
              <div className="form-group">
                <span
                  onClick={(event) => {
                    this.toggleModal('reimbursementModalOpen', true, event)
                  }}
                  className="blue"
                >
                  {translate('invoice.row.travelReimbursement')}
                </span>

                {dirtyReimbursementForm ? (
                  disabled ? (
                    <button
                      onClick={() => {
                        this.toggleModal('reimbursementModalOpen', true)
                      }}
                      className="btn bill-row-button pull-right btn-primary"
                    >
                      Näytä
                    </button>
                  ) : (
                    <>
                      <>
                        <button
                          onClick={() => {
                            this.toggleModal('reimbursementModalOpen', true)
                          }}
                          className="btn bill-row-button pull-right btn-primary"
                        >
                          Muokkaa
                        </button>
                        <button onClick={this.clearTravelReimbursement} className="margin-right-20 btn bill-row-button pull-right btn-warning">
                          Tyhjennä
                        </button>
                      </>
                    </>
                  )
                ) : (
                  <div className="pull-right" data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        this.toggleModal('reimbursementModalOpen', true)
                      }}
                      style={{ fontSize: '24px' }}
                      className="btn btn-square pull-right dkblue"
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
              <ReimbursementForm
                show={this.state.reimbursementModalOpen}
                onHide={(event) => {
                  this.toggleModal('reimbursementModalOpen', false, event)
                }}
                billReimbursementsFromCustomer={billReimbursementsFromCustomer}
                initialValues={{
                  vehicleRegisterNumber: '',
                  stayOverNight: 0,
                  route: '',
                  kilometers: '',
                  fulldaycompensation: 0,
                  partialdaycompensation: 0,
                  showInResult: false,
                  lunchcompensation: '',
                  foreignAllowance: '',
                  startdate: moment().format('DD.MM.YYYY'),
                  returndate: moment().format('DD.MM.YYYY'),
                  wayPoint: [],
                  ...this.state.billableItem,
                  time: timeToIsoTime(this.state.billableItem.time) || '09:00',
                  returntime: timeToIsoTime(this.state.billableItem.returntime) || '09:00',
                }}
                handleSubmit={this.handleSubmitReimbursement}
                billableItem={this.state.billableItem}
                form={'reimbursementForm'}
                disabled={disabled}
                translate={translate}
                locale={locale}
              />
            </div>

            <div className="col-xs-12 additional">
              <div className="form-group">
                <span className="blue">{translate('invoice.row.reimbursementsOfExpences')}</span>

                {dirtyReimbursementForm ? (
                  disabled ? (
                    <>
                      <button
                        onClick={() => {
                          this.toggleModal('reimbursementOfExpencesModalOpen', true)
                        }}
                        className="btn bill-row-button pull-right btn-primary"
                      >
                        Näytä
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          this.toggleModal('reimbursementOfExpencesModalOpen', true)
                        }}
                        className="btn bill-row-button pull-right btn-primary"
                      >
                        Muokkaa
                      </button>
                      <button onClick={this.clearReimbursement} className="margin-right-20 btn bill-row-button pull-right btn-warning">
                        Tyhjennä
                      </button>
                    </>
                  )
                ) : (
                  <div className="pull-right" data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        this.toggleModal('reimbursementOfExpencesModalOpen', true)
                      }}
                      style={{ fontSize: '24px' }}
                      className="btn btn-square pull-right dkblue"
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
              <ReimbursementOfExpencesForm
                onRequestHide={() => {
                  this.toggleModal('reimbursementOfExpencesModalOpen', false)
                }}
                disabled={disabled}
                handleSubmit={this.handleSubmitReimbursementsOfExpences}
                billableItem={this.state.billableItem}
                initialValues={{
                  ...this.state.billableItem,
                }}
                translate={translate}
                show={this.state.reimbursementOfExpencesModalOpen}
                upload={upload}
                onHide={() => {
                  this.toggleModal('reimbursementOfExpencesModalOpen', false)
                }}
                user={user}
              />
            </div>

            <div className="col-xs-12">
              <div className="pull-right" data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                <button
                  type="button"
                  disabled={disabled}
                  className="delete btn btn-square pull-right dkblue"
                  onClick={() => {
                    this.toggleModal('deleteModalOpen', true)
                  }}
                >
                  {translate('invoice.row.deleteR')}
                  <i className="glyphicon icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {DeleteModal}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    lastUpdatedBillableItem: state.bills.lastUpdatedBillableItem,
    selectedBill: state.bill.selectedBill,
    upload: state.bill.upload,
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateBillableItem: (data, callback) => dispatch({ type: 'UPDATE_BILLABLE_ITEM', data, callback }),
    deleteReceipt: (data, billableItemId) => dispatch({ type: 'DELETE_RECEIPT', data, billableItemId }),
    uploadProgress: (data) => dispatch({ type: 'CURRENT_UPLOAD', data }),
    uploadReceipt: (data, progressCallback) => dispatch({ type: 'CREATE_RECEIPT', data, progressCallback }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillRow)
