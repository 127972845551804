import { RESET_PASSWORD, START_RESET_PASSWORD } from './passwordActions'

import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { resetPasswordApi, startResetPasswordApi } from './passwordApi'
import { browserHistory } from 'react-router'
import { setObject } from '../../components/Localstorage/localStorageService'

export function startResetPasswordWithApi(data) {
  return startResetPasswordApi({ email: data.email })
    .then((person) => {
      return { response: person }
    })
    .catch((err) => {
      return err
    })
}

export function resetPasswordWithApi(data) {
  return resetPasswordApi(data)
    .then((person) => {
      return { response: person }
    })
    .catch((err) => {
      return err
    })
}

export function* startResetPasswordAction(data) {
  const { response, error } = yield call(startResetPasswordWithApi, data)
  if (response) {
    yield put({ type: 'START_RESET_PASSWORD_SUCCESS', response, data: data.data })
    console.log('response', response)
    const url = `${response.endpointUrl}?request=${response.request}`
    window.location.href = url
  } else {
    yield put({ type: 'START_RESET_PASSWORD_FAILURE', error })
    browserHistory.push('/password/checkemail-failure')
  }
}

export function* resetPasswordAction(data) {
  const { response, error } = yield call(resetPasswordWithApi, data.data)
  if (response) {
    setObject('user', response)
    console.log('response', response)
    yield put({ type: 'CHECK_USER' })
    browserHistory.push('/account')
  } else {
    yield put({ type: 'RESET_PASSWORD_FAILURE', error })
    browserHistory.push('/password/changed-failure')
  }
}

export function* getUserForPasswordResetAction(data) {
  const { response, error } = yield call(startResetPasswordWithApi, data)
  if (response) {
    yield put({ type: 'RESET_PASSWORD_SUCCESS', response, data: data.data })
    browserHistory.push('/password/checkemail')
  } else {
    yield put({ type: 'RESET_PASSWORD_FAILURE', error })
    browserHistory.push('/password/checkemail-failure')
  }
}

export function* startResetPassword() {
  yield takeEvery(START_RESET_PASSWORD, startResetPasswordAction)
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordAction)
}
