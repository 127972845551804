import { isMobile } from './detectMobile'
import { dateToIsoDate } from './dateHelpers'
import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'
import moment from 'moment'

export const customDatePicker = (date, placeholder, handler, locale, disabled, onBlur, inputStyles) => {
  if (isMobile()) {
    const styles = inputStyles || { display: 'block', width: '100%', minWidth: '96%', lineHeight: '50px' }
    return (
      <input
        disabled={disabled}
        style={styles}
        className="form-control mobile-date"
        onChange={(event) => handler(moment(event.target.value))}
        placeholder={placeholder}
        type="date"
        value={dateToIsoDate(date)}
      />
    )
  } else {
    const dateClass = date ? 'form-control' : 'form-control no-value'
    return (
      <DayPickerInput
        dayPickerProps={{ localeUtils: LocaleUtils, locale }}
        disabled={disabled}
        style={{ display: 'block' }}
        value={date}
        onDayChange={handler}
        onBlur={onBlur}
        className={dateClass}
        placeholder={placeholder}
        format="DD.MM.YYYY"
      />
    )
  }
}
