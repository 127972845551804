import {fork} from 'redux-saga/effects'

import {checkUser, createSharedCustomer, failures, getUserWithToken, logout, tryLogin} from '../routes/Login/loginSagas'
import {
  deleteSharedCustomers,
  getPersonInfo,
  getTaxInfoSaga,
  serchCustomers,
  updateSharedCustomers
} from '../components/api/Info/infoSagas'
import {getSalaries} from '../components/api/Salaries/salariesSagas'
import {updatePerson} from '../components/api/Person/personSagas'
import {disableTemplate, getTemplates, updateTemplate} from '../components/api/Templates/templatesSagas'
import {
  createAdvancePaymentSaga,
  createBill,
  createBillableItem,
  createReceipt,
  deleteBill,
  deleteReceipt,
  duplicateBill,
  getArchive,
  getBills,
  getReceipt,
  getSingleBillableItem,
  sendOpenRequest,
  sendToApprove,
  updateBill,
  updateBillableItem,
  updateDueDate,
} from '../components/api/Bills/billsSagas'
import {getProfilePic, uploadProfilePic, uploadTaxCardPic} from '../components/api/ProfileUploads/profileUploadsSagas'
import {
  calculateSalaryFromPartialBill,
  calculateSallary,
  calculateSallaryFromBill
} from '../components/api/Calculator/calculatorSagas'
import {deleteBillableItem, selectAndFetchBill, validateBill} from '../components/api/Bill/billSagas'
import {findCustomers} from '../components/api/Customers/customersSagas'
import {agreeEula, bankConfirmed, getUserByToken, startRegistration} from '../routes/Register/registerSagas'
import {resetPassword, startResetPassword} from '../routes/Password/passwordSagas'
import {
  approveSubcontracorItemSaga,
  createExternalItemSaga,
  createSubcontractorItemSaga,
  declineSubcontracorItemSaga,
  deleteExtItemSaga,
  deletePlainReceiptSaga,
  deleteReceiptSaga,
  deleteSubcontracorItemSaga,
  findSubContractorsSaga,
  openItemSaga,
  saveBillAttachmentForItemSaga,
  saveBillAttachmentSaga,
  sendForApprovalSubcontracorItemSaga,
  sendForPayment,
  updateItemSaga,
  updateSubcontracorItemSaga,
} from '../components/api/SubContractor/subcontractorSagas'
// Your sagas for this container

export default function* rootSaga() {
  yield [
    fork(checkUser),
    fork(tryLogin),
    fork(logout),
    fork(failures),
    fork(getPersonInfo),
    fork(serchCustomers),
    fork(updateSharedCustomers),
    fork(deleteSharedCustomers),
    fork(getSalaries),
    fork(updatePerson),
    fork(createSharedCustomer),
    fork(getTemplates),
    fork(updateTemplate),
    fork(disableTemplate),
    fork(getProfilePic),
    fork(uploadProfilePic),
    fork(uploadTaxCardPic),
    fork(getBills),
    fork(createBill),
    fork(createBillableItem),
    fork(getArchive),
    fork(calculateSallary),
    fork(calculateSallaryFromBill),
    fork(updateBill),
    fork(deleteBill),
    fork(sendOpenRequest),
    fork(updateBillableItem),
    fork(getSingleBillableItem),
    fork(deleteBillableItem),
    fork(createReceipt),
    fork(deleteReceipt),
    fork(getReceipt),
    fork(selectAndFetchBill),
    fork(findCustomers),
    fork(startRegistration),
    fork(getUserByToken),
    fork(agreeEula),
    fork(startResetPassword),
    fork(resetPassword),
    fork(updateDueDate),
    fork(findSubContractorsSaga),
    fork(createSubcontractorItemSaga),
    fork(updateSubcontracorItemSaga),
    fork(deleteSubcontracorItemSaga),
    fork(approveSubcontracorItemSaga),
    fork(declineSubcontracorItemSaga),
    fork(sendForApprovalSubcontracorItemSaga),
    fork(saveBillAttachmentSaga),
    fork(createExternalItemSaga),
    fork(saveBillAttachmentForItemSaga),
    fork(deleteReceiptSaga),
    fork(sendForPayment),
    fork(openItemSaga),
    fork(updateItemSaga),
    fork(deleteExtItemSaga),
    fork(deletePlainReceiptSaga),
    fork(validateBill),
    fork(getUserWithToken),
    fork(duplicateBill),
    fork(getTaxInfoSaga),
    fork(sendToApprove),
    fork(createAdvancePaymentSaga),
    fork(calculateSalaryFromPartialBill),
    fork(bankConfirmed),
  ]
}
