import React from 'react'
import { IndexLink } from 'react-router'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'

const validate = values => {
  const errors = {}
  const requiredFields = ['username', 'password']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'validation.required'
    }
  })
  return errors
}

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input
        type="text"
        className="form-control input-lg"
        value={field.value}
        {...field}
        {...props}
      />
      {errors[field.name] && touched[field.name] && (
        <span className="field-error">{translate(errors[field.name])}</span>
      )}
    </div>
  </div>
)

const LoginForm = props => {
  const { submitValues, translate } = props
  const initialValues = {
    username: '',
    password: '',
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        submitValues(values)
      }}
    >
      {({
        handleSubmit,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="col-xs-12">
            <Field
              name="username"
              autoComplete="username"
              label={translate('login.email')}
              translate={translate}
              component={CustomInputComponent}
            />
          </div>
          <div className="col-xs-12">
            <Field
              name="password"
              type="password"
              autoComplete="current-password"
              label={translate('login.password')}
              translate={translate}
              component={CustomInputComponent}
            />
          </div>
          <div className="col-xs-12">
            <div className="medium-margin">
              <button className="btn btn-success btn-block btn-lg" type="submit">
                {translate('login.logIn')}
              </button>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="medium-margin text-center">
              <IndexLink to="/password/reset">{translate('login.forgot')}</IndexLink>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
