import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import BillEditor from '../../../components/BillEditor'
import Card from '../../../components/Card'
import _ from 'lodash'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ContractorRow from '../../../components/Contractor/ContractorRow'
import ExtContractorRow from '../../../components/ExtContractor/ExtContractorRow'
import './archive.scss'


class ArchiveView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      searchString: '',
      editor: false,
      bill: null,
      filteredBills: []
    }
  }

  openEditor = (bill) => {
    const path = '/invoice?id=' + bill.id
    this.props.redirect(path)
  }

  closeEditor = () => {
    this.setState({
      editor: false,
      bill: null
    })
    this.props.getBills()
    this.props.getArchive({
      page: this.props.archivePage
    })
  }

  handleUpdate = () => {
    this.props.getBills()
  }

  componentDidMount() {
    this.props.getBills()
    this.props.getArchive({
      page: this.props.archivePage
    })
  }


  fetchMore() {
    this.props.getArchive({
      page: this.props.archivePage + 1
    })
    this.props.addPage()
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.props.lastUpdatedBill !== nextprops.lastUpdatedBill
      && nextprops.lastUpdatedBill.state === 'PENDING' && !nextprops.lastUpdatedBill.error) {
      const { filteredBills } = this.state
      filteredBills.push(nextprops.lastUpdatedBill.id)
      this.setState({ filteredBills })
    }
  }

  getBillsWithFilter = (bills) => {
    const { searchString } = this.state
    const items = _.filter(bills, function (o) {
      let pass = _.includes(('' + o.billNumber).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.sharedCustomer.name).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.billingdate).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.calculatedDueDate).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.state).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.totalprice).toLowerCase(), searchString.toLowerCase())
      if (!pass) {
        let filtered = _.filter(o.billableitems, function (bi) {
          return _.includes(('' + bi.destination).toLowerCase(), searchString.toLowerCase()) ||
            _.includes(('' + bi.price).toLowerCase(), searchString.toLowerCase()) ||
            _.includes(('' + bi.alvPrice).toLowerCase(), searchString.toLowerCase())
        })
        pass = filtered.length > 0
      }
      return pass
    })
    return items
  }

  getContractorWithFilter = (subcontractorItems) => {
    const { searchString } = this.state
    return _.filter(subcontractorItems, function (o) {
      return _.includes(('' + o.description).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.contractor.fullName).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.subcontractor.fullName).toLowerCase(), searchString.toLowerCase())
    })
  }

  getExtContractorWithFilter = (extItems) => {
    const { searchString } = this.state
    return _.filter(extItems, function (o) {
      return _.includes(('' + o.name).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.accountNumber).toLowerCase(), searchString.toLowerCase()) ||
        _.includes(('' + o.duedate).toLowerCase(), searchString.toLowerCase())
    })
  }

  renderNewBills = () => {
    const bills = this.getBillsWithFilter(this.props.archive.bills)
    return bills
      .filter(bill => bill.state === 'OPEN')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }
  renderOpenBills = () => {
    const bills = this.getBillsWithFilter(this.props.archive.bills)
    return bills
      .filter(bill => bill.state === 'APPROVED' || bill.state === 'PENDING')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }
  renderSentBills = () => {
    const bills = this.getBillsWithFilter(this.props.archive.bills)
    return bills
      .filter(bill => bill.state === 'CLOSED')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderCreditedBills = () => {
    const bills = this.getBillsWithFilter(this.props.archive.bills)
    return bills
      .filter(bill => bill.state === 'HYVITETTY')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderPaidBills = () => {
    const bills = this.getBillsWithFilter(this.props.archive.bills)
    return bills
      .filter(bill => bill.state === 'PAID')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderSubcontractorItems() {
    const items = this.getContractorWithFilter(this.props.archive.contractorBillableItems)
    return items.map(item => {
      return (
        <div className="col-xs-12 col-sm-6 col-md-12" key={item.id}>
          <ContractorRow item={item} />
        </div>
      )
    })
  }

  renderExtcontractorItems() {
    const items = this.getExtContractorWithFilter(this.props.archive.extContractorBillableItems)
    return items.map(item => {
      return (
        <div className="col-xs-12" key={item.id}>
          <ExtContractorRow item={item} />
        </div>
      )
    })
  }

  renderBill(bill, key) {
    if (this.state.filteredBills.includes(bill.id)) return null
    return (
      <div className="col-xs-12 col-sm-6 col-md-12" key={key}>
        <Card
          onUpdate={this.handleUpdate}
          data={bill}
          onEdit={this.openEditor.bind(this, bill)}
        />
      </div>
    )
  }

  onSearch = (e) => {
    this.setState({ searchString: e.target.value })
  }

  changeOrder = (e) => {
    this.setState({ order: e.target.value })
  }

  render() {
    const { translate } = this.props

    const newBills = this.renderNewBills()
    const openBills = this.renderOpenBills()
    const sentBills = this.renderSentBills()
    const creditedBills = this.renderCreditedBills()
    const paidBills = this.renderPaidBills()
    const subcontractor = this.renderSubcontractorItems()
    const extsubcontractor = this.renderExtcontractorItems()
    if (this.state.editor) {
      return (<BillEditor onUpdate={this.handleUpdate} onClose={this.closeEditor} bill={this.state.bill} />)
    }

    return (
      <div className="row">
        <div className="dashboard">
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              <span className="pull-left">
                {translate('invoicesView.title')}
              </span>
              <div className="row">
                <div className="col-xs-12 col-sm-9 pull-right archive-controls">
                  <div className="row">
                    <div className="col-xs-6 col-sm-4">
                    </div>
                    <div className="col-xs-6 col-sm-8">
                      <div className="search pull-right">
                        <div className="input-group add-on">
                          <input className="form-control"
                            placeholder={translate('invoicesView.search')}
                            onChange={this.onSearch}
                            type="text" />
                          <div className="input-group-btn">
                            <button className="btn btn-default" type="submit">
                              <i className="glyphicon icon-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {newBills.length !== 0 ?
            <div className="col-lg-12" >
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoicesView.notSent')}
              </div>
              <div className="row">
                {newBills}
              </div>
            </div>
            : null}


          {sentBills.length !== 0 ?
            <div className="col-lg-12" >
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoicesView.sentBills')}
              </div>
              <div className="row">
                {sentBills}
              </div>
            </div>
            : null}

          {openBills.length !== 0 ?
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoicesView.pendingBills')}
              </div>
              <div className="row">
                {openBills}
              </div>
            </div>
            : null}

          {creditedBills.length !== 0 ?
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoicesView.creditedBills')}
              </div>
              <div className="row">
                {creditedBills}
              </div>
            </div>
            : null}

          {paidBills.length !== 0 ?
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('invoicesView.paidBilld')}
              </div>
              <div className="row">
                {paidBills}
              </div>
            </div>
            : null}
          {subcontractor.length !== 0 ?
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('dashboard.subcontractorItems')}
              </div>
              <div className="row">
                {subcontractor}
              </div>
            </div>

            : null}

          {extsubcontractor.length !== 0 ?
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('subcontracting.extSubcontractingRows')}
              </div>
              <div className="row">
                {extsubcontractor}
              </div>
            </div>
            : null}
          <div className="button-container">
            <button className="btn btn-success btn-new-invoice" onClick={this.fetchMore.bind(this)}> {translate('invoicesView.loadMore')}</button>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    archive: state.bills.archive || [],
    bills: state.bills.list || [],
    translate: getTranslate(state.locale),
    lastUpdatedBill: state.bills.lastUpdatedBill,
    archivePage: state.bills.archivePage
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getArchive: (data) => dispatch({ type: 'GET_ARCHIVE', data }),
    duplicateBill: (id) => dispatch({ type: 'DUPLICATE_BILL', id }),
    addPage: () => dispatch({ type: 'ADD_ARCHIVE_PAGE' }),
    getBills: () => dispatch({ type: 'GET_BILLS' }),
    redirect: (url) => dispatch(push(url))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveView)
