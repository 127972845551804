import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import CalculatorForm from './CalculatorForm'
import './calculator.scss'

class Calculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'normal',
      opened: false,
      lastCalculated: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleState = this.toggleState.bind(this)
    this.changeTab = this.changeTab.bind(this)
  }

  toggleState = () => {
    this.setState({ opened: !this.state.opened })
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.lastCalculated !== this.props.lastCalculated) {
      this.setState({ lastCalculated: nextprops.lastCalculated })
    }
  }

  changeTab = (tab) => {
    this.setState({ tab: tab })
  }

  handleSubmit = (values) => {
    for (var key in values) {
      values[key] = +values[key] || 0
    }
    this.props.calculate({ ...values, taxPercent: values.taxPercent / 100, payday: '2022-06-12' })
  }

  render() {
    const { translate, user } = this.props
    const { lastCalculated } = this.state
    const ts = (value) => parseFloat(value).toFixed(2).toString().replace('.', ',')

    let hasYel = false
    if (lastCalculated && lastCalculated.salary && lastCalculated.yel && lastCalculated.yel > 0) {
      hasYel = true
    }
    return (
      <div className="row">
        <div className="dashboard calculator">
          <div className="confines">
            <div className="col-lg-12">
              <div style={{ marginTop: '30px' }} className="panel info-title">
                {translate('calculator.title')}
              </div>
              <div className="description dkblue">{translate('calculator.text')}</div>
              <CalculatorForm
                changeTab={this.changeTab}
                opened={this.state.opened}
                toggleState={this.toggleState}
                onSubmit={this.handleSubmit}
                tab={this.state.tab}
                user={user}
              />
              {lastCalculated && (
                <div className="calculator-result">
                  <table>
                    <tbody className="dkblue">
                      <tr>
                        <td>{translate('invoice.allInTotal')}</td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.totalBilling || 0)} €</span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translate('invoice.allInTotalWihtoutAlv')}</td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.totalBillingWithoutAlv || 0)} €</span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translate('invoice.taxableamount')}</td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.taxableamount || 0)} €</span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translate('invoice.employerCosts')}</td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.employerCosts || 0)} €</span>
                        </td>
                      </tr>
                      {lastCalculated.total_reimbursements > 0 && (
                        <tr className="dkblue">
                          <td>{translate('invoice.reimbursements')}</td>
                          <td className="dkblue">
                            <span className="sum pull-right">{ts(lastCalculated.total_reimbursements || 0)} €</span>
                          </td>
                        </tr>
                      )}
                      {lastCalculated.billableAmountToBePaid > 0 && (
                        <tr className="dkblue">
                          <td>{translate('invoice.taxfree_reimbursements')}</td>
                          <td className="dkblue">
                            <span className="sum pull-right">{ts(lastCalculated.billableAmountToBePaid || 0)} €</span>
                          </td>
                        </tr>
                      )}
                      <tr style={{ height: '50px' }}>
                        <td colSpan="2"></td>
                      </tr>
                      {lastCalculated.reductions < 0 && (
                        <tr className="dkblue">
                          <td>{translate('invoice.reductions')}</td>
                          <td>
                            <span className="sum pull-right">{ts(lastCalculated.reductions || 0)} €</span>
                          </td>
                        </tr>
                      )}

                      <tr className="dkblue">
                        <td>{translate('invoice.taxamount')}</td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.taxamount || 0)} €</span>
                        </td>
                      </tr>
                      {hasYel && (
                        <tr className="dkblue">
                          <td>{translate('invoice.tyel')}</td>
                          <td>
                            <span className="sum pull-right">{ts(lastCalculated.yel || 0)} €</span>
                          </td>
                        </tr>
                      )}
                      <tr className="dkblue">
                        <td>
                          <span>{translate('invoice.sotu')}</span>
                        </td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.sotu_total || 0)} €</span>
                        </td>
                      </tr>
                      {lastCalculated.distraint > 0 && (
                        <tr className="dkblue">
                          <td>{translate('invoice.distraint')}</td>
                          <td>
                            <span className="sum pull-right">{ts(lastCalculated.distraint || 0)} €</span>
                          </td>
                        </tr>
                      )}
                      <tr className="blue">
                        <td data-tip data-for="global-salary">
                          {translate('invoice.salary')}
                        </td>
                        <td>
                          <span className="sum pull-right">{ts(lastCalculated.salary || 0)} €</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    lastCalculated: state.calculator.lastCalculated,
    translate: getTranslate(state.locale),
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    calculate: data => dispatch({ type: 'CALCULATE_SALLARY', data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator)
