import { divide } from 'ramda'
import React, { Component, useEffect, useState } from 'react'
require('./error.css')
export const Error = (msg) => {
  console.log('msg', msg)
  return (
    <div className="error-alert">
      {msg.msg}{' '}
      <a href="https://www.privateinternetaccess.com/blog/how-to-disable-geolocation-in-browsers/">
        privateinternetaccess.com
      </a>
    </div>
  )
}

export default Error
