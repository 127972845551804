import React from 'react'

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input type="text" style={{width: '100%',  minWidth: '96%', lineHeight: '50px'}} className="form-control mobile-date" value={field.value} {...field} {...props} />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}
