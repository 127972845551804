import React from 'react'
import './marketing.scss'

export const landingAdd = (
  <div className="col-xs-12">
    <div className="marketing-container">
      <div className="info-icon icon-warning">
        <svg
          width="24"
          height="17"
          viewBox="0 0 24 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.2935 1.04226C21.8389 0.432137 20.3831 0.200012 18.9281 0.200012C14.3092 0.199637 9.69075 2.53776 5.07187 2.53776C3.9135 2.53776 2.75587 2.39076 1.5975 2.02326C1.46737 1.98201 1.33687 1.96251 1.20937 1.96251C0.564 1.96251 0 2.46201 0 3.15539V15.0526C0 15.5263 0.271125 15.9751 0.7065 16.1574C2.16112 16.7679 3.61687 17 5.07187 17C9.69075 17 14.3096 14.6619 18.9285 14.6619C20.0869 14.6619 21.2445 14.8089 22.4029 15.1764C22.533 15.2176 22.6635 15.2371 22.791 15.2371C23.4364 15.2371 24.0004 14.7376 24.0004 14.0443V2.14739C24 1.67339 23.7289 1.22489 23.2935 1.04226ZM12 12.2C10.3429 12.2 9 10.5879 9 8.60001C9 6.61176 10.3432 5.00001 12 5.00001C13.6567 5.00001 15 6.61176 15 8.60001C15 10.5886 13.6564 12.2 12 12.2Z"
            fill="white"
          />
        </svg>
      </div>
      <h2>3,9 %</h2>
      <p>Palvelumaksuun sisältyy:</p>
      <ul>
        <li>Kaikki paperityöt.</li>
        <li>Nopea palkanmaksu.</li>
        <li>Ei piilokuluja.</li>
        <li>Kysy suurlaskuttajan etuja - palvelumaksun kattohinta alkaen 89€/kk.</li>
      </ul>
    </div>
  </div>
)
