const isNumeric = /^[-+]?(\d+|\d+\.\d*|\d*\.\d+|[+-]|ˆ$)$/

export function normalizeNumber(value, previousValue) {
  const formatted = value.toString().replace(',', '.')
  if (!isNumeric.test(formatted)) {
    return '0'
  }
  return formatted
}

export function currencyFormatter(number) {
  if (!number) return ''
  if (isNaN(number)) return ''
  return number.toString().replace('.', ',')
}

export function normalizePercent(value, previousValue) {
  const formatted = value.toString().replace(/[^0-9,.]/g, '').replace(',', '.')
  if (isNaN(formatted)) {
    return 0
  }
  return formatted
}

export function percentageFormatter(number) {
  if (!number) return ''
  if (isNaN(number)) {
    return ''
  }
  return number
}

export function formatNumber(num) {
  return getNumber(num)
}

function getNumber(num) {
  if (num === undefined || num === null) return ''
  const val = num.toString().replace(/[^0-9.,+-]+/g, '').replace(/,/g, '.')
  if (!isNumeric.test(val)) {
    return ''
  }
  return val.toString().replace(/\./g, ',')
}

export function parseNumber(str) {
  if (str === undefined || str === null) return null
  const number = str.replace(/[^0-9.,+-]+/g, '').replace(/,/g, '.')
  if (!isNumeric.test(number)) {
    return ''
  }
  return number
}


export const isNumber = number => {
  if (number === undefined || number === null) return 'needsToBeNumber'
  number = number.toString().replace(',', '.')
  if (!isNumeric.test(number)) {
    return 'needsToBeNumber'
  }
  return undefined
}
