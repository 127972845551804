import React from 'react'
import { Modal, Button } from 'react-bootstrap'


export function deleteModal(modal, message, translate, closeModal, handleAction) {
  const show = !!modal
  return (
    <Modal show={show}
      onHide={closeModal}
      backdrop={'static'}
      animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{translate('invoice.confirmTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="message">{message}</div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <Button variant="cancel" onClick={closeModal}>{translate('misc.cancel')}</Button>
          </div>
          <div className="col-xs-6">
            <Button variant="danger" onClick={handleAction}>{translate('misc.confirm')}</Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal >
  )
}
