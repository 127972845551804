import { CALCULATE_FROM_PARTIAL_BILL, CLEAR_CALCULATE_FROM_PARTIAL_BILL } from './calculatorActions'

const partialCalculatorReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_FROM_PARTIAL_BILL + '_SUCCESS':
      return {
        ...state,
        ...action.response,
      }
    case CLEAR_CALCULATE_FROM_PARTIAL_BILL:
      return {
      }
    default:
      return state
  }
}

export default partialCalculatorReducer
