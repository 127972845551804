import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'
import {getTranslate} from 'react-localize-redux'
import {connect} from 'react-redux'
import './tooltip.scss'

class TooltipContainer extends Component {

  render() {
    const {translate} = this.props
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    const event = isMobile ? 'touchstart' : ''
    const options = {
      event,
      eventOff: 'touchend mouseleave click',
      globalEventOff: 'click',
      place: 'top',
      effect: 'solid'
    }
    return (
      <div>
        <ReactTooltip id='global-customer-view' {...options} >
          {translate('tooltip.customer.view')}
        </ReactTooltip>
        <ReactTooltip id='global-customer-save' {...options} >
          {translate('tooltip.customer.save')}
        </ReactTooltip>
        <ReactTooltip id='global-customer-remove' {...options} >
          {translate('tooltip.customer.remove')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-edit' {...options} >
          {translate('tooltip.card.edit')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-copy' {...options} >
          {translate('tooltip.card.copy')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-view' {...options} >
          {translate('tooltip.card.view')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-open' {...options} >
          {translate('tooltip.card.send')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-send' {...options} >
          {translate('tooltip.card.open')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-print' {...options} >
          {translate('tooltip.card.print')}
        </ReactTooltip>
        <ReactTooltip id='global-invoiceCard-delete' {...options} >
          {translate('tooltip.card.delete')}
        </ReactTooltip>
        <ReactTooltip id='global-invoice-disabled-tooltip' {...options} >
          {translate('tooltip.invoiceDisabled')}
        </ReactTooltip>
        <ReactTooltip id='global-view' {...options} >
          {translate('tooltip.view')}
        </ReactTooltip>
        <ReactTooltip id='global-print' {...options} >
          {translate('tooltip.print')}
        </ReactTooltip>
        <ReactTooltip id='global-delete' {...options} >
          {translate('tooltip.delete')}
        </ReactTooltip>
        <ReactTooltip id='global-approve' {...options} >
          {translate('tooltip.approve')}
        </ReactTooltip>
        <ReactTooltip id='global-decline' {...options} >
          {translate('tooltip.decline')}
        </ReactTooltip>
          <ReactTooltip id='global-field-disabled' {...options} >
              {translate('tooltip.fieldDisabled')}
          </ReactTooltip>
        <ReactTooltip id='global-info' {...options} className='global-info-theme'/>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale)
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TooltipContainer)
