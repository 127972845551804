import React, { Component } from 'react'
import { connect } from 'react-redux'
import ExternalModal from './ExternalModal.js'
import { filter } from 'ramda'

import {
  createExternalItemAction,
  createReceipt,
  deleteReceiptAction,
} from '../../../components/api/SubContractor/subContractorActions'

class ExternalBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extModalOpen: false,
      receipts: [],
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeExtModal = this.closeExtModal.bind(this)
  }

  openExtModal = () => {
    this.setState({
      extModalOpen: !this.state.extModalOpen,
    })
  }

  closeExtModal = () => {
    this.setState({
      extModalOpen: false,
      receipts: [],
    })
  }

  handleSubmit = values => {
    const { createExternalItem, selectedBill } = this.props
    createExternalItem(values, selectedBill.id)
    this.closeExtModal()
  }

  onUploadProgress = progress => {
    this.props.uploadProgress(progress)
    if (progress.done === true) {
      this.validateForm()
      this.setState({
        ...this.state,
        receipts: [...this.state.receipts, progress.file],
      })
    }
  }

  handleDelete = e => {
    const receipts = filter(item => item.id !== e.id, this.state.receipts)
    this.props.deleteReceipt(e.id, this.props.selectedBill.id)
    const state = {
      ...this.state,
      receipts: [...receipts],
    }
    this.setState(state)
  }

  onReceiptAdd = (e, validateForm) => {
    const { selectedBill } = this.props
    this.validateForm = validateForm
    const file = e.target.files[0]
    file && this.props.createReceipt({ image: file, id: selectedBill.id }, this.onUploadProgress)
    e.target.value = null
  }

  render() {
    const { translate, selectedBill, upload, user, locale } = this.props
    const { receipts } = this.state
    const disabled = selectedBill.state !== 'OPEN'
    return (
      <div>
        <div className="col-sm-4 medium-margin">
          <button
            disabled={!selectedBill.id || selectedBill.state !== 'OPEN'}
            type="button"
            className="btn btn-accept medium-margin"
            onClick={this.openExtModal}
          >
            <span>
              <span className="glyphicon icon-plus-white"></span>
              {translate('invoice.addExtSubcontractor')}
            </span>
          </button>
        </div>
        <ExternalModal
          bill={selectedBill}
          show={this.state.extModalOpen}
          translate={translate}
          closeModal={this.closeExtModal}
          handleSubmit={this.handleSubmit}
          onReceiptAdd={this.onReceiptAdd}
          handleDelete={this.handleDelete}
          validateForm={this.validateForm}
          upload={upload}
          receipts={receipts}
          user={user}
          locale={locale}
          disabled={disabled}
          initialValues={{
            amount: 0,
            name: '',
            fullprice: '',
            referenceNumber: '',
            accountNumber: '',
            paynow: true,
            duedate: '',
            receipts: receipts,
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    lastUpdatedBillableItem: state.bills.lastUpdatedBillableItem,
    upload: state.bill.upload,
    user: state.user.user,
    selectedBill: state.bill.selectedBill,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createReceipt: (data, progressCallback) => dispatch(createReceipt(data, progressCallback)),
    uploadProgress: data => dispatch({ type: 'CURRENT_UPLOAD', data }),
    deleteReceipt: (receiptId, billID) => dispatch(deleteReceiptAction(receiptId, billID)),
    createExternalItem: (data, billId) => dispatch(createExternalItemAction(data, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalBill)
