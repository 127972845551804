import React, { Component } from 'react'
import Card from '../../../components/Card'
import Chart from '../../../components/Chart'
import StatusItem from '../../../components/StatusItem'
import BillEditor from '../../../components/BillEditor'
import ContractorRow from '../../../components/Contractor/ContractorRow'
import { getTranslate } from 'react-localize-redux'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { contains } from 'ramda'
import './HomeView.scss'

class HomeView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editor: false,
      bill: null,
    }
  }

  openEditor = (bill) => {
    const path = '/invoice?id=' + bill.id
    this.setState({
      bill,
    })

    this.props.redirect(path)
  }

  closeEditor = () => {
    this.setState({
      editor: false,
      bilId: null,
    })
  }

  componentDidMount() {}

  UNSAFE_componentWillMount() {
    const { user } = this.props
    if (!user || !user.authtoken) {
      this.props.redirect('/login')
    } else {
      setTimeout(() => {
        this.props.getInfo(24)
      }, 500)
    }
  }

  renderSubcontractorItems() {
    const items = this.props.info.subcontractorBillableItems || []
    return items.map((item) => {
      return (
        <div className="col-xs-12 col-sm-6 col-md-12" key={item.id}>
          <ContractorRow item={item} />
        </div>
      )
    })
  }

  renderBills = (...args) => {
    const bills = this.props.info.notPaidBills || []
    return bills
      .filter((bill) => contains(bill.state, args))
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderBill(bill, key) {
    return (
      <div className="col-xs-12 col-sm-6 col-md-12" key={key}>
        <Card data={bill} onEdit={this.openEditor.bind(this, bill)} />
      </div>
    )
  }

  render() {
    const { translate, user } = this.props
    if (!user || !user.authtoken) {
      return null
    }
    const taxPercent = this.props.info.taxpercent || 0
    const datePrices = this.props.info.datePrices || []
    const salaryPrices = this.props.info.salaryPrices || []
    const ts = (value) =>
      parseFloat(value || 0)
        .toFixed(2)
        .replace('.', ',')
    const percent = (value) =>
      parseFloat(value * 100 || 0)
        .toFixed(2)
        .replace('.', ',')

    const openBills = this.renderBills('OPEN')
    const closedBills = this.renderBills('CLOSED', 'PENDING')
    const subcontractorItems = this.renderSubcontractorItems()

    if (this.state.editor) {
      return <BillEditor onUpdate={this.handleUpdate} onClose={this.closeEditor} bill={this.state.bill} />
    }
    return (
      <div className="row">
        <div className="dashboard">
          <div className="col-lg-12">
            <div className="panel info-title">{translate('dashboard.title')}</div>
            <div className="row">
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem
                  type="document"
                  sum={ts(this.props.info.taxcardLeft) + ' €'}
                  sub={translate('dashboard.taxcardLeft')}
                  text={
                    <Link to="/account" className="text">
                      {translate('dashboard.taxcard')}
                    </Link>
                  }
                />
              </div>
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem
                  type="paperplane"
                  sum={ts(this.props.info.taxableAmount) + ' €'}
                  sub={translate('dashboard.taxableAmount')}
                  text={
                    <Link to="/salaries" className="text">
                      {translate('dashboard.viewSalaries')}
                    </Link>
                  }
                />
              </div>
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem type="coins" sum={ts(this.props.info.taxAmountThisYear) + ' €'} sub={translate('dashboard.taxAmountThisYear')} />
              </div>
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem type="coins" sum={percent(taxPercent) + ' %'} sub={translate('dashboard.taxPercent')} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem type="money" sum={ts(this.props.info.openAmount) + ' €'} sub={translate('dashboard.openInvoices')} />
              </div>
              <div className="col-sm-6 col-md-3 no-padding-right">
                <StatusItem type="money" sum={ts(this.props.info.approvedAmount) + ' €'} sub={translate('dashboard.closedBills')} />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="panel info-title">
              {translate('dashboard.openInvoices')} ({openBills.length})
            </div>
            <div className="row">{openBills}</div>
          </div>
          <div className="col-lg-12">
            <div className="panel info-title">
              {translate('dashboard.closedBills')} ({closedBills.length})
            </div>
            <div className="row">{closedBills}</div>
          </div>
          <div className="col-lg-12">
            <div className="panel info-title">
              {translate('dashboard.subcontractorItems')} ({subcontractorItems.length})
            </div>
            <div className="row">{subcontractorItems}</div>
          </div>
          <div className="col-md-6 chart-card">
            <div className="panel info-title">{translate('dashboard.invoicesChart')}</div>
            <Chart values={datePrices} />
          </div>
          <div className="col-md-6 chart-card">
            <div className="panel info-title">{translate('dashboard.wagesChart')}</div>
            <Chart values={salaryPrices} color="#e6a722" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    info: state.info.info || {},
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInfo: (months) => dispatch({ type: 'GET_INFO', months }),
    redirect: (url) => dispatch(push(url)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
