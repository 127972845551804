import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import './Chart.scss'

class Chart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: 24,
    }
  }

  getLabels() {
    const months = this.props.translate('chart.months').split(',')
    let res = []
    for (let i = 0; i < this.state.range; i++) {
      const currDate = new Date()
      currDate.setDate(1)
      const prevDate = new Date(currDate.setMonth(currDate.getMonth() - i))
      res.unshift(months[prevDate.getMonth()] + ' ' + prevDate.getFullYear())
    }
    return res
  }

  getValues() {
    if (!this.props.values || !this.props.values.length) {
      return []
    }
    const pad = n => {
      return n < 10 ? '0' + n : n
    }
    const res = []

    const dates = this.props.values.map(item => {
      return item.date
    })
    for (let i = 0; i < this.state.range; i++) {
      const currDate = new Date()
      currDate.setDate(1)
      const prevDate = new Date(currDate.setMonth(currDate.getMonth() - i))
      const dateString = '01.' + pad(prevDate.getMonth() + 1) + '.' + prevDate.getFullYear()
      if (dates.indexOf(dateString) >= 0) {
        const index = dates.indexOf(dateString)
        res.unshift(this.props.values[index].price)
      } else {
        res.unshift(null)
      }
    }
    return res
  }

  changeRange(range) {
    this.setState({
      range: range,
    })
  }

  render() {
    const { translate } = this.props
    const color = this.props.color || '#1aa6b7'
    const renderTab = range => (
      <div
        className={this.state.range === range ? 'tab active' : 'tab'}
        style={this.state.range === range ? { color: color, borderColor: color } : {}}
        onClick={this.changeRange.bind(this, range)}
      >
        {range}
        {translate('chart.m')}
      </div>
    )
    const options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0.05)',
            },
            ticks: {
              autoSkip: false,
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0.05)',
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    }
    const data = {
      datasets: [
        {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: color,
          borderWidth: 1,
          pointBackgroundColor: 'white',
          pointBorderWidth: 2,
          pointRadius: 3,
          lineTension: 0,
          spanGaps: false,
          data: this.getValues(),
        },
      ],
      labels: this.getLabels(),
    }

    return (
      <div className="chart">
        <div className="tabs">
          <div className="row">
            <div className="col-xs-3 no-padding">{renderTab(3)}</div>
            <div className="col-xs-3 no-padding">{renderTab(6)}</div>
            <div className="col-xs-3 no-padding">{renderTab(12)}</div>
            <div className="col-xs-3 no-padding">{renderTab(24)}</div>
          </div>
        </div>
        <Line data={data} options={options} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Chart)
