import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Client from '../../../components/Client'
import AddClientForm from './AddClientForm'
import { isEmpty, any } from 'ramda'
import './customers.scss'
import { Modal } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

class CustomersView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchResult: [],
      modalOpen: false,
      searchString: '',
      typing: false,
      typingTimeOut: 0,
      notSearched: true,
    }
    this.doSearch = this.doSearch.bind(this)
  }

  componentDidMount() {
    this.props.findCustomers('')
  }

  UNSAFE_componentWillReceiveProps(nexprops) {
    const { searchResult } = nexprops
    if (searchResult !== this.props.searchResult) {
      const result = searchResult || []
      this.setState({ searchResult: result.slice(0, Math.max(0, result.size)) })
    }
  }

  doSearch = data => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }
    if (!data) return
    this.props.findCustomers(data)
    this.setState({ typing: false })
  }

  onSearch = e => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      searchString: e.target.value,
      typing: true,
      notSearched: false,
      typingTimeout: setTimeout(() => {
        this.doSearch(this.state.searchString)
      }, 200),
    })
  }
  callSearch = e => {
    if (e.keyCode === 13 || typeof e.keyCode === 'undefined') {
      this.doSearch(this.state.searchString)
      this.setState({ typing: false, searchResult: [] })
    }
  }

  openModal = () => {
    this.setState({
      modalOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    })
  }

  onSubmitForm = values => {
    const { sendErrorMessage } = this.props
    values.termofpayment = +values.termofpayment
    const callback = obj => {
      if (obj.success) {
        this.closeModal()
      } else {
        sendErrorMessage(obj.error)
      }
    }
    this.props.createCustomer({ values, callback })
  }

  getContent = () => {
    const { translate } = this.props
    return translate('tooltip.customerNotSearched')
  }

  render() {
    const { translate, user, searchResult } = this.props
    const { searchString, notSearched } = this.state
    if (!user || !user.authtoken) {
      return null
    }
    const clientsArray = searchResult
    const searched = !isEmpty(searchString)
    const clients = ((searched && clientsArray) || []).map((client, key) => (
      <Client key={key} data={client} canBeAdded={!any(user.sharedCustomers, { id: client.id })} />
    ))
    const addedClients = (user.sharedCustomers || []).map((client, key) => (
      <Client key={key} data={client} canBeAdded={false} />
    ))
    return (
      <div className="row">
        <div className="dashboard customers">
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              <span className="pull-left">{translate('customers.title')}</span>
              <div className="row">
                <div className="col-xs-12 col-sm-8 pull-right archive-controls">
                  <div className="row">
                    <div className="col-xs-6 col-sm-7 col-md-8 col-lg-9">
                      <div className="search pull-right">
                        <div className="input-group add-on">
                          <input
                            className="form-control search"
                            onChange={this.onSearch}
                            onKeyDown={this.callSearch}
                            placeholder={translate('customers.search')}
                            type="text"
                          />
                          <div className="input-group-btn">
                            <button className="btn btn-default" onClick={this.callSearch}>
                              <i className="glyphicon icon-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-tip
                      data-for="addCustomer"
                      data-iscapture="true"
                      data-tip-disable={!notSearched}
                      className="col-xs-6 col-sm-5 col-md-4 col-lg-3"
                    >
                      <button
                        disabled={notSearched}
                        className="btn btn-success"
                        onClick={this.openModal}
                      >
                        <span>
                          <span className="glyphicon icon-plus-white"></span>
                          {translate('customers.buttonNew')}
                        </span>
                      </button>
                      <ReactTooltip
                        id="addCustomer"
                        getContent={() => this.getContent(notSearched)}
                        effect="solid"
                        delayHide={1000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchString && (
              <div>
                {translate('customers.searchResultLabel')} "{searchString}":
              </div>
            )}
            <div style={{ marginLeft: '-15px', marginRight: '-15px' }} hidden={!searched}>
              <div className="table">
                <div className="table-header hidden-xs hidden-sm">
                  <div className="field">{translate('customers.fields.name')}</div>
                  <div className="field no-padding">
                    {translate('customers.fields.organization')}
                  </div>
                  <div className="field businessId no-padding">{translate('customers.fields.businessid')}</div>
                  <div className="field no-padding">
                    {translate('customers.fields.phonenumber')}
                  </div>
                  <div className="field no-padding">{translate('customers.fields.email')}</div>
                  <div className="field no-padding">{translate('customers.fields.method')}</div>
                  <div className="field no-padding"></div>
                </div>
                {clients}
              </div>
            </div>

            <h3>Lisätyt asiakkaat</h3>
            <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <div className="table">
                <div className="table-header hidden-xs hidden-sm">
                  <div className="field">{translate('customers.fields.name')}</div>
                  <div className="field no-padding">
                    {translate('customers.fields.organization')}
                  </div>
                  <div className="field businessId no-padding">{translate('customers.fields.businessid')}</div>
                  <div className="field no-padding">
                    {translate('customers.fields.phonenumber')}
                  </div>
                  <div className="field no-padding">{translate('customers.fields.email')}</div>
                  <div className="field no-padding">{translate('customers.fields.method')}</div>
                  <div className="field no-padding"></div>
                </div>

                {addedClients}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.modalOpen}
          onHide={this.closeModal}
          size="lg"
          enforceFocus={false}
          backdrop={'static'}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{translate('customers.details.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modalOpen && (
              <AddClientForm hideSearch={true} handleSubmit={this.onSubmitForm} translate={translate} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    translate: getTranslate(state.locale),
    searchResult: state.customers.result,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createCustomer: data => dispatch({ type: 'CREATE_CUSTOMER', data }),
    findCustomers: data => dispatch({ type: 'FIND_CUSTOMERS', data }),
    sendErrorMessage: message =>
      dispatch({
        type: 'SEND_NOTIFICATION',
        response: { message: `LOCALIZED!${message}`, type: 'error' },
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)
