import React, { Component } from 'react'
import { connect } from 'react-redux'
import './StatusItem.scss'

class StatusItem extends Component {
  render() {
    const { type, sum, text, sub } = this.props

    const classes = 'status-item ' + type
    return (
      <div className={classes}>
        <div className="label"></div>
        <div className="info">
          <div className="header">
            <div className="title">{sum}</div>
            <div className="subtitle">{sub}</div>
          </div>
          {text}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, form, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusItem)
