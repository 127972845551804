import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import './calculator.scss'
import moment from 'moment'
import { Button } from 'react-bootstrap'

class Calculator extends Component {
  constructor(props) {
    super(props)
    const lastYear = moment()
      .year(moment().year() - 1)
      .year()
    this.state = {
      month: 1,
      year: lastYear,
      month2: 1,
      year2: lastYear,
      taxYear: lastYear,
    }

    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)

    this.handleMonthChange2 = this.handleMonthChange2.bind(this)
    this.handleYearChange2 = this.handleYearChange2.bind(this)
  }

  toggleState = () => {
    this.setState({ opened: !this.state.opened })
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.lastCalculated !== this.props.lastCalculated) {
      this.setState({ lastCalculated: nextprops.lastCalculated })
    }
  }

  changeTab = (tab) => {
    this.setState({ tab: tab })
  }

  handleMonthChange = (event) => {
    this.setState({ month: event.target.value })
  }

  handleYearChange = (event) => {
    this.setState({ year: event.target.value })
  }

  handleMonthChange2 = (event) => {
    this.setState({ month2: event.target.value })
  }

  handleYearChange2 = (event) => {
    this.setState({ year2: event.target.value })
  }

  handleTaxYearChange = (event) => {
    this.setState({ taxYear: event.target.value })
  }

  getTaxInfo = () => {
    this.props.getTaxInfos(this.state.taxYear)
  }

  formatEuro = (euro) => {
    return euro !== undefined ? `${euro} €` : ''
  }

  formatKm = (km) => {
    return km !== undefined ? `${km} km` : '0 km'
  }

  formatPcs = (pcs) => {
    const { translate } = this.props
    const unit = translate('reimbursements.pcs')
    return pcs !== undefined ? `${pcs} ${unit}` : `0 ${unit}`
  }

  render() {
    const { translate, userDetails, taxInfo } = this.props
    const id = userDetails && userDetails.user ? userDetails.user.id : ''
    var from = moment(`${this.state.year}-${this.state.month}-01`, 'YYYY-MM-DD').format('DD.MM.YYYY')
    var to = moment(`${this.state.year}-${this.state.month}-01`, 'YYYY-MM-DD').endOf('month').format('DD.MM.YYYY')
    var url = `/api/travel-expense/${id}/pdf?from=${from}&to=${to}&authtoken=${userDetails.authtoken}`

    var fromYear = moment(`${this.state.year}-01-01`, 'YYYY-MM-DD').format('DD.MM.YYYY')
    var toYear = moment(`${this.state.year}-12-01`, 'YYYY-MM-DD').endOf('month').format('DD.MM.YYYY')
    const urlWholeYear = `/api/travel-expense/${id}/pdf?from=${fromYear}&to=${toYear}&authtoken=${userDetails.authtoken}`

    var from2 = moment(`${this.state.year2}-${this.state.month2}-01`, 'YYYY-MM-DD').format('DD.MM.YYYY')
    var to2 = moment(`${this.state.year2}-${this.state.month2}-01`, 'YYYY-MM-DD').endOf('month').format('DD.MM.YYYY')
    var url2 = `/api/travel-expense/${id}/taxfree/pdf?from=${from2}&to=${to2}&authtoken=${userDetails.authtoken}`

    var fromYear2 = moment(`${this.state.year2}-01-01`, 'YYYY-MM-DD').format('DD.MM.YYYY')
    var toYear2 = moment(`${this.state.year2}-12-01`, 'YYYY-MM-DD').endOf('month').format('DD.MM.YYYY')
    var url2WholeYear = `/api/travel-expense/${id}/taxfree/pdf?from=${fromYear2}&to=${toYear2}&authtoken=${userDetails.authtoken}`

    const year = moment().year()
    const years = []
    const years2 = []
    for (var i = 0; i < 6; i++) {
      const curr = year - i
      years.push(
        <option key={curr} value={curr}>
          {curr}
        </option>
      )
      years2.push(
        <option key={curr + '-2'} value={curr}>
          {curr}
        </option>
      )
    }
    return (
      <div>
        <div className="row">
          <div className="dashboard calculator">
            <div className="confines">
              <div className="col-lg-12">
                <div style={{ marginTop: '30px' }} className="panel info-title">
                  {translate('reimbursements.TaxInfoTitle')}
                </div>
                <div className="description dkblue">{translate('reimbursements.TaxInfoSubTitle')}</div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>{translate('reimbursements.year')}</label>
                    <select name="year" className="form-control" value={this.state.taxYear} onChange={this.handleTaxYearChange}>
                      {years}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <Button onClick={this.getTaxInfo}>{translate('reimbursements.fetch_tax_info')}</Button>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 padding-left-15">
                <form className="form-horizontal">
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.kilometers_driven')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatKm(taxInfo.kilometers)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.kilometers_per_job')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatKm(taxInfo.kilometersPerJob)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.daily_allowances')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatPcs(taxInfo.fullDayCompensations)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.half_day_allowances')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatPcs(taxInfo.partialDayCompensations)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.numberDaysWithKilometers')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatPcs(taxInfo.numberDaysWithKilometers)}</p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xs-6 padding-left-15">
                <form className="form-horizontal">
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.kilometer_allowance')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatEuro(taxInfo.kmReimbursemets)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.kilometer_allowance_per_job')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatEuro(taxInfo.kmReimbursemetsPerJob)}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-7 control-label">{translate('reimbursements.daily_allowance_total')}</label>
                    <div className="col-sm-5">
                      <p className="form-control-static">{this.formatEuro(taxInfo.increasedlivingExpenses)}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="confines"></div>
        </div>
        <div className="row">
          <div className="dashboard calculator">
            <div className="confines">
              <div className="col-lg-12">
                <div style={{ marginTop: '30px' }} className="panel info-title">
                  {translate('reimbursements.TaxableTitle')}
                </div>
                <div className="description dkblue">{translate('reimbursements.TaxableText')}</div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>{translate('reimbursements.month')}</label>
                    <select name="month" className="form-control" value={this.state.month} onChange={this.handleMonthChange}>
                      <option value="1">{translate('reimbursements.jan')}</option>
                      <option value="2">{translate('reimbursements.feb')}</option>
                      <option value="3">{translate('reimbursements.march')}</option>
                      <option value="4">{translate('reimbursements.April')}</option>
                      <option value="5">{translate('reimbursements.May')}</option>
                      <option value="6">{translate('reimbursements.June')}</option>
                      <option value="7">{translate('reimbursements.July')}</option>
                      <option value="8">{translate('reimbursements.August')}</option>
                      <option value="9">{translate('reimbursements.September')}</option>
                      <option value="10">{translate('reimbursements.October')}</option>
                      <option value="11">{translate('reimbursements.November')}</option>
                      <option value="12">{translate('reimbursements.December')}</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>{translate('reimbursements.year')}</label>
                    <select name="year" className="form-control" value={this.state.year} onChange={this.handleYearChange}>
                      {years}
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <a href={url}>{translate('reimbursements.download')}</a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <a href={urlWholeYear}>{translate('reimbursements.downloadYear')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="dashboard calculator">
            <div className="confines">
              <div className="col-lg-12">
                <div style={{ marginTop: '30px' }} className="panel info-title">
                  {translate('reimbursements.taxfreeTitle')}
                </div>
                <div className="description dkblue">{translate('reimbursements.taxfreeText')}</div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>{translate('reimbursements.month')}</label>
                    <select name="month2" className="form-control" value={this.state.month2} onChange={this.handleMonthChange2}>
                      <option value="1">{translate('reimbursements.jan')}</option>
                      <option value="2">{translate('reimbursements.feb')}</option>
                      <option value="3">{translate('reimbursements.march')}</option>
                      <option value="4">{translate('reimbursements.April')}</option>
                      <option value="5">{translate('reimbursements.May')}</option>
                      <option value="6">{translate('reimbursements.June')}</option>
                      <option value="7">{translate('reimbursements.July')}</option>
                      <option value="8">{translate('reimbursements.August')}</option>
                      <option value="9">{translate('reimbursements.September')}</option>
                      <option value="10">{translate('reimbursements.October')}</option>
                      <option value="11">{translate('reimbursements.November')}</option>
                      <option value="12">{translate('reimbursements.December')}</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>{translate('reimbursements.year')}</label>
                    <select name="year2" className="form-control" value={this.state.year2} onChange={this.handleYearChange2}>
                      {years2}
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <a href={url2}>{translate('reimbursements.download')}</a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>&nbsp;</label>
                    <a href={url2WholeYear}>{translate('reimbursements.downloadYear')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    lastCalculated: state.calculator.lastCalculated,
    translate: getTranslate(state.locale),
    userDetails: state.user,
    taxInfo: state.info.taxInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTaxInfos: year => dispatch({ type: 'TAX_INFO', year }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator)
