import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { deleteModal } from '../Modal/DeleteModal'
import './Card.scss'

import ReactTooltip from 'react-tooltip'

class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: null,
    }
    this.copyBill = this.copyBill.bind(this)
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  handleDelete = () => {
    this.closeModal()
    this.props.deleteBill(this.props.data)
  }

  copyBill = () => {
    this.props.duplicateBill(this.props.data)
  }

  handleSendToAprove = () => {
    this.closeModal()
    const data = { ...this.props.data, state: 'PENDING' }
    this.props.updateBill(data)
  }

  handleSendOpenRequest = () => {
    this.closeModal()
    this.props.openBill(this.props.data)
  }

  handlePrint = () => {
    const { data, user } = this.props
    const w = window.open(
      `/api/bill/${data.id}/pdf?authtoken=${user.authtoken}&content-disposition=inline`,
    )
    w.onload = w.print
  }

  openModal = modal => {
    this.setState({
      modal,
    })
  }

  closeModal = () => {
    this.setState({
      modal: null,
    })
  }

  getClassNameForState(data) {
    const state = data.state
    let className = ''
    if (state === 'OPEN') {
      className = 'title green'
    } else if (state === 'CLOSED') {
      className = 'title yellow'
    } else if (state === 'PAID') {
      className = 'title green'
    } else {
      className = 'title'
    }
    return className
  }

  render() {
    const { data, translate } = this.props
    const { modal } = this.state
    const ts = value =>
      parseFloat(value)
        .toFixed(2)
        .toString()
        .replace('.', ',')
    if (!this.props.data) {
      return null
    }

    let message = ''
    let handleAction = () => {}
    if (modal === 'delete') {
      handleAction = this.handleDelete
      message = translate('invoice.messageForDelete')
    }
    if (modal === 'aprove') {
      handleAction = this.handleSendToAprove
      message = translate('invoice.messageForAproveRequest')
    }
    if (modal === 'open') {
      handleAction = this.handleSendOpenRequest
      message = translate('invoice.messageForOpenRequest')
    }
    const DeleteModal = deleteModal(modal, message, translate, this.closeModal, handleAction)
    let state = data.state
    if (data.openRequest) {
      state = 'OPEN_REQUESTED'
    }

    const classNameForState = this.getClassNameForState(data)
    return (
      <div className="card dkblue">
        <div className="card-header">
          <span className="title">
            <Link style={{ cursor: 'pointer' }} onClick={this.props.onEdit}>
              {data.sharedCustomer.name}
            </Link>
          </span>
          <span className="subtitle">
            {data.id} from {data.billingdate}
          </span>
        </div>
        <ul>
          <li>
            <span className={classNameForState}>
              {translate('invoice.state.' + state)}
              {state === 'PAID' ? <span className="paid-mark"></span> : null}
            </span>
            <span className="text">Status</span>
          </li>
          <li>
            <span className="title">{data.exactDueDate}</span>
            <span className="text">{translate('invoice.dueDate')}</span>
          </li>
          <li>
            <span className="title">{ts(data.totalpriceWithAlv || 0)} €</span>
            <span className="text">{translate('invoice.sum')}</span>
          </li>
          <li>
            <span className="title">{ts(data.salaryPercent * 100 || 0)} %</span>
            <span className="text">{translate('invoice.salaryPercent')}</span>
          </li>
          <li>
            <div className="btn-group pull-right">
              <button
                data-for="global-invoiceCard-copy"
                data-tip
                data-iscapture="true"
                onClick={this.copyBill}
                type="button"
                className="btn btn-square"
              >
                <i className="glyphicon icon-duplicate" />
              </button>
              {state === 'OPEN' ? (
                <button
                  data-for="global-invoiceCard-edit"
                  data-tip
                  data-iscapture="true"
                  onClick={this.props.onEdit}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-pencil" />
                </button>
              ) : (
                <button
                  data-for="global-invoiceCard-view"
                  data-tip
                  onClick={this.props.onEdit}
                  data-iscapture="true"
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-eye" />
                </button>
              )}
              {state === 'PENDING' ? (
                <button
                  data-for="global-invoiceCard-open"
                  data-tip
                  data-iscapture="true"
                  onClick={this.openModal.bind(this, 'open')}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-bell-dark" />
                </button>
              ) : null}
              {state === 'OPEN' ? (
                <button
                  data-for="global-invoiceCard-send"
                  data-tip
                  data-iscapture="true"
                  onClick={this.openModal.bind(this, 'aprove')}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-plane" />
                </button>
              ) : null}
              <button
                data-for="global-invoiceCard-print"
                data-tip
                data-iscapture="true"
                onClick={this.handlePrint}
                type="button"
                className="btn btn-square"
              >
                <i className="glyphicon icon-print" />
              </button>
              {state === 'OPEN' ? (
                <button
                  data-for="global-invoiceCard-delete"
                  data-tip
                  data-iscapture="true"
                  onClick={this.openModal.bind(this, 'delete')}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-trash" />
                </button>
              ) : null}
            </div>
          </li>
        </ul>
        {DeleteModal}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateBill: data => dispatch({ type: 'UPDATE_BILL', data }),
    deleteBill: data => dispatch({ type: 'DELETE_BILL', data }),
    openBill: data => dispatch({ type: 'OPEN_BILL', data }),
    duplicateBill: id => dispatch({ type: 'DUPLICATE_BILL', id }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)
