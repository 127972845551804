import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import ClientDetails from './ClientDetails'
import ReactTooltip from 'react-tooltip'
import './client.scss'
import { Modal, Button } from 'react-bootstrap'

class Client extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  saveCustomer = () => {
    const data = {
      personId: this.props.user.id,
      customerId: this.props.data.id,
    }
    this.props.saveCustomer(data)
  }

  deleteCustomer = () => {
    this.closeConfirm()
    const data = {
      personId: this.props.user.id,
      customerId: this.props.data.id,
    }
    this.props.deleteCustomer(data)
  }

  openModal = () => {
    this.setState({
      modalOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    })
  }

  confirmDelete = () => {
    this.setState({
      confirmModalOpen: true,
    })
  }
  closeConfirm = () => {
    this.setState({
      confirmModalOpen: false,
    })
  }

  render() {
    const { data, translate } = this.props

    const content = (
      <div className="client">
        <div className="field">
          <span className="field-name">{translate('customers.fields.name')}</span>
          <span className="field-text blue">{data.name || '-'}</span>
        </div>
        <div className="field">
          <span className="field-name">{translate('customers.fields.organization')}</span>
          <span className="field-text dkblue">{data.organization || '-'}</span>
        </div>
        <div className="field">
          <span className="field-name">{translate('customers.fields.businessid')}</span>
          <span className="field-text dkblue">{data.businessid || '-'}</span>
        </div>
        <div className="field">
          <span className="field-name">{translate('customers.fields.phonenumber')}</span>
          <span className="field-text dkblue">{data.phonenumber || '-'}</span>
        </div>
        <div className="field">
          <span className="field-name">{translate('customers.fields.email')}</span>
          <span className="field-text dark">{data.email || '-'}</span>
        </div>
        <div className="field">
          <span className="field-name">{translate('customers.fields.method')}</span>
          <span className="field-text dark">{data.sendBillMethod ? translate('customers.fields.sendBillMethod.' + data.sendBillMethod) : '-'}</span>
        </div>
        <div className="field control-buttons">
            <div className="btn-group">
              <button data-for="global-customer-view" data-tip data-iscapture="true" className="btn btn-square" onClick={this.openModal}>
                <i className="glyphicon icon-eye" />
              </button>
              {this.props.canBeAdded ? (
                <button
                  data-for="global-customer-save"
                  data-tip
                  data-iscapture="true"
                  type="button"
                  style={{ fontSize: '24px', padding: '0' }}
                  className="btn btn-square dkblue"
                  onClick={this.saveCustomer}
                >
                  +
                </button>
              ) : (
                <button data-for="global-customer-remove" data-tip data-iscapture="true" className="btn btn-square" onClick={this.confirmDelete}>
                  <i className="glyphicon icon-trash" />
                </button>
              )}
            </div>
        </div>
        <div>
          <Modal className="details" show={this.state.modalOpen} onHide={this.closeModal} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>{translate('customers.details.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalOpen && <ClientDetails data={data} />}</Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col-xs-6"></div>
                <div className="col-xs-6">
                  <Button variant="cancel" onClick={this.closeModal}>
                    {translate('misc.close')}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal className="confirm" show={!!this.state.confirmModalOpen} onHide={this.closeConfirm} animation={false}>
            <Modal.Header>
              <Modal.Title>{translate('customers.confirmDeleteTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="message">{translate('customers.confirmDeleteMessage')}</div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col-xs-6">
                  <Button variant="cancel" onClick={this.closeConfirm}>
                    {translate('misc.cancel')}
                  </Button>
                </div>
                <div className="col-xs-6">
                  <Button variant="danger" onClick={this.deleteCustomer}>
                    {translate('misc.confirm')}
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )

    return content
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveCustomer: (data) => dispatch({ type: 'SAVE_CUSTOMER', data }),
    deleteCustomer: (data) => dispatch({ type: 'DELETE_CUSTOMER', data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Client)
