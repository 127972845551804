import { getBill, removeBillableItem, validateBillFromApi } from './billApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'
import { browserHistory } from 'react-router'

export function getBillFromApi(data) {
  const billId = data.billId
  const user = getObject('user')
  return getBill(path(['authtoken'], user), path(['id'], user), billId)
    .then((response) => {
      return { response: response }
    })
    .catch((err) => {
      return { error: err }
    })
}

export function* tryGetBill(data) {
  const { response, error } = yield call(getBillFromApi, data)
  if (response) {
    yield put({ type: 'CALCULATE_FROM_BILL', data: response })
    yield put({ type: 'SELECT_BILL_SUCCESS', response })
  } else {
    yield put({ type: 'SELECT_BILL_FAILURE', error })
    browserHistory.push('/invoices')
  }
}

export function deleteBillableItemWithApi(data) {
  const user = getObject('user')
  return removeBillableItem(path(['authtoken'], user), data.data)
    .then(bill => {
      return { response: bill }
    })
    .catch(err => {
      return err
    })
}
export function validateBillAction(id) {
  const user = getObject('user')
  return validateBillFromApi(path(['authtoken'], user), id)
    .then(bill => {
      return { response: bill }
    })
    .catch(err => {
      return err
    })
}

export function* tryDeleteBillableItem(data) {
  const { response, error } = yield call(deleteBillableItemWithApi, data)
  if (response) {
    yield put({ type: 'DELETE_BILLABLE_ITEM_SUCCESS', response, data: data.data })
    yield put({ type: 'CALCULATE_FROM_BILL', data: data.data.bill })
  } else {
    yield put({ type: 'DELETE_BILLABLE_ITEM_FAILURE', error })
  }
}

export function* tryValidateBill(data) {
  const { response, error } = yield call(validateBillAction, data.id)
  if (response) {
    yield put({ type: 'VALIDATE_BILL_SUCCESS', response, data: data.data })
  } else {
    yield put({ type: 'VALIDATE_BILL_FAILURE', data: error.response.data })
  }
}

export function* selectAndFetchBill() {
  yield takeEvery('SELECT_BILL', tryGetBill)
}

export function* deleteBillableItem() {
  yield takeEvery('DELETE_BILLABLE_ITEM', tryDeleteBillableItem)
}

export function* validateBill() {
  yield takeEvery('VALIDATE_BILL', tryValidateBill)
}
