import { getCustomers } from './customersApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'

export function searchCustomersWithApi(data) {
  const user = getObject('user')
  let query = data.data || ''
  return getCustomers(path(['authtoken'], user), query)
    .then(user => {
      return { response: user }
    })
    .catch(err => {
      return err
    })
}

export function* trySearchCustomers(data) {
  const { response, error } = yield call(searchCustomersWithApi, data)
  if (response) {
    yield put({ type: 'FIND_CUSTOMERS_SUCCESS', response })
  } else {
    yield put({ type: 'FIND_CUSTOMERS_FAILURE', error })
  }
}

export function* findCustomers() {
  yield takeEvery('FIND_CUSTOMERS', trySearchCustomers)
}
