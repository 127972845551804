import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { currencyFormatter } from '../../../components/Utils/formHelpers'
import { isMobile } from '../../Utils/detectMobile'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'
import { confirmModal } from '../../Modal/ConfirmModal'
import Checkbox from '@material-ui/core/Checkbox'
import { dateToIsoDate } from '../../Utils/dateHelpers'
import ReactTooltip from 'react-tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Formik, Field } from 'formik'

import {
  createReceiptForItem,
  deleteReceiptAction,
  sendForPaymentAction,
  openItemAction,
  updateItemAction,
  deleteItemAction,
} from '../../../components/api/SubContractor/subContractorActions'

class ExtContractorInvoiceRow extends Component {
  defaultState() {
    return {
      duedate: '',
      name: '',
      amount: '',
      referenceNumber: '',
      accountNumber: '',
      newReceiptId: '',
      receipts: [],
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      error: {},
      deleteModalOpen: false,
      sendModalOpen: false,
      openModalOpen: false,
    }

    this.numberInputComponent = this.numberInputComponent.bind(this)
    this.textInputComponent = this.textInputComponent.bind(this)
    this.CustomCheckboxComponent = this.CustomCheckboxComponent.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  saveChanges = (values) => {
    this.props.updateItem(values, values.billId)
  }

  submitForm = (event) => {
    event.preventDefault()
  }

  debounce = (func, delay) => {
    let inDebounce
    return function () {
      const context = this
      const args = arguments
      clearTimeout(inDebounce)
      inDebounce = setTimeout(() => func.apply(context, args), delay)
    }
  }

  validate = (values) => {
    const errors = {}
    if (!values.receipts) {
      errors.receipts = 'validation.billRequired'
    }
    if (!values.name) {
      errors.name = 'validation.required'
    }
    if (!values.amount) {
      errors.amount = 'validation.required'
    }
    if (!values.referenceNumber) {
      errors.referenceNumber = 'validation.required'
    }
    if (!values.accountNumber) {
      errors.accountNumber = 'validation.required'
    }
    if (!values.duedate) {
      errors.duedate = 'validation.required'
    }
    return errors
  }

  handleChangeAmount = (e) => {
    const error = this.state.error
    error[e.target['name']] = null
    this.setState({
      error: {
        ...error,
      },
    })
  }

  handleChange = (e) => {
    const error = this.state.error
    error[e.target['name']] = null
    const row = {
      ...this.props.row,
      updated: this.props.row.updated++,
      [e.target['name']]: e.target.value,
    }
    this.setState({
      error: {
        ...error,
      },
      row: row,
    })
  }

  /*
    handleChangeMobileDate = (e) => {
      const { error: { duedate, ...error } } = this.state
      this.setState({
        error: {
          ...error
        },
        row: {
          ...this.props.row,
          duedate: isoDateToDate(e.target.value)
        }
      })
      this.submitRow()
    }
  
    handleChangeDesktopDate = (e) => {
      const { error: { duedate, ...error } } = this.state
      this.setState({
        error: {
          ...error
        },
        row: {
          ...this.props.row,
          updated: this.props.row.updated++,
          duedate: e.format('DD.MM.YYYY')
        }
      })
      this.submitRow()
    }
    */

  getDateComponent = (disabled) => {
    const { translate, locale } = this.props
    if (!isMobile()) {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
            <DayPickerInput
              value={this.props.row.duedate}
              dayPickerProps={{ localeUtils: LocaleUtils, locale }}
              readOnly={true}
              disabled={disabled}
              style={{ display: 'block' }}
              className="form-control"
              onDayChange={this.handleChangeDesktopDate}
              placeholder="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div>
            <input
              disabled={disabled}
              placeholder={translate('subcontracting.date')}
              className="form-control input-lg"
              name="date"
              type="date"
              value={dateToIsoDate(this.props.row.duedate)}
              onChange={this.handleChangeMobileDate}
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      )
    }
  }

  toggleModal = (modal, opened) => {
    this.setState({ [modal]: opened })
  }

  getButtonsByState = () => {
    const { translate, bill, row } = this.props
    const disabled = !row.canBeEdited || bill.state !== 'OPEN'
    let buttons = []
    if (!disabled && row.state === 'OPEN') {
      buttons = [
        ...buttons,
        <button
          type="button"
          key="send-button"
          disabled={disabled && row.state === 'OPEN'}
          className="delete btn btn-square dkblue"
          onClick={() => {
            this.toggleModal('sendModalOpen', true)
          }}
        >
          {translate('subcontracting.sendToPayment')}
          <i className="glyphicon no-border icon-plane"></i>
        </button>,
      ]
      buttons = [
        ...buttons,
        <button
          type="button"
          key="delete-button"
          disabled={disabled && row.state === 'OPEN'}
          className="delete btn btn-square dkblue"
          onClick={() => {
            this.toggleModal('deleteModalOpen', true)
          }}
        >
          {translate('invoice.row.deleteR')}
          <i className="glyphicon no-border icon-trash"></i>
        </button>,
      ]
    }
    if (row.state === 'WAITING_FOR_PAYMENT' || row.state === 'PAY_NOW') {
      buttons = [
        ...buttons,
        <button
          type="button"
          key="open-button"
          disabled={bill.state !== 'OPEN'}
          className="delete btn btn-square dkblue"
          onClick={() => {
            this.toggleModal('openModalOpen', true)
          }}
        >
          {translate('subcontracting.openRow')}
          <i className="glyphicon no-border icon-plane"></i>
        </button>,
      ]
    }
    return buttons
  }

  handleDeleteReceipt = (e) => {
    this.props.deleteReceipt(e.id, this.props.bill.id, this.props.row.id)
  }

  handleSend = (e) => {
    this.props.sendForPayment(this.props.row.id, this.props.bill.id)
    this.toggleModal('sendModalOpen', false)
  }

  handleOpen = (e) => {
    this.props.openItem(this.props.row.id, this.props.bill.id)
    this.toggleModal('openModalOpen', false)
  }

  handleDelete = (e) => {
    this.toggleModal('deleteModalOpen', false)
    this.props.deleteItem(this.props.row.id, this.props.bill.id)
  }
  onUploadProgress = (progress) => {
    // uploadProgress(progress)
    this.setState({
      ...this.state,
      ...progress,
    })
  }

  onReceiptAdd = (e) => {
    const file = e.target.files[0]
    this.setState({
      filename: file.name,
      progress: 0,
      done: false,
    })
    file &&
      this.props.uploadReceipt(
        {
          image: file,
          id: this.props.bill.id,
        },
        this.props.row.id,
        this.onUploadProgress
      )
    e.target.value = null
  }

  getReceipts = () => {
    const { translate, user } = this.props
    if (this.props.row && this.props.row.receipts && this.props.row.receipts.length > 0) {
      const disabled = this.props.row.receipts.length < 2
      const receipts = this.props.row.receipts.map((receipt) => {
        return (
          <tr key={receipt.id}>
            <td>{receipt.date}</td>
            <td>
              <a href={`/api/extcontractoritems/receipt/${receipt.id}?authtoken=${user.authtoken}`}>{receipt.displayname}</a>
            </td>
            <td>
              <button
                style={{ maxWidth: '100px', height: '30px' }}
                type="button"
                className="pull-right btn btn-warning"
                disabled={disabled}
                onClick={this.handleDeleteReceipt.bind(this, receipt)}
              >
                {translate('misc.delete')}
              </button>
            </td>
          </tr>
        )
      })
      return (
        <div className="receipts-container dkblue">
          <table width="100%">
            <thead>
              <tr>
                <td>{translate('misc.date')}</td>
                <td>{translate('misc.file')}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>{receipts}</tbody>
          </table>
        </div>
      )
    }
    return null
  }

  toggleState = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  textInputComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    handleSubmit,
    disabled,
    form,
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input
          {...field}
          type="text"
          disabled={disabled}
          className="form-control input-lg"
          onBlur={(event) => this.handleBlur(event, form)}
          value={field.value}
        />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )

  handleBlur = (event, form) => {
    form.handleSubmit()
  }

  numberInputComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    disabled,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const changeValue = (event) => {
      let val = event.currentTarget.value.replace(/[^0-9.,]/g, '').replace(',', '.')
      if (!isNaN(val)) {
        setFieldValue(field.name, val.replace('.', ','))
      }
    }
    const val = field.value ? `${field.value}`.replace('.', ',') : field.value
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <input
            {...field}
            type="text"
            disabled={disabled}
            className="form-control input-lg"
            onChange={changeValue}
            value={val}
            onBlur={(event) => this.handleBlur(event, form)}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  CustomCheckboxComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    form,
    checkboxLabel,
    disabled,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const changeInput = (event, form) => {
      setFieldValue(field.name, event.currentTarget.checked)
      this.saveChanges({
        ...form.values,
        [field.name]: event.currentTarget.checked,
      })
    }
    return (
      <div className="form-group">
        <label>{label}</label>
        <div className="nomargin form-control">
          <FormControlLabel
            control={
              <Checkbox
                className="scaled"
                id={field.name}
                checked={!!field.value}
                disabled={disabled}
                onChange={(event) => changeInput(event, form)}
                value={!!field.value}
              />
            }
            label={checkboxLabel}
          />
          <label htmlFor={field.name}> </label>
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  render() {
    const { translate, bill, row } = this.props
    const stateString = translate('subcontracting.' + row.state)
    const description = `${currencyFormatter(row.amount)} €, ${row.name}, ${stateString} `
    const disabled = !row.canBeEdited || bill.state !== 'OPEN'
    const dateInput = this.getDateComponent(disabled)
    const buttons = this.getButtonsByState()

    // modals
    const messageForDeleteRow = translate('subcontracting.messageForDeleteBillableItem')
    const DeleteModal = confirmModal(
      this.state.deleteModalOpen,
      messageForDeleteRow,
      translate,
      () => {
        this.toggleModal('deleteModalOpen', false)
      },
      this.handleDelete
    )

    const messageForSendRow = translate('subcontracting.messageForSendExtItem')
    const sendModal = confirmModal(
      this.state.sendModalOpen,
      messageForSendRow,
      translate,
      () => {
        this.toggleModal('sendModalOpen', false)
      },
      this.handleSend
    )

    const messageForOpenRow = translate('subcontracting.messageForOpenRow')
    const openModal = confirmModal(
      this.state.openModalOpen,
      messageForOpenRow,
      translate,
      () => {
        this.toggleModal('openModalOpen', false)
      },
      this.handleOpen
    )

    const receiptTable = this.getReceipts()
    let initialValues = row
    return (
      <Formik initialValues={initialValues} validate={this.validate} onSubmit={this.saveChanges}>
        {({
          handleChange,
          handleSubmit,
          /* and other goodies */
        }) => (
          <div className="row col-xs-12">
            <div className={this.state.collapsed ? 'invoice-row collapsed' : 'invoice-row'}>
              <div className="title noselect" onClick={this.toggleState.bind(this)}>
                <div>
                  <span>{description}</span>
                </div>
                <div style={{ width: '40px' }}>
                  <i className="chevron" />
                </div>
              </div>
              <div className="invoice-row-body ext-item">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="name"
                        label={translate('subcontracting.name')}
                        translate={translate}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        component={this.textInputComponent}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">{dateInput}</div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="amount"
                        label={translate('subcontracting.fullprice')}
                        translate={translate}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        component={this.numberInputComponent}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="referenceNumber"
                        label={translate('subcontracting.referenceNumber')}
                        translate={translate}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        component={this.textInputComponent}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="accountNumber"
                        label={translate('subcontracting.accountNumber')}
                        translate={translate}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        component={this.textInputComponent}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="paynow"
                        label={translate('subcontracting.payNow')}
                        checkboxLabel={translate('subcontracting.payNowShort')}
                        translate={translate}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        component={this.CustomCheckboxComponent}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="row medium-margin">
                    <div className="col-xs-12 col-sm-6">
                      {receiptTable}
                      {this.state.progress && this.state.done === false && (
                        <div className="progress-containter">
                          <div className="progress-outer">
                            <div className="progress-inner" style={{ width: this.state.progress + '%' }}></div>
                            <div className="progress-text">
                              {this.state.fileName} <span className="progress-value">{this.state.progress}%</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div
                        disabled={disabled}
                        className="btn btn-success white div-button"
                        style={{
                          margin: '0px',
                        }}
                      >
                        <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                          <div className="glyphicon icon-plus"></div>
                          {translate('subcontracting.addBill')}
                          <input
                            onChange={this.onReceiptAdd}
                            type="file"
                            disabled={disabled}
                            className="file-input"
                            style={{
                              position: 'absolute',
                              padding: '0px',
                              top: '0px',
                              left: '0px',
                              width: '100%',
                              height: '100%',
                              opacity: 0,
                              cursor: disabled ? 'not-allowed' : 'pointer',
                            }}
                          />
                          {this.state.error['receipts'] && (
                            <span className="field-error">{translate('validation.' + this.state.error['receipts'])}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row medium-margin">
                    <div className="pull-right">{buttons}</div>
                  </div>
                  <button type="submit" hidden></button>
                </form>
              </div>
            </div>
            {DeleteModal}
            {sendModal}
            {openModal}
          </div>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    user: state.user,
    bill: state.bill.selectedBill,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadReceipt: (data, extItemId, progressCallback) => dispatch(createReceiptForItem(data, extItemId, progressCallback)),
    deleteReceipt: (receiptId, billId, extId) => dispatch(deleteReceiptAction(receiptId, billId, extId)),
    sendForPayment: (extId, billId) => dispatch(sendForPaymentAction(extId, billId)),
    openItem: (extId, billId) => dispatch(openItemAction(extId, billId)),
    updateItem: (item, billId) => dispatch(updateItemAction(item, billId)),
    deleteItem: (extId, billId) => dispatch(deleteItemAction(extId, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtContractorInvoiceRow)
