import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import './templates.scss'
import Checkbox from '@material-ui/core/Checkbox'

class Templates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    this.props.getTemplates()
  }

  onDelete = () => {
    this.props.deleteTemplate(this.state.template)
    this.closeModal(true)
  }

  onChange = (template, e) => {
    ReactTooltip.rebuild()
    template.showInResult = !!e.target.checked
    this.props.updateTemplate(template)
  }

  renderTemplates() {
    const { templates } = this.props
    const templatesList = templates && templates.list ? templates.list : []
    return templatesList.map((template, key) => {
      return this.renderTemplate(template, key)
    })
  }

  openModal = (template) => {
    this.setState({ modalOpen: true, template })
  }

  closeModal = (e) => {
    this.setState({ modalOpen: false, template: null })
  }

  renderTemplate(template, key) {
    const checkbox = 'checkbox_' + key
    return (
      <div className="table-row" key={key}>
        <div className="field middle">
          <Checkbox
            className="scaled"
            onChange={this.onChange.bind(this, template)}
            checked={template.showInResult}
            id={checkbox}
            value={template.showInResult}
          />
        </div>
        <div className="field">
          <span>{template.text}</span>
        </div>
        <div className="field middle">
          <button
            data-for="global-delete"
            data-tip
            data-iscapture="true"
            className="pull-right btn btn-square"
            onClick={this.openModal.bind(this, template)}
          >
            <i className="glyphicon icon-trash" />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { translate } = this.props
    const templates = this.renderTemplates()
    return (
      <div className="row">
        <div className="dashboard templates">
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              {translate('templates.title')}
            </div>

            <div className="description">{translate('templates.description')}</div>
            <div className="row">
              <div className="table">
                <div className="table-header">
                  <div className="field">{translate('templates.active')}</div>
                  <div className="field">{translate('templates.name')}</div>
                  <div className="field"></div>
                </div>
                {templates}
              </div>
            </div>
          </div>
        </div>
        <Modal show={!!this.state.modalOpen} onHide={this.closeModal} backdrop={'static'} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('templates.confirmDeleteTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="message">{translate('templates.confirmDeleteMessage')}</div>
          </Modal.Body>

          <Modal.Footer>
            <div className="row">
              <div className="col-xs-6">
                <Button variant="cancel" onClick={this.closeModal}>
                  {translate('misc.cancel')}
                </Button>
              </div>
              <div className="col-xs-6">
                <Button variant="danger" onClick={this.onDelete}>
                  {translate('misc.confirm')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    templates: state.templates || {},
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTemplates: (value) => dispatch({ type: 'GET_TEMPLATES' }),
    updateTemplate: (data) => dispatch({ type: 'UPDATE_TEMPLATE', data }),
    deleteTemplate: (data) => dispatch({ type: 'DELETE_TEMPLATE', data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
