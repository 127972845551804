
export const USER_REGISTER_REQUESTED = 'USER_REGISTER_REQUESTED'
export const GET_USER_BY_TOKEN = 'GET_USER_BY_TOKEN'
export const EULA_AGREED = 'EULA_AGREED'
export const BANK_CONFIRMED = 'BANK_CONFIRMED'

export const getUserByToken = token => ({
  type: GET_USER_BY_TOKEN,
  token: token
})

export const agreeEula = token => ({
  type: EULA_AGREED,
  user: token
})

export const bankConfirmed = code => ({
  type: BANK_CONFIRMED,
  code: code
})
