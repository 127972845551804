import approveEulaFromBank from './components/approveEulaFromBank'
import registrationFailedView from './components/registrationFailedView'
import registerLayout from './components/registerLayout'
import registrationSuccessView from './agreed/registrationSuccessView'
import registrationConfirmBankView from './components/registrationConfirmBankView'

import React from 'react'
import {Route} from 'react-router'

const register = (
  <Route path="registration" component={registerLayout}>
    <Route path="failed" component={registrationFailedView}></Route>
    <Route path="confirm" component={registrationConfirmBankView}></Route>
    <Route path="eula" component={approveEulaFromBank}></Route>
    <Route path="success" component={registrationSuccessView}></Route>
  </Route>
)

export default register
