import axios from 'axios'
import { browserHistory } from 'react-router'

export function apiCall(url, method, token, body, callback) {
  const gettingEncodedImage = method === 'get' && /api\/person\/.*\/profilepic/.test(url)
  const sendingFile = body && typeof body.name === 'string' &&
    typeof body.size === 'number' &&
    typeof body.lastModified === 'number'
  const options = {
    method: method,
    url: url,
    headers: {
      'Content-Type': sendingFile ? '' : 'application/json',
      'authtoken': token
    },
    responseType: gettingEncodedImage ? 'arraybuffer' : 'json'
  }
  if (body) {
    if (sendingFile) {
      options.data = new FormData()
      options.data.append('file', body, body.name)
      options.onUploadProgress = (progressEvent) => {
        callback && callback({
          progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          file: {},
          done: false
        })
      }

    } else {
      options.data = JSON.stringify(body)
    }
  }
  return axios(options)
    .then(response => {
      if (gettingEncodedImage) {
        const image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`
      }
      if (response.status === 204) {
        return { response: 'ok' }
      }
      if (sendingFile) {
        callback && callback({
          progress: 0,
          done: true,
          file: response.data
        })
      } else {
        callback && callback({
          success: response
        })
      }
      return response.data
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        browserHistory.push('/logout')
      }
      if (!sendingFile) {
        callback && callback(error.response.data)
      }
      return Promise.reject({ error: error })
    })
}
