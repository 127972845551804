import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { resetPassword } from '../passwordActions'

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = { password: '' }
    this.resetPasswordSubmit = this.resetPasswordSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  resetPasswordSubmit(e) {
    e.preventDefault()
    const {
      routeParams: { token },
    } = this.props
    this.props.resetUserPassword({
      registrationToken: token,
      password: this.state.password,
    })
  }

  handleChange(event) {
    this.setState({ password: event.target.value })
  }

  render() {
    const { translate } = this.props
    return (
      <div className="row">
        <h1>{translate('password.passwordChange.head')}</h1>
        <p className="lead">{translate('password.passwordChange.body')}</p>
        <div className="col-xs-12 col-sm-10 col-md-8">
          <form onSubmit={this.resetPasswordSubmit}>
            <div className="form-group">
              <label>{translate('password.passwordChange.newPassword')}</label>
              <div>
                <input
                  placeholder="Uusi salasana"
                  className="form-control input-lg"
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </div>
            </div>
            <button className="btn btn-primary btn-large">
              {translate('password.passwordChange.button')}
            </button>
          </form>
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetUserPassword: user => dispatch(resetPassword(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
