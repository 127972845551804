import { SEND_NOTIFICATION } from './notificationActions'

const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION: {
      let type = action.response.type
      if (type !== 'success' && type !== 'warning' && type !== 'error') {
        type = 'info'
      }
      return {
        ...state,
        message: action.response.message,
        title: action.response.title,
        duration: action.response.duration || 6000,
        type,
      }
    }
    default:
      return state
  }
}

export default notificationReducer
