import React, { Component } from 'react'
import { getTranslate, getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import './Footer.scss'

class Footer extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.locale !== this.props.locale
  }

  render() {
    const { translate } = this.props
    const content = (
      <div className="footer">
        <div className="container-fluid">
          <ul className="footer-left">
            <li><Link to='#'>Ninjou Oy</Link></li>
          </ul>
          <div>
            <ul className="footer-right">
            <li><Link to='/eula'>{translate('footer.eula')}</Link></li>
              <li><Link to='/privacy'>{translate('footer.privacy')}</Link></li>
              <li><a href='https://ninjou.fi'>{translate('footer.about')}</a></li>
            </ul>
          </div>
        </div>
      </div>
    )
    return (
      <div>
        {content}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: getActiveLanguage(state.locale).code,
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
