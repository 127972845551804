import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from '../../Utils/detectMobile'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils from 'react-day-picker/moment'

import { Button, Modal } from 'react-bootstrap'
import { dateToIsoDate } from '../../Utils/dateHelpers'
import ReactTooltip from 'react-tooltip'
import { Field, Formik } from 'formik'
import moment from 'moment'
import Select from 'react-select'
import { find, propEq } from 'ramda'
import { numberInputComponent } from '../../FormComponents/NumerInput'

import 'react-day-picker/lib/style.css'

import { createSubcontracorAction, searchSubcontractors } from '../../../components/api/SubContractor/subContractorActions'
import { reactSelectStyles } from '../../../styles/ReactSelectStyles'

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input type="text" className="form-control input-lg" value={field.value} {...field} {...props} />
      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  </div>
)

const CustomDateComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  locale,
  disabled,
  handleChange,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <DayPickerInput
        value={field.value}
        dayPickerProps={{ localeUtils: LocaleUtils, locale }}
        readOnly={true}
        disabled={disabled}
        className="form-control"
        onDayChange={
          disabled
            ? null
            : (day) => {
                setFieldValue(field.name, moment(day).format('DD.MM.YYYY'))
              }
        }
        placeholder="DD.MM.YYYY"
        format="DD.MM.YYYY"
      />
    </div>
    {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
  </div>
)

const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

class SubcontratorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      error: [],
      subcontractorBillableItem: {},
    }
    this.onInputKeyDown = debounce((key) => {
      this.searchSubContractor(key)
    }, 200).bind(this)

    this.validateFn = this.validateFn.bind(this)
    this.submitValues = this.submitValues.bind(this)
    this.subcontractorSelectComponent = this.subcontractorSelectComponent.bind(this)
    this.searchSubContractor = this.searchSubContractor.bind(this)
    this.setSubcontractor = this.setSubcontractor.bind(this)
  }

  validateFn = (values) => {
    const errors = {}
    if (!values.description) {
      errors.description = 'validation.required'
    }
    if (!values.date) {
      errors.date = 'validation.required'
    }
    if (!values.amount) {
      errors.amount = 'validation.required'
    }
    if (!(values.subcontractor && values.subcontractor.id)) {
      errors.subcontractor = 'validation.required'
    }
    return errors
  }

  searchSubContractor = (key) => {
    this.props.searchSubcontractorList(key)
    if ((key && key.length > 1) || key.length === 0) {
      this.setState({
        customers: [],
      })
    }
  }

  setSubcontractor(e, setFieldValue) {
    if (e.value) {
      const sub = find(propEq('id', e.value))(this.props.subcontractors)
      setFieldValue('subcontractor', sub)
    } else {
      console.log('e not found', e)
    }
  }

  subcontractorSelectComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    disabled,
    subcontractorsList,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const currentVal = {
      label: field.value.email || '',
      value: field.value.id || '',
    }
    return (
      <div className="form-group">
        <label className="dkblue">{translate('subcontracting.subcontractor')}</label>
        <div data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
          <Select
            disabled={disabled}
            options={subcontractorsList}
            value={currentVal}
            placeholder={translate('misc.select')}
            inputProps={{ className: 'form-control22' }}
            clearable={false}
            autoload={false}
            onInputChange={this.onInputKeyDown}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            onCloseResetsInput={false}
            filterOptions={(options, filter, currentValues) => {
              // Do no filtering, just return all options
              return options
            }}
            onChange={(event) => this.setSubcontractor(event, setFieldValue)}
            styles={reactSelectStyles}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  submitValues = (values) => {
    const { bill, createSubcontractorItem, closeModal } = this.props
    createSubcontractorItem(values, bill.id)
    closeModal()
  }

  getDateComponent(disabled) {
    const { translate, locale } = this.props
    if (!isMobile()) {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
            <DayPickerInput
              value={this.state.subcontractorBillableItem.date}
              dayPickerProps={{ localeUtils: LocaleUtils, locale }}
              readOnly={true}
              disabled={disabled}
              style={{ display: 'block' }}
              className="form-control"
              onDayChange={this.handleChangeDesktopDate}
              placeholder="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div>
            <input
              disabled={disabled}
              placeholder={translate('subcontracting.date')}
              className="form-control input-lg"
              name="date"
              type="date"
              value={dateToIsoDate(this.state.subcontractorBillableItem.date)}
              onChange={this.handleChangeMobileDate}
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      )
    }
  }
  render() {
    const { translate, show, closeModal, locale, subcontractors, disabled } = this.props
    const subcontractorsList = subcontractors.map((subcontractor) => {
      return {
        label: subcontractor.email,
        value: subcontractor.id,
      }
    })
    return (
      <div className="row">
        <Modal show={show} onHide={closeModal} backdrop={'static'} dialogClassName="modal-90w" animation={false}>
          <Formik
            initialValues={{
              description: '',
              amount: 0,
              date: '',
              subcontractor: {},
            }}
            validate={this.validateFn}
            onSubmit={this.submitValues}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              validateForm,
              /* and other goodies */
            }) => (
              <div className="valign-helper">
                <Modal.Header closeButton>
                  <Modal.Title>{translate('subcontracting.Modal.Title')}</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <Field
                          name="subcontractor"
                          label={translate('subcontracting.subcontractor')}
                          translate={translate}
                          locale={locale}
                          disabled={disabled}
                          subcontractorsList={subcontractorsList}
                          component={this.subcontractorSelectComponent}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <Field
                          name="date"
                          label={translate('subcontracting.date')}
                          translate={translate}
                          locale={locale}
                          disabled={disabled}
                          component={CustomDateComponent}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <Field
                          name="amount"
                          label={translate('subcontracting.price')}
                          translate={translate}
                          locale={locale}
                          disabled={disabled}
                          component={numberInputComponent}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12">
                        <Field
                          name="description"
                          label={translate('subcontracting.description')}
                          translate={translate}
                          locale={locale}
                          disabled={disabled}
                          component={CustomInputComponent}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row">
                      <div className="col-xs-6">
                        <Button variant="cancel" onClick={closeModal}>
                          {translate('misc.cancel')}
                        </Button>
                      </div>
                      <div className="col-xs-6">
                        <Button variant="success" type="submit">
                          {translate('subcontracting.addRow')}
                        </Button>
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </div>
            )}
          </Formik>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedBill: state.bill.selectedBill,
    subcontractors: state.subcontractor.list,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchSubcontractorList: (data) => dispatch(searchSubcontractors(data)),
    createSubcontractorItem: (item, billId) => dispatch(createSubcontracorAction(item, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubcontratorModal)
