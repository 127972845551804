import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTranslate} from 'react-localize-redux'
import {startResetPassword} from '../passwordActions'

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '' }
  }

  resetPasswordSubmit(e) {
    e.preventDefault()
    this.props.startResetPassword({})
  }

  handleChange(event) {
    this.setState({ email: event.target.value })
  }

  render() {
    const { translate } = this.props
    return (
      <div className="row">
        <h1>{translate('password.reset.head')}</h1>
        <p className="lead">{translate('password.reset.body')}</p>
        <div className="col-xs-12 col-sm-10 col-md-8">
          <form onSubmit={this.resetPasswordSubmit.bind(this)}>
            <button className="btn btn-primary btn-large">
              {translate('password.reset.button')}
            </button>
          </form>
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startResetPassword: user => dispatch(startResetPassword(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
