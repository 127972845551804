import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import '../../components/Card/Card.scss'
import { currencyFormatter } from '../Utils/formHelpers'
import {
  approveSubcontracorAction,
  declineSubcontracorAction,
} from '../api/SubContractor/subContractorActions'

import ReactTooltip from 'react-tooltip'

class ContractorRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: null,
      declineModal: null,
    }
    this.openApproveModal = this.openApproveModal.bind(this)
    this.closeApproveModal = this.closeApproveModal.bind(this)
    this.openDeclineModal = this.openDeclineModal.bind(this)
    this.closeDeclineModal = this.closeDeclineModal.bind(this)
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  openApproveModal() {
    this.setState({
      modal: true,
    })
  }

  closeApproveModal = () => {
    this.setState({
      modal: null,
    })
  }

  openDeclineModal() {
    this.setState({
      declineModal: true,
    })
  }
  closeDeclineModal = () => {
    this.setState({
      declineModal: null,
    })
  }

  approveItem = () => {
    this.props.approveSubcontractorItem(this.props.item)
    this.closeApproveModal()
  }

  declineItem = () => {
    this.props.declineSubcontractorItem(this.props.item)
    this.closeDeclineModal()
  }

  getClassNameForState(data) {
    const state = data.state
    let className = ''
    if (state === 'OPEN') {
      className = 'title green'
    } else if (state === 'CLOSED') {
      className = 'title yellow'
    } else if (state === 'PAID') {
      className = 'title green'
    } else {
      className = 'title'
    }
    return className
  }

  render() {
    const { item, translate } = this.props
    return (
      <div className="ext-card dkblue">
        <div className="card-header">
          <span className="title">{item.name}</span>
          <span className="subtitle">{item.duedate}</span>
        </div>
        <ul>
          <li>
            <span className="title green">
              {item.paid === true
                ? translate('subcontracting.PAID')
                : translate('subcontracting.' + item.state)}
            </span>
            <span className="text">Status</span>
          </li>
          <li>
            <span className="title">{item.name}</span>
            <span className="text">{translate('subcontracting.name')}</span>
          </li>
          <li>
            <span className="title">{currencyFormatter(item.amount)} €</span>
            <span className="text">{translate('subcontracting.fullprice')}</span>
          </li>
          <li>
            <span className="title">{item.accountNumber}</span>
            <span className="text">{translate('subcontracting.accountNumber')}</span>
          </li>
          <li>
            <span className="title">{item.referenceNumber}</span>
            <span className="text">{translate('subcontracting.referenceNumber')}</span>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    approveSubcontractorItem: item => dispatch(approveSubcontracorAction(item)),
    declineSubcontractorItem: item => dispatch(declineSubcontracorAction(item)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorRow)
