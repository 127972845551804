import React from 'react'
import { reactSelectStyles } from '../../styles/ReactSelectStyles'
import AsyncSelect from 'react-select/async'
import { Button, Modal } from 'react-bootstrap'
import Select from 'react-select'

export const CustomWaypointComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  disabled,
  label,
  handleChange,
  disabledSearch,
  values,
  self,
  arrayHelpers,
  index,
  form,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const isOpenStr = `menuOpen${field.name}`
  const isLoadingField = `loading_${field.name}`
  const isOpen = self.state?.[isOpenStr] || false
  const onRouteChange = async (value, field, setFieldValue, values, form) => {
    // field.value = value.value
    if (value) {
      await setFieldValue(field.name, value.value)
      self.setKilometers(field, value, values, setFieldValue)
      self.setState({
        ...self.state,
        [isOpenStr]: false,
      })
    }
  }
  const onInputKeyDown = (e) => {
    if (!disabledSearch) {
      self.searchAddress(e, field)
    }
  }
  const fieldNameForRoutes = `${field.name}Routes`
  const selectedRoute = self.state[fieldNameForRoutes]
  let routes =
    selectedRoute?.map((route) => {
      return {
        value: route,
        label: route,
      }
    }) || []

  // eslint-disable-next-line
  const includes = routes.find((val) => val.value === field.value)
  if (!includes && !!field.value) {
    routes.push({
      value: field.value,
      label: field.value,
    })
  }

  const loadOptions = (inputString, callback) => {
    self.setState({
      [isLoadingField]: true,
    })
    return new Promise(async (resolve) => {
      const addressesFn = (addresses) => {
        const values = addresses?.map((address) => {
          return {
            label: address,
            value: address,
          }
        })
        self.setState({
          [isLoadingField]: false,
        })
        resolve(values)
      }
      self.searchAddress(inputString, field, addressesFn)
    })
  }

  const selectedValue = routes?.find((val) => val.value === field.value)
  const toggleMenu = (boolean) => {
    self.setState({
      ...self.state,
      [isOpenStr]: boolean,
    })
  }
  const isLoading = !!self.state[isLoadingField]
  return (
    <div className="col-xs-12 form-group waypoint-item-container">
      <label>{label}</label>
      <div className="waypoint-item">
        <div className="waypoint-button-container">
          <Button
            className="remove-waypoint"
            type="button"
            onClick={() => self.removeWaypoint(arrayHelpers, index, values, setFieldValue)} // remove a friend from the list
          ></Button>
        </div>
        <div className="width25">
          <Button className="§col-xs-6 col-sm-3" onClick={() => self.fetchAddressFromCoordinates(setFieldValue, field.name, values)}>
            Hae
          </Button>
        </div>
        <div className="waypoint-input">
          <AsyncSelect
            menuIsOpen={isOpen}
            isLoading={isLoading}
            loadingMessage={() => translate('misc.search.loading')}
            noOptionsMessage={() => translate('misc.search.noOptions')}
            onFocus={() => toggleMenu(true)}
            onBlur={() => toggleMenu(false)}
            disabled={disabled}
            isSearchable={!disabledSearch}
            defaultOptions={routes}
            value={selectedValue}
            loadOptions={loadOptions}
            placeholder={translate('misc.select')}
            inputProps={{ className: 'form-control22' }}
            clearable={false}
            autoload={false}
            onInputChange={(value) => onInputKeyDown(value, field)}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            onCloseResetsInput={false}
            filterOptions={(options, filter, currentValues) => {
              // Do no filtering, just return all options
              return options
            }}
            onChange={(value) => onRouteChange(value, field, setFieldValue, values, form)}
            styles={reactSelectStyles}
          />
        </div>
      </div>

      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  )
}
