import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { EulaFinnish } from './Eula'

class EulaView extends Component {
  renderFinnishEula() {
    return (
      <div className="row">
        <div className="col-md-10 col-md-offset-1">{EulaFinnish()}</div>
      </div>
    )
  }

  renderEnglishEula() {
    return this.renderFinnishEula()
  }

  render() {
    const { locale } = this.props
    const selectedLang = locale.languages.find(a => a.active === true)
    let eula = null
    if (selectedLang.code === 'fi') {
      eula = this.renderFinnishEula()
    } else {
      eula = this.renderEnglishEula()
    }
    return <div>{eula}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.register.user,
    translate: getTranslate(state.locale),
    locale: state.locale,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EulaView)
