import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUserByToken } from '../registerActions'
import './register.scss'

class RegisterView extends Component {
  componentDidMount() {}

  render() {
    const { translate } = this.props
    return (
      <div className="row">
        <h1>{translate('register.successfull')}</h1>
        <div>{translate('register.successfullBody')}</div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUser: token => dispatch(getUserByToken(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView)
