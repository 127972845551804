import { GET_BILLS, CREATE_BILL, GET_ARCHIVE, ADD_ARCHIVE_PAGE } from './billsActions'

import { UPDATE_BILL } from '../Bill/billActions'

import { uniq } from 'ramda'

const defaultState = {
  archive: {
    bills: [],
    contractorBillableItems: [],
    extContractorBillableItems: [],
  },
  archivePage: 0,
}
const billsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_BILLS + '_SUCCESS':
      return {
        ...state,
        list: action.response,
      }
    case GET_BILLS + '_FAILURE':
      return {
        ...state,
        list: [],
      }
    case CREATE_BILL + '_SUCCESS':
      return {
        ...state,
        lastUpdatedBill: action.response,
      }
    case CREATE_BILL + '_FAILURE':
      return {
        ...state,
        lastUpdatedBill: {},
      }

    case GET_ARCHIVE + '_SUCCESS':
      return {
        ...state,
        archive: {
          bills: uniq([...state.archive.bills, ...action.response.bills]),
          contractorBillableItems: uniq([
            ...state.archive.contractorBillableItems,
            ...action.response.contractorBillableItems,
          ]),
          extContractorBillableItems: uniq([
            ...state.archive.extContractorBillableItems,
            ...action.response.extContractorBillableItems,
          ]),
        },
      }
    case GET_ARCHIVE + '_FAILURE':
      return {
        ...state,
        archive: {},
      }
    case ADD_ARCHIVE_PAGE:
      return {
        ...state,
        archivePage: state.archivePage + 1,
      }
    case UPDATE_BILL + '_SUCCESS':
      return {
        ...state,
        list: state.list.map(bill => {
          if (bill.id === action.response.id) {
            return action.response
          }
          return bill
        }),
      }
    default:
      return state
  }
}

export default billsReducer
