const mobile = require('is-mobile');


export function isMobile() {
  return mobile()
}

export function isIpad() {
  const testExp = new RegExp('iPad')
  return testExp.test(navigator.userAgent)
}
