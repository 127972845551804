import React from 'react'
import { Link } from 'react-router'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import '../../components/BillEditor/editor.scss'
import { Field, Formik } from 'formik'

const bicValidator = require('bic-validator')

const validateIbanChecksum = (iban) => {
  if (!iban) {
    return true
  }
  const ibani = iban || ''
  const ibanStripped = ibani
    .replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
    .toUpperCase() //calculation expects upper-case
  const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)
  if (!m) return false

  const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch) {
    //replace upper-case characters by numbers 10 to 35
    return ch.charCodeAt(0) - 55
  })
  //The resulting number would be to long for javascript to handle without loosing precision.
  //So the trick is to chop the string up in smaller parts.
  const mod97 = numbericed.match(/\d{1,7}/g).reduce(function (total, curr) {
    return Number(total + curr) % 97
  }, '')

  return mod97 === 1
}

const validateFn = (values) => {
  let errors = {}
  if (!values.firstName) {
    errors.firstName = 'validation.required'
  }

  if (!validateIbanChecksum(values.iban)) {
    errors.iban = 'account.invalid-iban'
  }
  if (!bicValidator.isValid(values.bic)) {
    errors.bic = 'account.invalid-bic'
  }
  if (!values.lastName) {
    errors.lastName = 'validation.required'
  }
  return errors
}

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div>
      <input type="text" className="form-control input-lg" value={field.value} {...field} {...props} />
      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  </div>
)

const IbanComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const changeValue = (event) => {
    const val = event.currentTarget.value
      .replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
      .toUpperCase() //calculation expects upper-case
      .replace(/^(^[A-Z]{2})(^[0-9]+)$/)
    setFieldValue(field.name, val)
  }

  const format = (value) => {
    let a = ''
    if (!value) return a
    for (let i = 0; i < value.length; i++) {
      if (i % 4 === 0 && i !== 0) {
        a = a + ' '
      }
      a = a + value[i]
    }
    return a
  }
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input {...field} {...props} type="text" className="form-control input-lg" value={format(field.value)} onChange={changeValue} />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}

const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="nomargin form-control">
      <FormControlLabel
        control={<Checkbox className="scaled" id={field.name} checked={!!field.value} onChange={props.onChange} value={field.value} />}
        label={label}
      />
      <label htmlFor={field.name}> </label>
      {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
    </div>
  </div>
)

const AccountForm = (props) => {
  const { handleSubmit, initialValues, translate, progress } = props
  return (
    <Formik
      initialValues={initialValues}
      validate={validateFn}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        validateForm,
        /* and other goodies */
      }) => (
        <form className="clearfix account-form" onSubmit={handleSubmit}>
          <div className="col-sm-12 col-md-9">
            <div className="row">
              <div className="col-sm-6">
                <Field
                  name="firstName"
                  label={translate('account.firstName')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="lastName"
                  label={translate('account.lastName')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="phone"
                  label={translate('account.phone')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="email"
                  label={translate('account.email')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="marketingname"
                  label={translate('account.marketingname')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="address"
                  label={translate('account.address')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="city"
                  label={translate('account.city')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="postalcode"
                  label={translate('account.postalcode')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="personSettings.copy_rows"
                  label={translate('account.copy-rows')}
                  id="copy_rows"
                  translate={translate}
                  onChange={handleChange}
                  component={CustomCheckboxComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="personSettings.primaryWork"
                  label={translate('account.primary-work')}
                  id="primaryWork"
                  translate={translate}
                  onChange={handleChange}
                  component={CustomCheckboxComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="profession"
                  label={translate('account.profession')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="taxpercent"
                  label={translate('account.taxpercent')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                  disabled={true}
                />
              </div>
              <div className="col-sm-6">
                <Field name="iban" label={translate('account.iban')} translate={translate} handleChange={handleChange} component={IbanComponent} />
              </div>
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <Field
                  name="bic"
                  label={translate('account.bic')}
                  translate={translate}
                  handleChange={handleChange}
                  component={CustomInputComponent}
                />
              </div>
              <div className="col-sm-6"></div>
              <div className="col-sm-6 pull-right">
                <div>
                  <label>{translate('account.taxCard')}</label>
                </div>
                <Link target="_blank" to={`/api/person/${props.user.id}/taxcard?authtoken=${props.authtoken}`}>
                  {props.user.personSettings.taxCard}
                </Link>
              </div>
              <div className="col-xs-12">
                <div className="pull-right">
                  <div disabled={!!(progress !== null)} style={{ position: 'relative' }} className="btn btn-success white">
                    <div className="glyphicon icon-plus"></div>
                    {translate('account.buttonAdd')}
                    <input
                      onChange={props.onTaxCardUpload}
                      accept=".jpg, .jpeg, .png, .pdf"
                      type="file"
                      disabled={!!(progress !== null)}
                      className="file-input"
                      style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: progress !== null ? 'not-allowed' : 'pointer',
                      }}
                    />
                  </div>
                  <button type="submit" className="btn btn-success">
                    {translate('account.buttonSave')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default AccountForm
