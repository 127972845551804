import React, { Component, useState } from 'react'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import { initialize } from 'react-localize-redux'
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux'
import routes from '../routes'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'

import createStore from '../store/createStore'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

//import 'react-select/dist/react-select.css'
//import 'react-select/styles.css'
import '../styles/select.scss'
import '../styles/tailwind.css'
import '../styles/own-styles.scss'
const mobile = require('is-mobile')
require('../styles/mobile.css')

class AppContainer extends Component {
  constructor(props) {
    super(props)
    Sentry.init({
      dsn: 'https://52346f3753844061a025cd6a7b254d15@o1245996.ingest.sentry.io/6405578',
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
    const initialState = window.___INITIAL_STATE__
    var middleWare = routerMiddleware(browserHistory)
    this.store = createStore(initialState, middleWare)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    let muiTheme = createMuiTheme({
      typography: {
        htmlFontSize: 9,
        fontFamily: ['Source Sans Pro', 'sans-serif'],
      },
      palette: {
        primary: {
          light: '#546379',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          light: '#6fbf73',
          main: '#84B83F',
          dark: '#357a38',
          contrastText: '#fff',
        },
      },
    })

    const _history = syncHistoryWithStore(browserHistory, this.store)
    const languages = ['en', 'fi']
    this.store.dispatch(initialize(languages, { defaultLanguage: 'fi' }))
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'hx3u3l40',
      })
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Provider store={this.store}>
          <div style={{ height: '100%' }}>
            <Router history={_history}>{routes}</Router>
          </div>
        </Provider>
      </MuiThemeProvider>
    )
  }
}

export default withStyles({ withTheme: true })(AppContainer)
