import React, { Component } from 'react'
import { connect } from 'react-redux'
import './register.scss'
import { getTranslate } from 'react-localize-redux'
import { agreeEula, bankConfirmed, getUserByToken } from '../registerActions'

class registrationConfirmBankView extends Component {
  componentDidMount() {
    const { location, confirmRegister } = this.props
    let state = { code: location.query.code, state: location.query.state }
    confirmRegister(state)
  }

  renderFinnishEula() {
    return <div className="row"></div>
  }

  renderEnglishEula() {
    return this.renderFinnishEula()
  }

  onAgree() {
    this.props.eulaAgreed(this.props.user)
  }

  render() {
    const { locale, location, confirmRegister } = this.props
    //confirmRegister(location.query.code)
    return <div></div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.register.user,
    translate: getTranslate(state.locale),
    locale: state.locale,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUser: token => dispatch(getUserByToken(token)),
    eulaAgreed: user => dispatch(agreeEula(user)),
    confirmRegister: user => dispatch(bankConfirmed(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(registrationConfirmBankView)
