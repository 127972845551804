import React from 'react'

export const PrivacyEn = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <h3>1. Registrar</h3>
      Ninjou Ltd., later mentioned also as "Ninjou" Business ID 2543310-1
      <h3>2. Contact person</h3>
      Jani Puustinen admin@ninjou.fi
      <h3>3. The name of the registry</h3>
      Ninjou Oy's personal information, marketing and customer register.
      <h3>4. Purpose of processing personal data</h3>
      Ninjou Ltd.'s uses personal data in it's payroll and billing service. In addition, Ninjou
      analyzes the users information on Ninjou Ltd.'s internal and external marketing planning and
      product development.
      <h3>5. Information content of the register</h3>
      Ninjou Ltd. collects the following information from users of the service:
      <ul>
        <li>The persons whole name</li>
        <li>Social security number</li>
        <li>Home address</li>
        <li> Phone number</li>
        <li>E-mail address</li>
        <li>Bank account number</li>
        <li>Tax card information</li>
        <li>Billing information</li>
        <li>User's customer register</li>
        <li>Salary calculations</li>
        <li>Age</li>
        <li>
          User activity in Ninjou Ltd.'s extranet including the current IP address from which pages
          were browsed
        </li>
      </ul>
      <h3>6. Regular sources of information</h3>
      In its operations, Ninjou Ltd. uses information collected from users as mentioned in section
      5. External sources of information include insurance companies, tax and enforcement
      authorities, as well as other authorities that, according to Finnish law, may transfer
      information to Ninjou Ltd. and from whom Ninjou Ltd. is legally required to receive
      information.
      <h3>7. Data recipients, disclosure of information</h3>
      <p>
        Ninjou Ltd.'s personal data is processed by only natural persons employed by Ninjou Ltd.
      </p>
      a) Ninjou Ltd. will only disclose to third parties the personal data of point 5, at the
      request of the Finnish authorities, according to Finnish law, the following information:
      <ul>
        <li>The persons whole name</li>
        <li>Social security number</li>
        <li>Home address</li>
        <li>Phone number</li>
        <li>E-mail address</li>
        <li>Bank account number</li>
        <li>Tax card information</li>
      </ul>
      b) Ninjou uses data collected from its users in digital analytics services. The user can not
      be identified in this context because the information used does not contain personal
      information. Analytic services store the following information:
      <ul>
        <li>Billing information</li>
        <li>User's customer register</li>
        <li>Salary information</li>
        <li>
          Users activity in Ninjou Ltd. including mouse pointer movement, edited fields, session
          time, browser type and current IP address from which pages were browsed
        </li>
        <li>Time stamps</li>
      </ul>
      <h3>8. Transfer of data outside the EU or EEA</h3>
      Ninjou Ltd. does not disclose information outside the EU. The data to be associated with an
      individual user will be disclosed only at the request of the Finnish authorities.
      <h3>9. Principles of registry protection</h3>
      Customer and personal data are stored in the registrar's premises that are locked. Access to
      the register is restricted to persons working for Ninjou Ltd. who need information in their
      duties. Digital user data is technically protected by isolating data repositories from the
      public network and the service through which access can be accessed is protected by firewall
      and access rights. Information is only transmitted via a secure connection. Paper documents
      are mainly transferred to digital format and stored in a strong security cloud service.
      Accounting and other material stored in paper form are kept in a locked storage.
      <h3>10. Storage time</h3>
      Section 7 b analytics information is stored for up to 120 days in an outside analytics
      service. Digital billing and salary information under the Accounting Act will be kept for a
      maximum of 6 years unless the customer terminates the customer relationship before that date.
      The billing and salary information in paper form is stored for 6 years.
      <h3>11. General rights of a registered person</h3>A private individual registered with the
      Ninjou Service has the right to view, modify and delete their personal data freely. Ninjou
      does not guarantee the functionality of the service if the following data is deleted:
      <ul>
        <li>Account Number</li>
        <li>Tax percentage</li>
        <li>Address</li>
        <li>Telephone number</li>
        <li>Email address</li>
      </ul>
      <h3>12. Other Registered Rights</h3>
      The user has the right to transfer his / her own data to a third party. The user has the right
      not to be subjected to automated decision-making. You will need to inform the customer service
      by email at admin@ninjou.fi. A registered user of the Ninjou Service has the right to become
      completely forgotten after the termination of the customer relationship. Below you will find
      instructions on closing customer relationships and getting forgotten. Customer relationship
      can be terminated in writing by the feedback form at www.ninjou.fi or by email at
      admin@ninjou.fi. After a calendar month after the end of the customer relationship, Ninjou
      removes all digital user information that is unique in its service. After this period, the
      user can not retrieve earnings certificates or other documents from the service, nor can
      Ninjou provide them to the user. In the form of paper, unique billing and payroll information
      is kept under the Finnish Accounting Act for 6 years.
    </div>
  )
}
