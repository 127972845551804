import React, { Component } from 'react'
import { connect } from 'react-redux'
import './register.scss'
import { getTranslate } from 'react-localize-redux'
import { agreeEula, getUserByToken } from '../registerActions'
import { EulaFinnish } from '../../Eula/Eula'
import { getObject } from '../../../components/Localstorage/localStorageService'
import { browserHistory } from 'react-router'

class approveEulaFromBank extends Component {
  componentDidMount() {}

  renderFinnishEula() {
    return (
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <h1>Kiitos rekisteröitymisestä</h1>
          <p className="lead">Hyväksymällä käyttäjäehdot pääset käyttämään Ninjoun järjestelmää.</p>
          <button onClick={this.onAgree.bind(this)} className="btn btn-primary btn-large">
            Olen lukenut ja hyväksyn käyttäjäehdot
          </button>
          {EulaFinnish()}
          <button onClick={this.onAgree.bind(this)} className="btn btn-primary btn-large">
            Hyväksyn käyttäjäehdot
          </button>
        </div>
      </div>
    )
  }

  renderEnglishEula() {
    return this.renderFinnishEula()
  }

  onAgree() {
    const user = getObject('user')
    this.props.eulaAgreed(user)
  }

  render() {
    const user = getObject('user')
    if (!user) {
      browserHistory.push('/registration/failed')
    }
    const { locale } = this.props
    const selectedLang = locale.languages.find((a) => a.active === true)
    let eula = null
    if (selectedLang.code === 'fi') {
      eula = this.renderFinnishEula()
    } else {
      eula = this.renderEnglishEula()
    }
    return <div>{eula}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
    translate: getTranslate(state.locale),
    locale: state.locale,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUser: token => dispatch(getUserByToken(token)),
    eulaAgreed: user => dispatch(agreeEula(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(approveEulaFromBank)
