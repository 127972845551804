import { apiCall } from '../../../services/api'

export function fetchProfilePic(token, id) {
  return apiCall(`/api/person/${id}/profilepic`, 'get', token)
}

export function uploadPic(token, id, file, progressCallback) {
  return apiCall(`/api/person/${id}/profilepic`, 'post', token, file, progressCallback)
}

export function uploadCard(token, id, file, progressCallback) {
  return apiCall(`/api/person/${id}/taxcard`, 'post', token, file, progressCallback)
}
