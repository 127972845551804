import { calculate, calculateFromBill, calculateFromPartialBill } from './calculatorApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path } from 'ramda'

export function tryCalculate(data) {
  const user = getObject('user')
  return calculate(path(['authtoken'], user), data.data)
    .then((calculated) => {
      return { response: calculated }
    })
    .catch((err) => {
      return err
    })
}

export function tryCalculatePartial(data) {
  const user = getObject('user')
  return calculateFromPartialBill(path(['authtoken'], user), data.data)
    .then((calculated) => {
      return { response: calculated }
    })
    .catch((err) => {
      return err
    })
}
export function tryCalculateFromBill(data) {
  const user = getObject('user')
  return calculateFromBill(path(['authtoken'], user), data.data)
    .then(calculated => {
      return { response: calculated }
    })
    .catch(err => {
      return err
    })
}

export function* calculateFromApi(data) {
  const { response, error } = yield call(tryCalculate, data)
  if (response) {
    yield put({ type: 'CALCULATE_SALLARY_SUCCESS', response })
  } else {
    yield put({ type: 'CALCULATE_SALLARY_FAILURE', error })
  }
}




export function* calculateFromBillFromApi(data) {
  const { response, error } = yield call(tryCalculateFromBill, data)
  if (response) {
    yield put({ type: 'CALCULATE_FROM_BILL_SUCCESS', response })
  } else {
    if (error) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: {
          message: `LOCALIZED!${error.response.data.errorKey}`,
          duration: 4000,
          type: 'error',
        },
      })
    }
    yield put({ type: 'CALCULATE_FROM_BILL_FAILURE', error })
  }
}

export function* calculateFromPartialBillFromApi(data) {
  const { response, error } = yield call(tryCalculatePartial, data)
  if (response) {
    yield put({ type: 'CALCULATE_FROM_PARTIAL_BILL_SUCCESS', response })
  } else {
    if (error) {
      yield put({
        type: 'SEND_NOTIFICATION',
        response: {
          message: `LOCALIZED!${error.response.data.errorKey}`,
          duration: 4000,
          type: 'error',
        },
      })
    }
    yield put({ type: 'CALCULATE_FROM_BILL_FAILURE', error })
  }
}

export function* calculateSallary() {
  yield takeEvery('CALCULATE_SALLARY', calculateFromApi)
}
export function* calculateSallaryFromBill() {
  yield takeEvery('CALCULATE_FROM_BILL', calculateFromBillFromApi)
}
export function* calculateSalaryFromPartialBill() {
  yield takeEvery('CALCULATE_FROM_PARTIAL_BILL', calculateFromPartialBillFromApi)
}
