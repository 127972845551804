export function getObject(key) {
  try {
    const obj = JSON.parse(localStorage.getItem(key))
    return obj
  } catch (err) {
    return {}
  }
}

export function setObject(key, obj) {
  let str = null
  if (obj) {
    str = JSON.stringify(obj)
  }
  try {
    localStorage.setItem(key, str)
  } catch (e) {
    console.error('error setting localstorage', e)
  }
}
