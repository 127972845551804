import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import makeRootReducer from './reducers'
import { updateLocation } from './location'
import createSagaMiddleware from 'redux-saga'
import mySaga from '../sagas/rootSagas'
import { composeWithDevTools } from 'redux-devtools-extension'

export default (initialState = {}, middleWare) => {
  const sagaMiddleware = createSagaMiddleware()
  // ======================================================
  // Middleware Configuration
  // ======================================================

  const middleware = [thunk, middleWare]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const middlewares = [sagaMiddleware]
  const enhancers = [applyMiddleware(...middlewares)]

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  let store
  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      makeRootReducer(),
      initialState,
      composeWithDevTools(applyMiddleware(...middleware), ...enhancers),
    )
  } else {
    store = createStore(
      makeRootReducer(),
      initialState,
      compose(applyMiddleware(...middleware), ...enhancers),
    )
  }

  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))
  store.runSaga = sagaMiddleware.run
  sagaMiddleware.run(mySaga)
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
