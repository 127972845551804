import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import Card from '../../../components/Card'
import BillEditor from '../../../components/BillEditor'
import { connect } from 'react-redux'
import './invoices.scss'
import { push } from 'react-router-redux'
import { selectNewBill } from '../../../components/api/Bill/billActions'
import moment from 'moment'

class InvoicesView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      editor: false,
      bill: null,
      filteredBills: []
    }
  }

  componentDidMount() {
    this.props.getBills()
  }

  openEditor = (bill) => {
    const path = '/invoice?id=' + bill.id
    this.props.redirect(path)
  }

  createNewBill = () => {
    const data = {
      billableitems: [],
      newItem: false,
      opened: 'opened',
      state: 'OPEN',
      totalprice: 0,
      wait: true,
      billingdate: moment().format('DD.MM.YYYY')
    }
    this.props.selectNewBill(data)
    this.setState({
      editor: true
    })
  }

  handleUpdate = (bill) => {
    const bills = this.state.bills
    const index = bills.findIndex(x => x.id === bill.id)
    bills[index] = { ...bills[index], ...bill }
    this.setState({ bills })
  }

  closeEditor = () => {
    this.setState({
      editor: false,
      bill: null
    })
    this.props.getBills()
  }

  renderPendingBills() {
    const bills = this.props.bills || []
    return bills
      .filter(bill => bill.state === 'PENDING')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderClosedBills() {
    const bills = this.props.bills || []
    return bills
      .filter(bill => bill.state === 'CLOSED')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }
  renderNewBills() {
    const bills = this.props.bills || []
    return bills
      .filter(bill => bill.state === 'OPEN')
      .map((bill, key) => {
        return this.renderBill(bill, key)
      })
  }

  renderBill(bill, key) {
    if (this.state.filteredBills.includes(bill.id)) return null
    return (
      <div className="col-xs-12 col-sm-6 col-md-12" key={key}>
        <Card
          onUpdate={this.handleUpdate}
          data={bill}
          onEdit={this.openEditor.bind(this, bill)}
        />
      </div>
    )
  }

  render() {
    const { translate } = this.props
    if (this.state.editor) {
      return (<BillEditor
        onUpdate={this.handleUpdate}
        onClose={this.closeEditor} />)
    }
    return (
      <div className="row">
        <div className="dashboard">
          <button className="btn btn-success btn-new-invoice" onClick={this.createNewBill.bind(this, null)}>
            <span>
              <span className="glyphicon icon-plus-white"></span>
              {translate('invoicesView.buttonNew')}
            </span>
          </button>
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">{translate('invoicesView.titleNew')}</div>
            <div className="row">
              {this.renderNewBills()}
            </div>
          </div>
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">{translate('invoicesView.pendingBills')}</div>
            <div className="row">
              {this.renderPendingBills()}
            </div>
          </div>
          <div className="col-lg-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">{translate('invoicesView.sentBills')}</div>
            <div className="row">
              {this.renderClosedBills()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bills: state.bills.list,
    translate: getTranslate(state.locale),
    lastUpdatedBill: state.bills.lastUpdatedBill
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getBills: () => dispatch({ type: 'GET_BILLS' }),
    selectNewBill: (data) => dispatch(selectNewBill(data)),
    redirect: (url) => dispatch(push(url))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesView)
