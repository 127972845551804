import React, {Component} from 'react'
import {connect} from 'react-redux'
import RegisterForm from './registerForm'
import './register.scss'
import NinjouImage from '../../Login/assets/technology.png'
import {browserHistory, IndexLink} from 'react-router'
import {getActiveLanguage, getTranslate} from 'react-localize-redux'
import ReactPixel from 'react-facebook-pixel'
import {landingAdd} from '../../Marketing/LandingAdd'

class RegisterView extends Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    if (user && user.authtoken) {
      browserHistory.push('')
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    ReactPixel.track('ViewContent', {
      content_name: 'Pushed register button',
      content_category: 'Registration',
      content_ids: [],
      content_type: 'product',
      value: 0,
      currency: 'EUR',
    })
    this.props.register({
      ...values,
      language: this.props.language,
    })
  }
  componentDidMount() {
    ReactPixel.init('316885369682761q')
    ReactPixel.pageView()
    ReactPixel.fbq('track', 'PageView')
  }
  render() {
    const image = `url(${NinjouImage})`
    const { translate } = this.props
    return (
      <div className="row">
        <div className="content-bg" style={{ backgroundImage: image }}></div>
        <div className="col-xs-12 register-container no-padding">
          <div
            className="col-xl-6 welcome-register-container bigger no-padding"
            style={{ backgroundImage: image }}
          >
            <div className="row">
              <div className="col-xs-12">
                <h1>{translate('register.welcomeTitle')}</h1>
              </div>
              <div className="col-xs-12">
                <span>{translate('register.welcomeText')}</span>
              </div>
              <div className="col-xs-12">
                <IndexLink to="/login" className="register-link">
                  {translate('register.login')}
                </IndexLink>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 register-form-container">
            <div className="row">
              <RegisterForm
                handleSubmit={this.handleSubmit}
                initialValues={{
                  email: '',
                  phone: '',
                  address: '',
                  postalcode: '',
                  password: '',
                  promoCode: '',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user || {},
    translate: getTranslate(state.locale),
    language: getActiveLanguage(state.locale).code,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    register: (credentials) => dispatch({ type: 'USER_REGISTER_REQUESTED', credentials }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView)
