import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export function sendToBillingModal(modal, message, translate, closeModal, handleAction, validation = {}, approveAddressStart, state) {
  let msg = []
  msg.push(<div key="1">{message}</div>)
  let approvableByCheckBox = false
  let canSend = true
  if (validation.valid === false) {
    canSend = false
    let keys = Object.keys(validation.errors)
    msg = []
    keys.forEach((key) => {
      let temp = translate(`invoice.${key}`) + ' : ' + translate(`invoice.${validation.errors[key]}`)
      msg.push(<div key={key}>{temp}</div>)
    })

    if (keys.length === 1) {
      if (keys[0] === 'not_home_address') {
        approvableByCheckBox = true
      }
      if (state.approved_address_start === true) {
        canSend = true
      }
    }
  }

  let checkBoxElem = ''
  if (approvableByCheckBox) {
    checkBoxElem = (
      <div className="message">
        <FormControlLabel
          control={
            <Checkbox
              className="scaled"
              id="reverse"
              checked={!!state.approved_address_start}
              onChange={() => approveAddressStart(!state.approved_address_start)}
              value={!!state.approved_address_start}
            />
          }
          label={translate('invoice.leftSomewhereElseThanHome')}
        />
      </div>
    )
  }

  return (
    <Modal className="confirm" show={!!modal} onHide={closeModal} backdrop={'static'} animation={false}>
      <div className="valign-helper">
        <Modal.Header closeButton>
          <Modal.Title>{translate('invoice.confirmTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="message">{msg}</div>
          {checkBoxElem}
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-6">
              <Button variant="cancel" onClick={closeModal}>
                {translate('misc.cancel')}
              </Button>
            </div>
            <div className="col-xs-6">
              <Button variant="success" disabled={canSend === false} onClick={handleAction}>
                {translate('misc.confirm')}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
