import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import WageCard from './../../components/WageCard'
import { groupWith } from 'ramda'
import './salaries.scss'

const next = {
  marginRight: '10px',
  width: '100px',
}
const previous = {
  marginRight: '10px',
  width: '100px',
}

const pages = {
  marginTop: '7px',
}
class Salaries extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      limit: 20,
    }
  }

  componentDidMount() {
    this.props.getSalaries()
  }

  renderGroups = (page, limit) => {
    const groups = groupWith((a, b) => {
      return a.payday.slice(3) === b.payday.slice(3)
    }, this.props.salaries.slice(page * limit, page * limit + limit))
    return groups.map((group, key) => this.renderMonth(group, key))
  }

  renderMonth = (group, key) => {
    const { translate } = this.props
    const cards = group.map((card, key) => this.renderCard(card, key))
    const months = translate('chart.months').split(',')
    const date = group[0].payday.split('.')
    const dateString = months[parseInt(date[1]) - 1] + ' ' + date[2]

    return (
      <div className="col-lg-12" key={key}>
        <div className="panel info-title"></div>
        <div className="sub-header dkblue">{dateString}</div>
        <div className="row">{cards}</div>
      </div>
    )
  }
  previous = () => {
    if (this.state.page > 0) {
      this.setState({
        ...this.state,
        page: this.state.page - 1,
      })
    }
  }
  next = () => {
    if (this.state.page * this.state.limit + this.state.limit < this.props.salaries.length) {
      this.setState({
        ...this.state,
        page: this.state.page + 1,
      })
    }
  }
  renderCard = (card, key) => {
    return (
      <div className="col-sm-6 col-md-4 col-lg-3" key={key}>
        <WageCard
          id={card.id}
          key={key}
          alvtotal={card.alvtotal}
          payday={card.payday}
          othercosts={card.othercosts}
          salary={card.salary}
          salaryurl={card.salaryurl}
          costs={card.costs}
        />
      </div>
    )
  }

  render() {
    const { translate } = this.props

    const groups = this.renderGroups(this.state.page, this.state.limit)
    const total = this.props.salaries.length
    const from = this.state.page * this.state.limit + 1
    const lastOrLimit = this.state.page * this.state.limit + this.state.limit
    const to = lastOrLimit < total ? lastOrLimit : total
    const showText = `${translate('salaries.showing')} ${from}-${to}, ${total} ${translate('salaries.fromSalaries')}`
    return (
      <div>
        <div className="row">
          <div className="dashboard wage-slip">
            <div style={{ marginTop: '30px', fontSize: '25px', fontWeight: 100 }} className="col-lg-12">
              {translate('salaries.title')}
            </div>
            {groups}
          </div>
        </div>
        <div className="dashboard wage-slip" style={{marginBottom: '30px'}}>
          <div className="col-sm-4">
            <button className="btn btn-default" style={previous} onClick={this.previous}>
              {translate('salaries.previous')}
            </button>
            <button className="btn btn-default" style={next} onClick={this.next}>
              {translate('salaries.next')}
            </button>
          </div>
          <div className="col-sm-4" style={pages}>
            {showText}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    salaries: state.salaries.list || [],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSalaries: () => dispatch({ type: 'GET_SALARIES' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Salaries)
