import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { browserHistory } from 'react-router'
import { path } from 'ramda'

class LoginView extends Component {
  componentDidMount() {
    const { user } = this.props
    const token = path(['location', 'query', 'token'], this.props)
    const billId = path(['location', 'query', 'bill'], this.props)

    this.props.loginWithToken(token, billId)
    if (user && user.authtoken) {
      if (billId) {
        browserHistory.push(`/invoice?id=${billId}`)
      } else {
        browserHistory.push('')
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextprops) {}

  render() {
    return <div className="row"></div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user || {},
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginWithToken: (token, billId) => dispatch({ type: 'TOKEN_LOGIN_REQUESTED', token, billId }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
