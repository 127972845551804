import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { searchSubcontractors, createSubcontracorAction } from '../../../components/api/SubContractor/subContractorActions'
import { find, propEq } from 'ramda'
import { formatNumber, parseNumber } from '../../../components/Utils/formHelpers'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { isMobile } from '../../Utils/detectMobile'
import LocaleUtils from 'react-day-picker/moment'
import ContractorInvoiceRow from './ContractorInvoiceRow'
import ExtContractorInvoiceRow from './ExternalItemRow'
import { dateToIsoDate, isoDateToDate } from '../../Utils/dateHelpers'
import ReactTooltip from 'react-tooltip'
import './contractor.scss'
import { reactSelectStyles } from '../../../styles/ReactSelectStyles'

const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

class ContractorButton extends Component {
  constructor(props) {
    super(props)
    this.state = this.getBaseState()
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.setSubcontractor = this.setSubcontractor.bind(this)
    this.handleChangeAmount = this.handleChangeAmount.bind(this)
    this.handleChangeMobileDate = this.handleChangeMobileDate.bind(this)
    this.handleChangeDesktopDate = this.handleChangeDesktopDate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onInputKeyDown = debounce((key) => {
      this.searchSubContractor(key)
    }, 200).bind(this)
  }

  getBaseState() {
    return {
      modalOpen: false,
      extModalOpen: false,
      selectedSubcontractor: {
        label: '',
        value: '',
      },
      subcontractorBillableItem: {
        description: '',
        date: '',
        amount: '',
        subcontractor: '',
      },
      error: {},
    }
  }

  validate() {
    const errors = {}
    const item = this.state.subcontractorBillableItem
    if (!item.subcontractor) {
      errors.subcontractor = 'required'
    }
    if (!item.date) {
      errors.date = 'required'
    }
    if (!item.amount) {
      errors.amount = 'required'
    }
    if (!item.description) {
      errors.description = 'required'
    }
    return errors
  }

  handleSubmit(event) {
    event.preventDefault()
    const errors = this.validate()

    const keys = Object.keys(errors)
    if (keys.length === 0) {
      this.props.createSubcontractorItem(this.state.subcontractorBillableItem, this.props.selectedBill.id)
      this.setState({
        errors: errors,
        modalOpen: false,
      })
    } else {
      this.setState({
        error: errors,
      })
    }
  }

  handleChangeAmount(e) {
    const {
      error: { amount, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        [e.target['name']]: parseNumber(e.target.value),
      },
    })
  }

  handleChange(e) {
    const {
      error: { description, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        [e.target['name']]: e.target.value,
      },
    })
  }

  setSubcontractor(e) {
    const {
      error: { subcontractor, ...error },
    } = this.state
    const sub = find(propEq('id', e.value))(this.props.subcontractors)
    this.setState({
      error: {
        ...error,
      },
      selectedSubcontractor: e,
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        subcontractor: sub,
      },
    })
  }

  searchSubContractor = (key) => {
    this.props.searchSubcontractorList(key)
    if ((key && key.length > 1) || key.length === 0) {
      this.setState({
        customers: [],
      })
    }
  }

  openModal() {
    this.setState({
      modalOpen: true,
      subcontractorBillableItem: {},
    })
  }

  closeModal() {
    const newState = this.getBaseState()
    this.setState(newState)
  }

  openExtModal = () => {
    this.setState({
      extModalOpen: true,
    })
  }

  closeExtModal = () => {
    this.setState({
      extModalOpen: false,
    })
  }

  handleChangeMobileDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        date: isoDateToDate(e.target.value),
      },
    })
  }

  handleChangeMobileDueDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        duedate: isoDateToDate(e.target.value),
      },
    })
  }

  handleChangeDesktopDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        date: e.format('DD.MM.YYYY'),
      },
    })
  }

  handleChangeDesktopDueDate(e) {
    const {
      error: { date, ...error },
    } = this.state
    this.setState({
      error: {
        ...error,
      },
      subcontractorBillableItem: {
        ...this.state.subcontractorBillableItem,
        duedate: e.format('DD.MM.YYYY'),
      },
    })
  }

  getDateComponent(disabled) {
    const { translate, locale } = this.props
    if (!isMobile()) {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
            <DayPickerInput
              value={this.state.subcontractorBillableItem.date}
              dayPickerProps={{ localeUtils: LocaleUtils, locale }}
              readOnly={true}
              disabled={disabled}
              style={{ display: 'block' }}
              className="form-control"
              onDayChange={this.handleChangeDesktopDate}
              placeholder="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.date')}</label>
          <div>
            <input
              disabled={disabled}
              placeholder={translate('subcontracting.date')}
              className="form-control input-lg"
              name="date"
              type="date"
              value={dateToIsoDate(this.state.subcontractorBillableItem.date)}
              onChange={this.handleChangeMobileDate}
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      )
    }
  }

  getDueDateComponent() {
    const { translate, disabled, locale } = this.props
    if (!isMobile()) {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.duedate')}</label>
          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
            <DayPickerInput
              value={this.state.subcontractorBillableItem.duedate}
              dayPickerProps={{ localeUtils: LocaleUtils, locale }}
              readOnly={true}
              disabled={disabled}
              style={{ display: 'block' }}
              className="form-control"
              onDayChange={this.handleChangeDesktopDueDate}
              placeholder="DD.MM.YYYY"
              format="DD.MM.YYYY"
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label className="dkblue">{translate('subcontracting.duedate')}</label>
          <div>
            <input
              disabled={disabled}
              placeholder={translate('subcontracting.duedate')}
              className="form-control input-lg"
              name="duedate"
              type="duedate"
              value={dateToIsoDate(this.state.subcontractorBillableItem.duedate)}
              onChange={this.handleChangeMobileDueDate}
            />
            {this.state.error['date'] && <span className="field-error">{translate('validation.' + this.state.error['date'])}</span>}
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      )
    }
  }

  render() {
    const { translate, selectedBill, subcontractors } = this.props
    const subcontractorsList = subcontractors.map((subcontractor) => {
      return {
        label: subcontractor.fullName,
        value: subcontractor.id,
      }
    })
    const state = selectedBill.state
    const disabled = state !== 'OPEN'
    const dateInput = this.getDateComponent()
    const hasContractorItems = selectedBill.contractorBillableItems && selectedBill.contractorBillableItems.length > 0
    const items = selectedBill.contractorBillableItems || []
    let rows =
      items.map((row) => {
        return <ContractorInvoiceRow key={row.id} row={row} bill={selectedBill} />
      }) || []

    const extItems = selectedBill.extContractorBillableItems || []
    let externalRows = extItems.map((row) => {
      return <ExtContractorInvoiceRow key={row.id} row={row} />
    })

    const hasExtContractorItems = selectedBill.extContractorBillableItems && selectedBill.extContractorBillableItems.length > 0
    return (
      <div className="row">
        {hasContractorItems && (
          <div className="col-xs-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              {translate('subcontracting.subcontractingRows')}
            </div>
            {rows}
          </div>
        )}
        {hasExtContractorItems && (
          <div className="col-xs-12">
            <div style={{ marginTop: '30px' }} className="panel info-title">
              {translate('subcontracting.extSubcontractingRows')}
            </div>
            {externalRows}
          </div>
        )}
        <Modal show={this.state.modalOpen} onHide={this.closeModal} backdrop={'static'} animation={false}>
          <div className="valign-helper">
            <Modal.Header closeButton>
              <Modal.Title>{translate('subcontracting.Modal.Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="form-group">
                      <label className="dkblue">{translate('subcontracting.subcontractor')}</label>
                      <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={disabled}>
                        <Select
                          disabled={disabled}
                          options={subcontractorsList}
                          value={this.state.selectedSubcontractor || ''}
                          placeholder={translate('misc.select')}
                          inputProps={{ className: 'form-control22' }}
                          clearable={false}
                          autoload={false}
                          onInputChange={this.onInputKeyDown}
                          onBlurResetsInput={false}
                          onSelectResetsInput={false}
                          onCloseResetsInput={false}
                          filterOptions={(options, filter, currentValues) => {
                            // Do no filtering, just return all options
                            return options
                          }}
                          onChange={this.setSubcontractor}
                          styles={reactSelectStyles}
                        />
                        {this.state.error['subcontractor'] && (
                          <span className="field-error">{translate('validation.' + this.state.error['subcontractor'])}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">{dateInput}</div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="form-group">
                      <label className="dkblue">{translate('subcontracting.price')}</label>
                      <div>
                        <input
                          placeholder={translate('subcontracting.price')}
                          className="form-control input-lg"
                          name="amount"
                          type="text"
                          value={formatNumber(this.state.subcontractorBillableItem.amount)}
                          onChange={this.handleChangeAmount}
                        />
                        {this.state.error['amount'] && <span className="field-error">{translate('validation.' + this.state.error['amount'])}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12">
                    <div className="form-group">
                      <label className="dkblue">{translate('subcontracting.description')}</label>
                      <div>
                        <input
                          placeholder={translate('subcontracting.description')}
                          className="form-control input-lg"
                          name="description"
                          type="text"
                          value={this.state.subcontractorBillableItem.description}
                          onChange={this.handleChange}
                        />
                        {this.state.error['description'] && (
                          <span className="field-error">{translate('validation.' + this.state.error['description'])}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row modal-buttons">
                  <div className="col-xs-12">
                    <button className="btn btn-success pull-right" type="submit" style={{ width: '210px' }}>
                      {translate('subcontracting.addRow')}
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedBill: state.bill.selectedBill,
    subcontractors: state.subcontractor.list,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchSubcontractorList: (data) => dispatch(searchSubcontractors(data)),
    createSubcontractorItem: (item, billId) => dispatch(createSubcontracorAction(item, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorButton)
