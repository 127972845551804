import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { resetPassword } from '../passwordActions'

class success extends Component {
  componentDidMount() {
    const { location, continueReset } = this.props
    console.log('success', location.query.code)
    console.log('state', location.query.state)
    let state = { code: location.query.code, state: location.query.state }
    continueReset(state)
  }

  renderFinnishEula() {
    return <div className="row"></div>
  }

  renderEnglishEula() {
    return this.renderFinnishEula()
  }

  onAgree() {
    this.props.eulaAgreed(this.props.user)
  }

  render() {
    return <div></div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.register.user,
    translate: getTranslate(state.locale),
    locale: state.locale,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    continueReset: (user) => dispatch(resetPassword(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(success)
