import React from 'react'

export const EulaFinnish = () => {
  return (
    <div>
      <h3>Ninjou Oy:n, 2543310-1, palvelun yleiset käyttöehdot Voimassa 1.1.2023 alkaen</h3>
      <h4>Yleistä</h4>
      <ol>
        <li>
          Käyttäjätilin luominen verkkopalveluun ei vielä muodosta työsuhdetta Ninjoun ja sen käyttäjän välille. Kirjallinen tai vähintään suullinen
          sopimus vaaditaan työsuhteen merkiksi. Työsuhteen laatu ja ehdot tarkennetaan varsinaisessa työsopimuksessa.
        </li>
        <li>
          Ninjou Oy voi tarjota töitä sen palveluun rekisteröityneelle henkilölle. Rekisteröitynyt palvelun käyttäjä voi myös sopia suoraan
          loppuasiakkaan kanssa työvuoroistaan varsinkin Ninjou Oy:n palveluajan ulkopuolella. Suositeltavaa on kuitenkin aina pyytää työn tarjoajaa
          ottamaan yhteyttä Ninjou Oy:n palveluneuvojaan ennen työsuoritetta, jos se on mahdollista.
        </li>
        <li>
          Ninjou Oy:n alaisuudessa työtä tekevän on noudatettava työn tarjoajan antamia työturvallisuusmääräyksiä ja toimittava aina varovaisesti
          työkohteessa.
        </li>
        <li>
          Ninjoun työntekijän on viipymättä työvuorojen jälkeen toimitettava työajanseurantaan ja palkanmaksun määräytymiseen liittyvät yksityiskohdat
          Ninjoun verkkopalvelun kautta, jollei Ninjou ole jo sopinut työvuorosta loppuasiakkaan kanssa. Ninjou Oy myös osaltaan tarkistaa työn
          ostaneen yrityksen työvuorolistat. Ellei oikeellisia tietoja työvuoroista työntekijän ilmoittaminen tietojen perusteella saada viipymättä
          työvuorojen päätteeksi, palkanmaksua voidaan viivästyttää kunnes työvuorojen tiedot on pitävästi selvitetty.
        </li>
        <li>Ninjou Oy:lle on toimitettava työtodistukset ja osaamisen tunnustaminen, jotta henkilön ammattitaito voidaan todentaa.</li>
        <li>Palkanmaksu määräytyy kulloisenkin alan työehtosopimuksen tai urakkapalkkauksen mukaisesti.</li>
        <li>
          Yleinen palkkapäivä Ninjoulla on työn aloituksesta seuraavan kuun 20. päivä. Tästä voidaan poiketa yksilöllisesti Ninjoun ja työntekijän
          välisellä sopimuksella.
        </li>
        <li>Työntekijä on vakuutettu kaikilla lakisääteisillä vakuutuksilla.</li>
        <li>
          Ninjou Oy tarjoaa työntekijälleen työterveyshuollon ja työkyvyn alenemaan liittyvät palvelut Mehiläinen-konsernin toimipisteissä ympäri
          Suomea.
        </li>
        <li>Sairauspoissaoloista vaaditaan aina lääkärintodistus. Todistus haetaan oman paikkakunnan terveysasemalta.</li>
        <li>Ninjou Oy korvaa sairausajan peruuntuneet työvuorot 10 sairauspäivään asti.</li>
        <li>
          Ninjou Oy voi kustantaa työntekijänsä työvälineitä ja -vaatteita, jos työnkuva niitä vaatii. Käyttäjäyritys voi myös tarjota työvaatteet ja
          -välineet. Esiintyjät kustantavat aina omat työvaatteensa ja työvälineensä.
        </li>
        <li>
          Kun työsopimus on allekirjoitettu, Ninjou varaa etuoikeuden käyttää työntekijän palveluita myydessään niitä loppuasiakkaalle. Työntekijä voi
          kieltäytyä tarjotusta työstä, jos Ninjou pyytää työvuoroon alle neljää viikkoa ennen työvuoroa.
        </li>
        <li>Koeaika on aina kuusi kuukautta. Tänä aikana työsuhteen voi purkaa molemminpuolisesti ilman irtisanomisaikaa.</li>
        <li>
          Työsopimus on pääsääntöisesti tarvittaessa työhön kutsuttavan työntekijän sopimus. Kun työsuhde on kestänyt yli vuoden, tarkastellaan
          toteutuneet työvuorot ja tuntimäärät, jonka jälkeen työsopimus voidaan muuttaa tarpeen mukaan toistaiseksi voimassa olevaksi.
        </li>
      </ol>
      <h4>Ninjou Oy:n verkkopalvelun ehdot</h4>
      <ol>
        <li>
          Ninjou Oy:n henkilöstöpalveluun liitytään joko osoitteessa ninjou.fi tai laskutus.ninjou.fi. Työntekijä hyväksytään mukaan valtuutetun
          Ninjou työntekijän toimesta.
        </li>
        <li>
          Ninjou Oy:n verkkopalvelun kautta työntekijä voi työskennellä haluamallaan tavalla, määräyksien ja ohjeiden mukaisesti eri tahoille, hyvää
          tapaa ja Suomen lakia noudattaen.
        </li>
        <li>
          Ninjou Oy:n etujen tai maineen vastaisesti ja vilpillisesti toimivan työntekijän työsuhde voidaan purkaa kulloisenkin työlainsäädännön
          puitteissa.
        </li>
        <li>
          Ninjou Oy:n verkkopalvelun käyttäjä on velvollinen tarkasti ja huolella täyttämään tarvittavat tiedot pyydettyihin työvuoron tuloutusta
          koskeviin kenttiin.
        </li>
        <li>
          Ninjou Oy:n henkilöstöpalvelua käyttävä työntekijä hyväksyy sen, että hänen tietojaan toimitetaan viranomaisen pyynnöstä asiaankuuluville
          tahoille, jotka niitä Suomen lainsäädännön perusteella voivat vaatia.
        </li>
        <li>
          Ninjoun verkkosivut toimivat sellaisenaan kuin ne ovat kulloinkin tarjolla. Ninjou Oy pidättää oikeudet teknisiin muutoksiin. Ninjou Oy ei
          takaa teknistä toimivuutta kaikilla ohjelmistoalustoilla, eikä tilanteessa jossa Ninjoun palvelua käyttävän työntekijän laitteisto ei tue
          uusia ohjelmistoteknisiä ominaisuuksia.
        </li>
        <li> Ninjou Oy ei vastaa palvelun käyttäjän kadonneista tiedoista, mahdollisen tietoteknisen vikatilanteen johdosta.</li>
      </ol>

      <p>
        Kaikki Ninjou Oy:n ja sen verkkolaskutuspalvelun käyttäjän väliset juridiset kiistat ratkaistaan tarvittaessa Keski-Suomen käräjäoikeudessa
        Jyväskylässä.
      </p>
    </div>
  )
}
