import React, {Component} from 'react'

class About extends Component {

  render() {
    return (
      <div>About page</div>
    )
  }
}

export default About
