import { apiCall } from '../../../services/api'

export function fetchBills(token, id) {
  return apiCall(`/api/person/${id}/bills`, 'get', token)
}

export function calculateFromBill(token, data) {
  return apiCall(`/api/calc/frombill/${data.id}`, 'put', token, data)
}

export function newBill(token, id, data) {
  return apiCall(`/api/person/${id}/bills`, 'post', token, data)
}

export function changeBill(token, id, data) {
  return apiCall(`/api/person/${id}/bills/${data.id}`, 'put', token, data)
}

export function removeBill(token, id, data) {
  return apiCall(`/api/person/${id}/bills/${data.id}`, 'delete', token)
}

export function openBill(token, data) {
  return apiCall(`/api/person/bill/${data.id}/open`, 'put', token, data)
}

export function fetchBillableItems(token, id) {
  return apiCall(`/api/person/bills/${id}/billableitems`, 'get', token)
}

export function newBillableItem(token, id, data) {
  return apiCall(`/api/person/${id}/bills/${data.id}`, 'put', token, data)
}

export function getBillableItem(token, id) {
  return apiCall(`/api/billableitem/${id}`, 'get', token)
}

export function changeBillableItem(token, id, data, callback) {
  return apiCall(`/api/billableitem/${data.id}`, 'put', token, data, callback)
}

export function removeBillableItem(token, data) {
  return apiCall(`/api/billableitem/${data.id}`, 'delete', token)
}

export function fetchArchive(token, id, page) {
  return apiCall(`/api/person/${id}/archive?page=${page}`, 'get', token)
}

export function newReceipt(token, data, progressCallback) {
  return apiCall(
    `/api/billableitem/${data.id}/receipt`,
    'post',
    token,
    data.image,
    progressCallback,
  )
}

export function removeReceipt(token, id) {
  return apiCall(`/api/billableitem/receipt/${id}`, 'delete', token)
}

export function fetchReceipt(token, id) {
  return apiCall(`/api/billableitem/receipt/${id}`, 'get', token)
}

export function copyBill(token, personId, id) {
  return apiCall(`/api/person/${personId}/bills/${id}/copy`, 'post', token)
}

export function createAdvancePayment(token, personId, billId, data) {
  return apiCall(
    `/api/person/${personId}/bills/${billId}/advancePayment`,
    'post',
    token,
    data,
  )
}
