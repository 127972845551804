import React from 'react'
import { reactSelectStyles } from '../../styles/ReactSelectStyles'
import AsyncSelect from 'react-select/async'

export const CustomRouteComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  disabled,
  label,
  routes,
  selectClass,
  handleChange,
  disabledSearch,
  values,
  self,
  form,
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const isOpenStr = `menuOpen${field.name}`
  const isLoadingField = `loading_${field.name}`
  const isOpen = self.state?.[isOpenStr] || false
  const onRouteChange = async (value, field, setFieldValue, values, form) => {
    // field.value = value.value
    if (value) {
      await setFieldValue(field.name, value.value)
      self.setKilometers(field, value, values, setFieldValue)
      self.setState({
        ...self.state,
        [isOpenStr]: false,
      })
    }
  }

  const loadOptions = (inputString, callback) => {
    self.setState({
      ...self.state,
      [isLoadingField]: true,
    })
    return new Promise(async (resolve) => {
      const addressesFn = (addresses) => {
        const values = addresses?.map((address) => {
          return {
            label: address,
            value: address,
          }
        })
        self.setState({
          [isLoadingField]: false,
        })
        resolve(values)
      }
      self.searchAddress(inputString, field, addressesFn)
    })
  }
  const finalRoutes =
    routes?.map((route) => {
      return {
        value: route,
        label: route,
      }
    }) || []
  const includes = finalRoutes.find((val) => val.value === field.value)
  if (!includes && field.value) {
    finalRoutes.push({
      value: field.value,
      label: field.value,
    })
  }
  let selectedVal = finalRoutes.find((val) => val.value === field.value)
  const toggleMenu = (boolean) => {
    self.setState({
      ...self.state,
      [isOpenStr]: boolean,
    })
  }
  const isLoading = !!self.state[isLoadingField]
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        {props.children}
        <div className={selectClass}>
          <AsyncSelect
            menuIsOpen={isOpen}
            isLoading={isLoading}
            loadingMessage={() => translate('misc.search.loading')}
            noOptionsMessage={() => translate('misc.search.noOptions')}
            onFocus={() => toggleMenu(true)}
            onBlur={() => toggleMenu(false)}
            disabled={disabled}
            isSearchable={!disabledSearch}
            value={selectedVal}
            defaultOptions={finalRoutes}
            cacheOptions
            placeholder={translate('misc.select')}
            inputProps={{ className: 'form-control22' }}
            clearable={false}
            loadOptions={loadOptions}
            styles={reactSelectStyles}
            onChange={(value) => onRouteChange(value, field, setFieldValue, values, form)}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    </div>
  )
}
