import React, { Component } from 'react'
import { Link } from 'react-router'
import Questions from './Questions'
import jani from './../assets/photo-jani.jpg'
import tommi from './../assets/photo-tommi.jpg'
import './Landing.scss'
// eslint sx-a11y/anchor-is-valid: 0
class Landing extends Component {
  handleSubmit = data => {}

  scrollToAnchor = anchor => {
    if (!this.scrollToAnchor) {
      return
    }
    this.scrollAnchor.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  render() {
    const photoTommi = `url(${tommi})`
    const photoJani = `url(${jani})`
    return (
      <div className="landing">
        <div className="header wrap">
          <div className="container">
            <div className="head">
              <div className="buttons">
                <Link to="/register">
                  <button className="btn transparent">Rekisteröidy</button>
                </Link>
                <Link to="/login">
                  <button className="btn ">Kirjaudu Sisään</button>
                </Link>
              </div>
              <div className="logo"></div>
            </div>
          </div>
          <div className="hr"></div>
          <div className="container">
            <div className="banner-info">Laskuta ilman yritystä, muutamme työsi palkaksi</div>
          </div>
          <div className="hr"></div>
          <div className="header-hint">
            *Ninjou on Japania ja tarkoittaa inhimillisyyttä, se on toimintamme periaate sinun
            rahaliikenteesi hoitamisessa. Joustamme tarpeidesi mukaan, emmekä jätä pulaan
          </div>
          <button className="mouse" onClick={this.scrollToAnchor} />
        </div>
        <div ref={node => (this.scrollAnchor = node)}></div>
        <div className="wrap">
          <div className="container">
            <div className="heading star">
              <span>tietoja tarkemmin</span>
            </div>
            <div className="plain-text">
              Ninjou tarjoaa sinulle markkinoiden vaivattomimman tavan muuttaa työntekosi rahaksi.
            </div>
            <div className="info-blocks">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <div className="info-block suitcase">
                    <div className="header-text">
                      <span>Laskutuspalvelu ja palkanmaksu</span>
                    </div>
                    <div className="header-hr short"></div>
                    <div className="text">
                      <span>
                        Helppous ja vaivattomuus kuvaavat palveluamme.
                        <br />
                        Saat yrittäjän vapauden työntekijän eduilla. Samankaltaisina toistuvat
                        laskutukset ovat aina tallessa, etkä joudu toistamaan puuduttavia rutiineja
                        uudelleen.
                        <br />
                        Provisiomme on 6,9% ja se sisältää kaiken työn, jota laskutus ja palkanmaksu
                        lakisääteisesti vaativat. Jos koet, että laskutuksesi edellyttää pienempää
                        prosenttia, neuvottele kanssamme. Ei piilokuluja palkkatodistuksista tai
                        muistakaan paperitöistä.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="info-block umbrella">
                    <div className="header-text">
                      <span>VAKUUTUKSET</span>
                    </div>
                    <div className="header-hr short"></div>
                    <div className="text">
                      <span>
                        Ninjou Oy:n kautta työskennellessä olet vakuutettu toimialasta riippumatta,
                        lukuunottamatta Valviran alaisia toimialoja. Näitä ovat mm. muutamat
                        hoitotyöhön ja turvallisuuteen liittyvät alat.
                        <br />
                        Vakuutuksemme käsittävät lakisääteiset tapaturma- ja henkivakuutukset sekä
                        vastuuvakuutuksen työssä mahdollisesti aiheutuvista, työntekijän asiakkaalle
                        aiheuttamista vahingoista.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="info-block wallet">
                    <div className="header-text">
                      <span>Laskutuspalvelu ja palkanmaksu</span>
                    </div>
                    <div className="header-hr short"></div>
                    <div className="text">
                      <span>
                        Jotta palvelumme erinomainen kehitimme toimiva järjestelmä
                        perintätoimistojen.
                        <br />
                        Perimme tehokkaasti saatavat asiakkaalta, kuitenkin sovitellen ja kaikkien
                        osapuolten kasvot säilyttäen.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading question">
              <span>Usein kysytyt kysymykset</span>
            </div>
            <Questions />
          </div>
        </div>
        <div className="interested">
          <div className="wrap">
            <div className="header">Kiinnostunut?</div>
            <div className="header-hr white"></div>
            <div className="clearfix">
              <div className="pane">
                <div className="rhombus">
                  <div className="inner user"></div>
                </div>
                <div>
                  <span>
                    Rekisteröidy palvelumme käyttäjäksi ja voit laskuttaa asiakkaitasi samantien.
                  </span>
                </div>
                <Link to="/register">
                  <button className="btn transparent">Rekisteröidy</button>
                </Link>
              </div>
              <div className="pane">
                <div className="rhombus">
                  <div className="inner lock"></div>
                </div>
                <div>
                  <span>Oletko jo käyttäjämme? Kirjaudu sisään oheisen linkin kautta.</span>
                </div>
                <Link to="/login">
                  <button className="btn transparent">Kirjaudu Sisään</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="container">
            <div className="heading gear">
              <span>Näin se toimii</span>
            </div>
            <div className="plain-text">
              Ninjou tarjoaa markkinoiden helpoimman työkalun muuttaa työsi rahaksi ilman
              yrittäjyyden velvoitteita.
            </div>
            <div className="steps clearfix">
              <div className="step">
                <div className="content star">
                  Sovi työnantajan kanssa että hoidat laskutuksen Ninjou Oy:n kautta.
                </div>
              </div>
              <div className="step">
                <div className="content key">
                  Rekisteröidy palveluumme ja lähetä työsuorituksistasi lasku Ninjoun kautta.
                </div>
              </div>
              <div className="step">
                <div className="content document">
                  Ninjou laskuttaa asiakastasi ja hoitaa puolestasi paperityöt.
                </div>
              </div>
              <div className="step">
                <div className="content euro">
                  Ninjou maksaa tehdystä työstäsi palkan tilillesi ja oimittaa sinulle palkkakuitin.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="video">
          <div className="wrap">
            <div className="header">katso video</div>
            <div className="header-hr white"></div>
            <button className="play-button">
              <span>Pelata</span>
            </button>
          </div>
        </div>
        <div className="wrap">
          <div className="container">
            <div className="heading heart">
              <span>keitä me olemme</span>
            </div>
            <div className="team">
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="person">
                    <div className="photo">
                      <div
                        style={{
                          height: '100%',
                          backgroundImage: photoJani,
                          backgroundPosition: 'right center',
                        }}
                      />
                    </div>
                    <div className="header-text">
                      <span>Jani Puustinen</span>
                    </div>
                    <div className="header-hr"></div>
                    <div className="description">
                      <span>
                        Palkanlaskijana vuodesta 2004. Perusti Ninjou Oy:n toukokuussa 2013.
                      </span>
                      <div>Facebook</div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="person">
                    <div className="photo">
                      <div style={{ height: '100%', backgroundImage: photoTommi }} />
                    </div>
                    <div className="header-text">
                      <span>Tommi Taskinen</span>
                    </div>
                    <div className="header-hr"></div>
                    <div className="description">
                      <span>
                        Tommi Taskinen on ohjelmoijamme, joka vastaa tuotteen teknisestä
                        toimivuudesta.
                      </span>
                      <div>Facebook</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading letter">
              <span>ota yhteyttä</span>
            </div>
            <div className="plain-text">
              Ole rohkeasti meihin yhteydessä joko palautelomakkeella tai sähköpostilla. Palvelemme
              mieluusti.
            </div>
          </div>
        </div>
        <div className="map">
          <div className="wrap">
            <div className="container">
              <div className="visible-xs marker">
                Address: Lehtissaarentie 11 40900 Säynätsalo <br />
                Tel.: 045 679 0809 klo 09.00 - 16.00) <br />
                Email: admin@ninjou.fi
              </div>
              {/*
                <ContactForm
                  onSubmit={this.handleSubmit}
                  />
                  */}
              <div className="hidden-xs marker">
                Address: Lehtissaarentie 11 40900 Säynätsalo <br />
                Tel.: 045 679 0809 klo 09.00 - 16.00) <br />
                Email: admin@ninjou.fi
                <div className="point"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-footer">
          <div className="wrap">
            <div className="container">
              <div className="row">
                <ul className="right">
                  <li>
                    <Link to="/register">Rekisteröidy</Link>
                  </li>
                  <li>
                    <Link to="/login">Kirjaudu sisään</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Rekisteriseloste</Link>
                  </li>
                </ul>
                <ul className="left">
                  <li>© Ninjoy Oy 2017</li>
                  <li>Y-tunnus 2543310-1</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Landing
