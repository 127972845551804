import { update } from './personApi'
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { getObject } from '../../Localstorage/localStorageService'
import { path, mergeDeepRight } from 'ramda'

export function upatePersonWithApi(data) {
  const user = getObject('user')
  return update(path(['authtoken'], user), mergeDeepRight(user, data.data))
    .then(user => {
      return { response: user }
    })
    .catch(err => {
      return err
    })
}

export function* tryUpdatePerson(data) {
  const { response, error } = yield call(upatePersonWithApi, data)
  if (response) {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!UPDATE_PERSON_SUCCESS', duration: 3000, type: 'success' },
    })
    yield put({ type: 'UPDATE_PROFILE_SUCCESS', response })
  } else {
    yield put({
      type: 'SEND_NOTIFICATION',
      response: { message: 'LOCALIZED!UPDATE_PERSON_FAILURE', duration: 3000, type: 'error' },
    })
    yield put({ type: 'UPDATE_PROFILE_FAILURE', error })
  }
}

export function* updatePerson() {
  yield takeEvery('UPDATE_PROFILE', tryUpdatePerson)
}
