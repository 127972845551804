import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { Field, Formik } from 'formik'
import { connect } from 'react-redux'
import { path } from 'ramda'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

class CalculatorForm extends Component {
  constructor(props) {
    super(props)

    this.renderTab = this.renderTab.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  numberInputComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    disabled,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const changeValue = (event, form) => {
      let val = event.currentTarget.value.replace(/[^0-9.,]/g, '').replace(',', '.')
      if (!isNaN(val)) {
        const floatNum = parseFloat(val) || 0
        if (field.name === 'hours') {
          setFieldValue('price', floatNum * parseFloat(form.values.hourlyRate))
        }
        if (field.name === 'hourlyRate') {
          setFieldValue('price', floatNum * parseFloat(form.values.hours))
        }
        setFieldValue(field.name, val)
      }
    }
    let val = field.value
    if (typeof val === 'number') {
      val = val.toFixed(2)
    }
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <input
            {...field}
            type="text"
            disabled={disabled}
            className="form-control input-lg"
            onChange={(e) => changeValue(e, form)}
            value={val.toString().replace('.', ',')}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  checkboxInputComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    handleChange,
    disabled,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const changeValue = (event, form) => {
      setFieldValue(field.name, event.target.checked ? true : false)
    }
    return (
      <FormControlLabel
        control={
          <Checkbox
            className="scaled"
            {...field}
            onChange={(e) => changeValue(e, form)}
            // checked={!!this.state.billReimbursementsFromCustomer}
            //onChange={this.onBillCustomerChange}
            //value={!!this.state.billReimbursementsFromCustomer}
          />
        }
        label={translate('invoice.billReimbursementsFromCustomer')}
      />
    )
  }

  renderTab = (tabToRender, handleReset) => {
    const { translate, tab } = this.props
    const color = '#1aa6b7'
    return (
      <div
        className={tab === tabToRender ? 'tab active' : 'tab'}
        style={tab === tabToRender ? { color: color, borderColor: color } : {}}
        onClick={(e) => this.handleTabChange(e, tabToRender, handleReset)}
      >
        {translate('calculator.tab.' + tabToRender)}
      </div>
    )
  }

  handleTabChange = (event, tab, handleReset) => {
    event.preventDefault()
    this.props.changeTab(tab)
    handleReset()
  }

  render() {
    const { translate, user, opened, toggleState, onSubmit, tab } = this.props
    if (!user) return null
    let percent = path(['personSettings', 'taxpercent'], user) || 0
    percent = percent * 100

    const initialValues = {
      price: 0,
      hours: 0,
      hourlyRate: 0,
      alv: 0,
      taxPercent: percent,
      kilometers: 0,
      fulldaycompensation: 0,
      partialdaycompensation: 0,
      lunchcompensation: 0,
    }

    return (
      <Formik initialValues={initialValues} validate={this.validate} onSubmit={onSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          validateForm,
          handleReset,
          /* and other goodies */
        }) => (
          <form className="calculator-form dkblue" onSubmit={handleSubmit}>
            <div className="tabs calculator">
              <div className="row">
                <div className="col-xs-6 no-padding">{this.renderTab('normal', handleReset)}</div>
                <div className="col-xs-6 no-padding">{this.renderTab('hourly', handleReset)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6" style={tab === 'hourly' ? { display: 'none' } : {}}>
                <Field
                  name="price"
                  label={translate('calculator.form.price')}
                  placeholder={translate('calculator.form.pricePH')}
                  translate={translate}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  component={this.numberInputComponent}
                />
              </div>
              <div className="col-sm-6" style={tab === 'normal' ? { display: 'none' } : {}}>
                <Field
                  name="hours"
                  label={translate('calculator.form.hours')}
                  placeholder={translate('calculator.form.hoursPH')}
                  translate={translate}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  component={this.numberInputComponent}
                />
              </div>
              <div className="col-sm-6" style={tab === 'normal' ? { display: 'none' } : {}}>
                <Field
                  name="hourlyRate"
                  label={translate('calculator.form.hourlyRate')}
                  placeholder={translate('calculator.form.hourlyRatePH')}
                  translate={translate}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  component={this.numberInputComponent}
                />
              </div>
              <div className="col-xs-12" style={tab === 'normal' ? { display: 'none' } : {}}>
                <div className="summary">
                  <span className="title">{translate('calculator.form.price')}</span>
                  <span className="sum pull-right">{parseFloat(values.price).toFixed(2).toString().replace('.', ',')} €</span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>{translate('calculator.form.alv')}</label>
                  <Field component="select" className="form-control" name="alv">
                    <option value="0">0%</option>
                    <option value="10">10%</option>
                    <option value="14">14%</option>
                    <option value="24">24%</option>
                  </Field>
                </div>
              </div>
              <div className="col-sm-6">
                <Field
                  name="taxPercent"
                  label={translate('calculator.form.taxPercent')}
                  placeholder={translate('calculator.form.taxPercent')}
                  translate={translate}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  component={this.numberInputComponent}
                />
              </div>
              <div className="col-xs-12">
                <div className="row">
                  <div className={opened ? 'toggle-additional opened' : 'toggle-additional'}>
                    <div className="additional-label noselect" onClick={toggleState}>
                      <div>{translate('calculator.form.additional')}</div>
                    </div>
                    <div className="additional">
                      <div className="col-sm-6">
                        <Field
                          name="kilometers"
                          min="0"
                          step="any"
                          label={translate('calculator.form.kilometers')}
                          placeholder={translate('calculator.form.kilometersPH')}
                          translate={translate}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          component={this.numberInputComponent}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="fulldaycompensation"
                          min="0"
                          step="any"
                          label={translate('calculator.form.fullDaily')}
                          placeholder={translate('calculator.form.fullDailyPH')}
                          translate={translate}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          component={this.numberInputComponent}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="partialdaycompensation"
                          min="0"
                          step="any"
                          label={translate('calculator.form.semiDaily')}
                          placeholder={translate('calculator.form.semiDailyPH')}
                          translate={translate}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          component={this.numberInputComponent}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="lunchcompensation"
                          min="0"
                          step="any"
                          label={translate('calculator.form.meal')}
                          placeholder={translate('calculator.form.mealPH')}
                          translate={translate}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          component={this.numberInputComponent}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pull-right col-xs-6 col-sm-3">
                <button type="submit" className="btn btn-success">
                  {translate('calculator.form.buttonText')}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorForm)
