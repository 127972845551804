import React from 'react'

export const CustomTravelTimeComponent = ({
  field, // { name, value, onChange, onBlur }
  translate,
  label,
  handleChange,
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const val = field.value
  let hours = 0
  let minutes = 0
  if (val >= 0) {
    hours = Math.floor(val / 60)
    minutes = val % 60
  }

  const displayValue = `${hours} ${translate('invoice.reimbModal.hours')} ${minutes} ${translate('invoice.reimbModal.minutes')}`
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>
        <input
          disabled={true}
          type="text"
          style={{ width: '100%', minWidth: '96%', lineHeight: '50px' }}
          className="form-control mobile-date"
          value={displayValue}
        />
        {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
      </div>
    </div>
  )
}
