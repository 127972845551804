import { CALCULATE_FROM_BILL, CALCULATE_SALLARY } from './calculatorActions'

const calculatorReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_SALLARY + '_SUCCESS':
      return {
        ...state,
        lastCalculated: action.response,
      }
    case CALCULATE_SALLARY + '_FAILURE':
      return {
        ...state,
        lastCalculated: {},
      }
    case CALCULATE_FROM_BILL + '_SUCCESS':
      return {
        ...state,
        lastCalculated: action.response,
      }
    case CALCULATE_FROM_BILL + '_FAILURE':
      return {
        ...state,
        lastCalculated: {},
      }

    default:
      return state
  }
}

export default calculatorReducer
