import { filter } from 'ramda'
import { GET_INFO, SEARCH_CUSTOMERS, TAX_INFO } from './infoActions'

import { CREATE_CUSTOMER } from '../../User/userActions'

import {
  APPROVE_SUBCONTRACTOR_ITEM,
  DECLINE_SUBCONTRACTOR_ITEM,
} from '../SubContractor/subContractorActions'

const defState = {
  info: {
    paidSalariesThisYear: 0,
    openAmount: 0,
    approvedAmount: 0,
    paidAmount: 0,
    taxAmountThisYear: 0,
    paidSalaries: 0,
    provision: 0,
    jotain: 0,
  },
  taxInfo: {},
}

const infoReducer = (state = defState, action) => {
  switch (action.type) {
    case GET_INFO + '_SUCCESS':
      return {
        ...state,
        info: action.response,
      }
    case GET_INFO + '_FAILURE':
      return {
        ...state,
        info: {},
      }
    case SEARCH_CUSTOMERS + '_SUCCESS':
      return {
        ...state,
        foudCustomers: action.response,
      }
    case SEARCH_CUSTOMERS + '_FAILURE':
      return {
        ...state,
        foudCustomers: [],
      }
    case CREATE_CUSTOMER + '_SUCCESS':
      return {
        ...state,
        foudCustomers: [...state.foudCustomers, action.response],
      }
    case APPROVE_SUBCONTRACTOR_ITEM + '_SUCCESS':
      let contractorItems = filter(
        item => item.id !== action.response.item.id,
        state.info.subcontractorBillableItems,
      )
      return {
        ...state,
        info: {
          ...state.info,
          subcontractorBillableItems: [...contractorItems, action.response.item],
        },
      }
    case DECLINE_SUBCONTRACTOR_ITEM + '_SUCCESS':
      let items = filter(
        item => item.id !== action.response.item.id,
        state.info.subcontractorBillableItems,
      )
      return {
        ...state,
        info: {
          ...state.info,
          subcontractorBillableItems: [...items, action.response.item],
        },
      }
    case TAX_INFO + '_SUCCESS':
      return {
        ...state,
        taxInfo: action.response,
      }
    default:
      return state
  }
}

export default infoReducer
