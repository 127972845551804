// We only need to import the modules necessary for initial render
import React from 'react'
import { Route, IndexRoute } from 'react-router'

import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import Home from './Home/components/HomeView'
import LoginRoute from './Login'
import RegisterRoute from './Register'
import RegisterStartedRoute from './Register/registerStarted'
import registerConfirms from './Register/registerConfirms'
import InvoicesRoute from './Invoices'
import ArchiveRoute from './Archive'
import CustomersRoute from './Customers'
import SalariesRoute from './Salaries'
import CalculatorRoute from './Calculator'
import AccountRoute from './Account'
import TemplatesRoute from './Templates'
import PrivacyRoute from './Privacy'
import AboutRoute from './About'
import SingleInvoice from './SingleInvoice'
import LogoutRoute from './Logout'
import PasswordRoute from './Password'
import EulaRoute from './Eula'
import ReimbursementsView from './TravelReimbursements'
import LoginWithToken from './LoginWithToken'
// import Landing from './Landing'
/*  Note: Instead of using JSX, we recommend using react-router
 PlainRoute objects to build route definitions.   */

const routes = (
  <Route path="/" component={CoreLayout}>
    <IndexRoute component={Home} />
    {LoginRoute}
    {InvoicesRoute}
    {RegisterRoute}
    {ArchiveRoute}
    {CustomersRoute}
    {SalariesRoute}
    {CalculatorRoute}
    {AccountRoute}
    {TemplatesRoute}
    {PrivacyRoute}
    {AboutRoute}
    {SingleInvoice}
    {LogoutRoute}
    {RegisterStartedRoute}
    {registerConfirms}
    {PasswordRoute}
    {EulaRoute}
    {ReimbursementsView}
    {LoginWithToken}
  </Route>
)

export default routes

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
 using getChildRoutes with the following signature:

 getChildRoutes (location, cb) {
 require.ensure([], (require) => {
 cb(null, [
 // Remove imports!
 require('./Counter').default(store)
 ])
 })
 }

 However, this is not necessary for code-splitting! It simply provides
 an API for async route definitions. Your code splitting should occur
 inside the route `getComponent` function, since it is only invoked
 when the route exists and matches.
 */
