export const isoDateToDate = (date) => {
  if (!date) {
    return ''
  }
  const dateArr = date.split('-')
  if (dateArr.length === 3) {
    return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`
  }
}
export const dateToIsoDate = (date) => {
  if (!date) {
    return ''
  }
  const dateArr = date.split('.')
  if (dateArr.length === 3) {
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
}

export const isoTimeToTime = (date) => {
  if (!date) {
    return ''
  }
  return date.replace(':', '.')
}

export const timeToIsoTime = (date) => {
  if (!date) {
    return ''
  }
  return date.replace('.', ':')
}

