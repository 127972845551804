import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import '../../Customers/components/customers.scss'
import '../../../components/Client/client.scss'
import { summary } from '../../../components/BillEditor/Summary'
import { clone } from 'ramda'
import './advance-payment.scss'

class AskAdvancePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bill: clone(props.bill),
      addAll: false,
      billableitems: [
        ...clone(props.bill.billableitems.filter((item) => !item.useAsAdvancePayment && !item.advancePaymentRequested)).map((item) => ({
          ...item,
          advancePaymentRequested: true,
        })),
      ],
    }
    this.props.calculate({
      ...this.state.bill,
      billableitems: this.state.billableitems,
    })
  }

  closeModal() {
    this.props.cancel()
  }

  calculateSummary() {
    const items = this.state.billableitems.filter((item) => item.advancePaymentRequested === true)
    this.props.calculate({
      ...this.state.bill,
      billableitems: items,
    })
  }

  createRequest(event, billableitems) {
    event.preventDefault()
    const itemIdsToAsk = billableitems.filter((item) => item.advancePaymentRequested === true).map((item) => item.id)
    this.props.handleSubmit({
      ids: itemIdsToAsk,
    })
  }

  getItemsWithRequested = () => {
    const items = this.state.billableitems.filter((item) => item.advancePaymentRequested)
    return items
  }
  addAllItems = (e) => {
    e.preventDefault()
    const items = this.state.billableitems.map((bi) => {
      bi.advancePaymentRequested = this.state.addAll
      return bi
    })
    this.setState({
      ...this.state,
      addAll: !this.state.addAll,
      billableitems: items,
    })
    this.calculateSummary()
  }
  handleChange = (event, item) => {
    this.setState({
      ...this.state,
      billableitems: this.state.billableitems.map((bi) => {
        if (bi.id === item.id) {
          bi.advancePaymentRequested = !bi.advancePaymentRequested
        }
        return bi
      }),
    })
    const items = this.state.billableitems
      .filter((item) => item.advancePaymentRequested)
      .map((item) => ({
        ...item,
        useAsAdvancePayment: true,
      }))
    this.props.calculate({
      ...this.state.bill,
      billableitems: items,
    })
  }
  submit = (event) => {
    const items = this.getItemsWithRequested()
    if (items.length === 0) {
      event.preventDefault()
      this.props.sendNotifications('NO_ROWS_SELECTED_FOR_ADVANVE_PAYMENT', 'warning')
    }
  }

  render() {
    const { translate, partialCalculator } = this.props
    const { billableitems } = this.state
    const fullSalary = partialCalculator
    const selectText = this.state.addAll ? translate('invoice.selectAll') : translate('invoice.clearAll')
    const rows = billableitems.map((item) => {
      return (
        <tr key={item.id}>
          <td>
            <input id={item.id} checked={item.advancePaymentRequested} type="checkbox" onChange={(event) => this.handleChange(event, item)} />
          </td>
          <td className="padding-left-15">
            <label htmlFor={item.id}>{item.destination}</label>
          </td>
          <td className="advance-payment-right">
            <label htmlFor={item.id}>{item.priceWithoutAlv} €</label>
          </td>
        </tr>
      )
    })
    const disabled = this.getItemsWithRequested().length === 0
    const style = disabled ? 'disabled' : ''
    const buttonStyle = `${style} btn btn-accept`
    const AskAdvancePaymentForm = (
      <form onSubmit={(event) => this.createRequest(event, billableitems)}>
        <div className="row">
          <div className="col-sm-12">
            <table className="advance-payment-table" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </tbody>
              <tbody>{rows}</tbody>
            </table>
          </div>
          <div className="col-xs-4" style={{ marginTop: '10px' }}>
            <button className="btn btn-accept" onClick={(e) => this.addAllItems(e)}>
              {selectText}
            </button>
          </div>
          <div className="summary col-xs-12">
            <span className="title sum dkblue">{translate('invoice.summary')}</span>

            {summary(fullSalary, this.state.bill, this.props.user, translate)}
          </div>
          <div className="col-xs-12" style={{ marginTop: '25px' }}>
            <div className="col-sm-6 col-xs-6">
              <button className="btn btn-cancel" onClick={() => this.closeModal()}>
                {translate('invoice.cancel')}
              </button>
            </div>
            <div className="col-sm-6 col-xs-6">
              <button className={buttonStyle} onClick={(e) => this.submit(e)} type="submit">
                {translate('invoice.askAdvancePayment')}
              </button>
            </div>
          </div>
        </div>
      </form>
    )

    return <div>{AskAdvancePaymentForm}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    partialCalculator: state.partialCalculator,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    calculate: (data) => dispatch({ type: 'CALCULATE_FROM_PARTIAL_BILL', data }),
    sendNotifications: (message) =>
      dispatch({
        type: 'SEND_NOTIFICATION',
        response: { message: `LOCALIZED!${message}`, type: 'error' },
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AskAdvancePayment)
