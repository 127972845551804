import {apiCall} from '../../services/api'

export function registerPerson(user) {
  return apiCall(`/api/registration/start`, 'post', null, user)
}

export function getUserByTokenFromApi(token) {
  return apiCall(`/api/registration/confirm?token=${token}`, 'get', null)
}

export function agreeEulaApi(user) {
  return apiCall(`/api/registration/confirm`, 'put', null, user)
}

export function bankConfirmedApi(data) {
  return apiCall(`/api/registration/oidc/finish`, 'put', null, data)
}
