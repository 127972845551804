import React, { Component } from 'react'
import { connect } from 'react-redux'
import './register.scss'
import { getTranslate } from 'react-localize-redux'

class RegisterView extends Component {
  render() {
    const { translate } = this.props
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <h1>{translate('register.failed')}</h1>
          <p className="lead">{translate('register.failedBody')}</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView)
