import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

class RegisterSuccessView extends Component {
  render() {
    const { translate } = this.props
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <h1>{translate('register.registerCompleteHead')}</h1>
          <p className="lead">{translate('register.registerCompleteBody1')}</p>
          <p className="lead">{translate('register.registerCompleteBody2')}</p>

          <a className="btn btn-large btn-primary" href="/login">{translate('register.registerCompleteButton')}</a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSuccessView)
