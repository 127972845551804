import React, { Component } from 'react'
import 'moment/locale/fi'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import 'rc-time-picker/assets/index.css'
import 'react-day-picker/lib/style.css'
import './modals.scss'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { path } from 'ramda'
import { Button, Modal } from 'react-bootstrap'
import { throttle } from 'throttle-debounce'
import { Field, FieldArray, Formik } from 'formik'
import Select from 'react-select'
import { connect } from 'react-redux'
import axios from 'axios'
import { getObject } from '../Localstorage/localStorageService'
import { CustomCheckboxComponent } from '../FormComponents/CustomCheckboxComponent'
import { renderDateField } from '../FormComponents/DateField'
import { CustomInputComponent } from '../FormComponents/CustomInputComponent'
import { Error } from './Error'

import { RouteSelectWithAddresses } from './RouteSelectWithAddresses'
import { CustomRouteComponent } from './CustomRouteComponent'
import { CustomWaypointComponent } from './CustomWaypointComponent'
import { reactSelectStyles } from '../../styles/ReactSelectStyles'
import { CustomTravelTimeComponent } from '../FormComponents/CustomTravelTimeComponent'

require('./reimbursementForm.css')
const validateFn = (values) => {
  const errors = {}
  if (values.time && values.startdate && values.returndate && values.returntime) {
    const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
    const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
    const diffInMinuts = end.diff(start, 'minutes')
    const travelTime = values.travelTimeInMinutes || 0
    values.workTimeInMinutes = diffInMinuts - travelTime
    values.totalTime = diffInMinuts
  }

  if (values.fulldaycompensation) {
    if (values.kilometers < 30) {
      errors.fulldaycompensation = 'validation.notEnoughKilometers'
    }
    if (values.lunchcompensation) {
      errors.lunchcompensation = 'validation.notWithComensations'
    }
    if (values.time && values.startdate && values.returndate && values.returntime) {
      const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
      const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
      let diffHours = end.diff(start, 'hours')
      let compensations = values.fulldaycompensation + values.partialdaycompensation

      const neededTimeForCompensation = (compensations - 1) * 24 + 10
      if (diffHours < neededTimeForCompensation) {
        errors.fulldaycompensation = 'validation.travelNotLongEnough'
      }
    } else {
      if (!values.time) {
        errors.time = 'validation.required'
      }
      if (!values.startdate) {
        errors.startdate = 'validation.required'
      }
      if (!values.returndate) {
        errors.returndate = 'validation.required'
      }
      if (!values.returntime) {
        errors.returntime = 'validation.required'
      }
    }
  }
  if (values.partialdaycompensation) {
    if (values.lunchcompensation) {
      errors.lunchcompensation = 'validation.notWithComensations'
    }
    if (values.kilometers < 30) {
      errors.partialdaycompensation = 'validation.notEnoughKilometers'
    }
    if (values.time && values.startdate && values.returndate && values.returntime) {
      const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
      const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
      let diffHours = end.diff(start, 'hours')
      let compensations = values.fulldaycompensation + values.partialdaycompensation

      const neededTimeForCompensation = (compensations - 1) * 24 + 6
      if (diffHours < neededTimeForCompensation) {
        errors.partialdaycompensation = 'validation.travelNotLongEnough'
      }
    }
  }

  if (values.kilometers) {
    if (!values.vehicleRegisterNumber) {
      if (values.vehicle === '0' || values.vehicle === 0) {
        errors.vehicleRegisterNumber = 'validation.required'
      }
    }
    if (!values.addressStart) {
      errors.addressStart = 'validation.required'
    }
    if (!values.addressStop) {
      errors.addressStop = 'validation.required'
    }
  }

  if (values.lunchcompensation) {
    if (values.time && values.startdate && values.returndate && values.returntime) {
      const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
      const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
      const endOfDay = start.clone().add(1, 'days').startOf('day')
      let ateriaKorvaukset = 0
      let diffInHours = end.diff(start, 'hours')
      if (values.travelTimeInMinutes) {
        diffInHours = diffInHours - values.travelTimeInMinutes / 60
      }
      if (diffInHours >= 6) {
        ateriaKorvaukset++
        diffInHours = diffInHours - 6
        if (diffInHours > 5) {
          ateriaKorvaukset++
          diffInHours = diffInHours - 5
        }
      }

      let diffOnNextDay = end.diff(endOfDay, 'hours')
      while (diffOnNextDay > 0) {
        if (diffOnNextDay >= 6) {
          ateriaKorvaukset++
          diffOnNextDay = diffOnNextDay - 6
        } else {
          break
        }
        if (diffOnNextDay > 5) {
          ateriaKorvaukset++
          diffOnNextDay = diffOnNextDay - 5
        } else {
          break
        }
        diffOnNextDay = diffOnNextDay - 13
      }
      if (values.lunchcompensation > ateriaKorvaukset) {
        errors.lunchcompensation = 'validation.tooManyLuchCompensations'
      }
    } else {
      errors.lunchcompensation = 'validation.traveltimeNotEntered'
    }
  }
  if (values.partialdaycompensation || values.fulldaycompensation) {
    if (!values.time) {
      errors.time = 'validation.required'
    }
    if (!values.returntime) {
      errors.returntime = 'validation.required'
    }
    if (!values.kilometers) {
      // errors.kilometers = 'validation.required'
    }
  }
  return errors
}

class ReimbursementForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startAddress: [],
      endAddress: [],
    }
    const user = getObject('user')
    this.authtoken = path(['authtoken'], user)
    this.CustomRouteComponent = this.CustomRouteComponent.bind(this)
    this.searchAddress = throttle(1000, this.searchAddress.bind(this), { noLeading: true, noTrailing: false })
    this.getDistance = this.getDistance.bind(this)
  }

  RouteSelectWithAddresses = (props) => RouteSelectWithAddresses({ ...props, self: this })
  CustomRouteComponent = (props) => CustomRouteComponent({ ...props, self: this })
  CustomWaypointComponent = (props) => CustomWaypointComponent({ ...props, self: this })
  searchAddress = (query, field, fn) => {
    if (!query) {
      return Promise.resolve()
    }
    const options = {
      method: 'get',
      url: `api/address?address=${query}`,
      headers: {
        'Content-Type': 'application/json',
        authtoken: this.authtoken,
      },
    }
    return axios(options).then((reply) => {
      const { data } = reply
      if (fn) {
        fn(data.addresses)
      }
      return data.addresses
    })
  }

  searchAddressByCoordinates = (coords, field) => {
    return axios
      .post(`api/address/coordinates`, coords, {
        headers: {
          'Content-Type': 'application/json',
          authtoken: this.authtoken,
        },
      })
      .then((reply) => {
        return {
          label: reply.data.formatted_address,
          value: reply.data.formatted_address,
          addresses: reply.data.addresses,
        }
      })
  }

  getDistance = (from, to, wayPoint, roundtrip) => {
    const options = {
      data: {
        from,
        to,
        wayPoint,
        roundtrip: !!roundtrip,
      },
      method: 'post',
      url: `api/address/distance`,
      headers: {
        'Content-Type': 'application/json',
        authtoken: this.authtoken,
      },
    }
    return axios(options)
  }

  CustomVehicleComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    disabled,
    label,
    handleChange,
    values,
    form,
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const onRouteChange = async (value, field, setFieldValue, values, form) => {
      // field.value = value.value
      if (value) {
        await setFieldValue(field.name, value.value)
      }
    }

    const vehicleTypes = [
      {
        label: translate('invoice.reimbModal.vehicleOptions.0'),
        value: '0',
      },
      {
        label: translate('invoice.reimbModal.vehicleOptions.7'),
        value: '7',
      },
    ]
    const selectedValue = vehicleTypes.find((val) => val.value === field.value)
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <Select
            disabled={disabled}
            options={vehicleTypes}
            value={selectedValue}
            placeholder={translate('misc.select')}
            inputProps={{ className: 'form-control22' }}
            clearable={false}
            autoload={false}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            onCloseResetsInput={false}
            filterOptions={(options, filter, currentValues) => {
              // Do no filtering, just return all options
              return options
            }}
            onChange={(value) => onRouteChange(value, field, setFieldValue, values, form)}
            styles={reactSelectStyles}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  setKilometers(field, value, values, setFieldValue) {
    let roundtrip = values.roundtrip ? 'TRUE' : 'FALSE'
    let from
    let to
    let wayPoint = []
    const name = field.name ? field.name : field
    if (name === 'addressStart') {
      from = value.value
      wayPoint = values.wayPoint
      roundtrip = values.roundtrip
      to = values.addressStop
    } else if (name === 'addressStop') {
      from = values.addressStart
      wayPoint = values.wayPoint
      roundtrip = values.roundtrip
      to = value.value
    } else if (name === 'roundtrip') {
      from = values.addressStart
      to = values.addressStop
      wayPoint = values.wayPoint
      roundtrip = value
    } else {
      from = values.addressStart
      to = values.addressStop
      roundtrip = values.roundtrip
      wayPoint = values.wayPoint
      wayPoint[name.split('.')[1]] = value.value
    }
    if (from && to) {
      this.getDistance(from, to, wayPoint, roundtrip).then((response) => {
        if (response && response.data) {
          const start = moment(values.startdate + ' ' + values.time, 'DD.MM.YYYY hh:mm')
          const end = moment(values.returndate + ' ' + values.returntime, 'DD.MM.YYYY hh:mm')
          let diffInMinutes = end.diff(start, 'minutes') - response.data.travelTimeInMinutes
          if (values.roundtrip) {
            diffInMinutes = diffInMinutes * 2
          }
          setFieldValue('workTimeInMinutes', diffInMinutes)
          setFieldValue('kilometers', response.data.distance)
          setFieldValue('travelTimeInMinutes', response.data.travelTimeInMinutes)
        }
      })
    }
  }

  RouteCheckboxComponent = ({
    field, // { name, value, onChange, onBlur }
    translate,
    label,
    disabled,
    placeholder,
    form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const change = (val, field, values, setFieldValue) => {
      const isChecked = val.target.checked ? true : false
      this.setKilometers(field, isChecked, values, setFieldValue)
      setFieldValue(field.name, val.target.checked)
    }
    return (
      <div className="form-group">
        <label>{label}</label>
        <div className="cno-padding-top nomargin form-control">
          <FormControlLabel
            control={
              <Checkbox
                className="scaled"
                id={field.name}
                checked={!!field.value}
                onChange={(val) => change(val, field, values, setFieldValue)}
                disabled={disabled}
                value={!!field.value}
              />
            }
            label={placeholder || label}
          />
          {errors[field.name] && touched[field.name] && <span className="field-error">{translate(errors[field.name])}</span>}
        </div>
      </div>
    )
  }

  removeWaypoint = (arrayHelpers, index, values, setFieldValue) => {
    const from = values.addressStart
    const to = values.addressStop
    const roundtrip = values.roundtrip
    const wayPoint = values.wayPoint.splice(index, index)
    this.getDistance(from, to, wayPoint, roundtrip).then((response) => {
      if (response && response.data) {
        setFieldValue('kilometers', response.data.distance)
      }
    })
    arrayHelpers.remove(index)
  }

  fetchAddressFromCoordinates = async (setFieldValue, fieldName, values) => {
    const { geolocation, permissions } = navigator
    let fieldStateForIsSearching = `loading_${fieldName}`
    const isOpenStr = `menuOpen${fieldName}`
    this.setState({
      [fieldStateForIsSearching]: true,
      [isOpenStr]: true,
    })
    geolocation.getCurrentPosition(
      (position) => {
        const coords = {
          accuracy: position.coords.accuracy,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        this.searchAddressByCoordinates(coords, fieldName).then(async (address) => {
          const field = `${[fieldName]}Routes`
          this.setState({
            ...this.state,
            [fieldStateForIsSearching]: false,
          })

          this.setState({
            ...this.state,
            [field]: address.addresses,
          })
        })
      },
      (failure) => {
        permissions.query({ name: 'geolocation' }).then((p) => {
          switch (p.state) {
            case 'denied':
              this.setState({
                ...this.state,
                permissionDenied: true,
              })
              break
            case 'granted':
              this.setState({
                ...this.state,
                permissionDenied: false,
              })
              break
            case 'prompt':
              break
            default:
              break
          }
        })
      }
    )
  }

  render() {
    const { handleSubmit, disabled, translate, locale, onHide, show, user, initialValues } = this.props
    const hideModal = () => {
      this.setState({
        ...this.state,
        addressStartRoutes: [],
        menuOpenaddressStart: false,
        addressStopRoutes: [],
        menuOpenaddressStop: false,
      })
      onHide()
    }
    const { permissionDenied } = this.state
    const disabledSearch = user?.personSettings?.disableAddressSearch
    return (
      <Formik
        initialValues={initialValues}
        validate={validateFn}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm /* and other goodies */ }) => (
          <Modal className="timeModal details" animation={false} show={show} dialogClassName="modal-90w" onHide={hideModal} backdrop={'static'}>
            <div className="valign-helper"></div>

            <form className="clearfix account-form" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{translate('invoice.row.travelReimbursement')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {permissionDenied && <Error msg={translate('invoice.reimbModal.noPermission')}></Error>}
                <ReactTooltip id="global-invoice-disabled-tooltip" place="top" type="info" effect="solid">
                  {translate('tooltip.invoiceDisabled')}
                </ReactTooltip>
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group">
                          <label className="dkblue">{translate('invoice.reimbModal.departure')}</label>
                          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                            <Field
                              disabled={disabled}
                              name="startdate"
                              type="time"
                              translate={translate}
                              locale={locale}
                              calculateWorkTime={true}
                              component={renderDateField}
                            />
                          </div>
                          <Field component="input" style={{ display: 'none' }} name="startdate" />
                        </div>
                        <div className="form-group time-to-route">
                          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                            <Field
                              disabled={disabled}
                              name="time"
                              label={translate('invoice.reimbModal.departureTime')}
                              translate={translate}
                              component={CustomInputComponent}
                              type="time"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <div className="form-group">
                          <label className="dkblue">{translate('invoice.reimbModal.arrival')}</label>
                          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                            <Field
                              disabled={disabled}
                              name="returndate"
                              calculateWorkTime={true}
                              translate={translate}
                              locale={locale}
                              component={renderDateField}
                            />
                          </div>
                        </div>
                        <div className="form-group time-to-route">
                          <div data-tip data-iscapture="true" data-for="global-invoice-disabled-tooltip" data-tip-disable={!disabled}>
                            <Field
                              disabled={disabled}
                              name="returntime"
                              label={translate('invoice.reimbModal.arrivalTime')}
                              translate={translate}
                              component={CustomInputComponent}
                              type="time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <label className="dkblue">{translate('invoice.reimbModal.travelInfo')}</label>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <Field
                          disabled={disabled}
                          name="vehicle"
                          type="text"
                          label={translate('invoice.reimbModal.vehicleType')}
                          translate={translate}
                          values={values}
                          handleChange={handleChange}
                          component={this.CustomVehicleComponent}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <Field
                          disabled={disabled}
                          name="vehicleRegisterNumber"
                          type="text"
                          label={translate('invoice.reimbModal.regNubmer')}
                          translate={translate}
                          component={CustomInputComponent}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        {user?.personSettings?.disableAddressSearch && (
                          <Field
                            disabled={disabled}
                            name="addressStart"
                            type="text"
                            label={translate('invoice.reimbModal.addressStart')}
                            translate={translate}
                            selectClass="width75 float-left"
                            values={values}
                            routes={this.state.addressStartRoutes}
                            setKilometers={this.setKilometers}
                            handleChange={handleChange}
                            component={this.RouteSelectWithAddresses}
                          >
                            <div className="width25 float-left">
                              <Button
                                className="col-xs-6 col-sm-3"
                                onClick={() => this.fetchAddressFromCoordinates(setFieldValue, 'addressStart', values)}
                              >
                                Hae
                              </Button>
                            </div>
                          </Field>
                        )}
                        {!user?.personSettings?.disableAddressSearch && (
                          <Field
                            disabled={disabled}
                            disabledSearch={disabledSearch}
                            name="addressStart"
                            type="text"
                            label={translate('invoice.reimbModal.addressStart')}
                            translate={translate}
                            selectClass="width75 float-left"
                            values={values}
                            routes={this.state.addressStartRoutes}
                            handleChange={handleChange}
                            component={this.CustomRouteComponent}
                          >
                            <div className="width25 float-left">
                              <Button
                                className="col-xs-6 col-sm-3"
                                onClick={() => this.fetchAddressFromCoordinates(setFieldValue, 'addressStart', values)}
                              >
                                Hae
                              </Button>
                            </div>
                          </Field>
                        )}
                        <div className="row waypoints">
                          <FieldArray
                            name="wayPoint"
                            render={(arrayHelpers) => (
                              <>
                                {values.wayPoint && values.wayPoint.length > 0 ? (
                                  values.wayPoint.map((friend, index) => (
                                    <Field
                                      key={index}
                                      name={`wayPoint.${index}`}
                                      component={this.CustomWaypointComponent}
                                      handleChange={handleChange}
                                      disabledSearch={disabledSearch}
                                      disabled={disabled}
                                      state={this.state}
                                      translate={translate}
                                      arrayHelpers={arrayHelpers}
                                      index={index}
                                      values={values}
                                    ></Field>
                                  ))
                                ) : (
                                  <span></span>
                                )}
                                <Button className="link-add" onClick={() => arrayHelpers.push('')}>
                                  <i className="glyphicon glyphicon-plus green" />
                                  <span>{translate('invoice.reimbModal.addWaypoint')}</span>
                                </Button>
                              </>
                            )}
                          />
                        </div>
                        {user?.personSettings?.disableAddressSearch && (
                          <Field
                            disabled={disabled}
                            disabledSearch={disabledSearch}
                            name="addressStop"
                            type="text"
                            label={translate('invoice.reimbModal.addressStop')}
                            translate={translate}
                            selectClass="width75 float-left"
                            values={values}
                            routes={this.state.addressStopRoutes}
                            setKilometers={this.setKilometers}
                            handleChange={handleChange}
                            component={this.RouteSelectWithAddresses}
                          >
                            <div className="width25 float-left">
                              <Button
                                className="col-xs-6 col-sm-3"
                                onClick={() => this.fetchAddressFromCoordinates(setFieldValue, 'addressStop', values)}
                              >
                                Hae
                              </Button>
                            </div>
                          </Field>
                        )}
                        {!user?.personSettings?.disableAddressSearch && (
                          <Field
                            disabled={disabledSearch}
                            disabledSearch={disabledSearch}
                            name="addressStop"
                            type="text"
                            label={translate('invoice.reimbModal.addressStop')}
                            translate={translate}
                            selectClass="width75 float-left"
                            values={values}
                            routes={this.state.addressStopRoutes}
                            handleChange={handleChange}
                            component={this.CustomRouteComponent}
                          >
                            <div className="width25 float-left">
                              <Button
                                className="col-xs-6 col-sm-3"
                                onClick={() => this.fetchAddressFromCoordinates(setFieldValue, 'addressStop', values)}
                              >
                                Hae
                              </Button>
                            </div>
                          </Field>
                        )}
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <Field
                          name="kilometers"
                          type="number"
                          label={translate('invoice.reimbModal.kilometers')}
                          component={CustomInputComponent}
                          translate={translate}
                          disabled={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <Field
                          name="totalTime"
                          type="number"
                          label={translate('invoice.reimbModal.totalTime')}
                          component={CustomTravelTimeComponent}
                          translate={translate}
                          disabled={true}
                        />
                        <Field
                          name="travelTimeInMinutes"
                          type="number"
                          label={translate('invoice.reimbModal.travelTimeInMinutes')}
                          component={CustomTravelTimeComponent}
                          translate={translate}
                          disabled={true}
                        />
                        <Field
                          name="workTimeInMinutes"
                          type="number"
                          label={translate('invoice.reimbModal.workTimeInMinutes')}
                          component={CustomTravelTimeComponent}
                          translate={translate}
                          disabled={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6"></div>
                    </div>
                  </div>

                  <div className="col-xs-12 padding-top">
                    <div className="row margin-bottom">
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          disabled={disabled}
                          name="roundtrip"
                          id="roundtrip"
                          label={translate('invoice.reimbModal.roundtrip')}
                          onChange={handleChange}
                          values={values}
                          className="no-padding-top"
                          component={this.RouteCheckboxComponent}
                          translate={translate}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          name="fulldaycompensation"
                          disabled={disabled}
                          type="number"
                          label={translate('invoice.reimbModal.fulldaycompensation')}
                          component={CustomInputComponent}
                          translate={translate}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          name="partialdaycompensation"
                          disabled={disabled}
                          type="number"
                          label={translate('invoice.reimbModal.partialdaycompensation')}
                          component={CustomInputComponent}
                          translate={translate}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          disabled={disabled}
                          name="foreignAllowance"
                          type="number"
                          label={translate('invoice.reimbModal.foreignAllowance')}
                          component={CustomInputComponent}
                          translate={translate}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          disabled={disabled}
                          name="lunchcompensation"
                          type="number"
                          label={translate('invoice.reimbModal.lunchcompensation')}
                          component={CustomInputComponent}
                          translate={translate}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-4">
                        <Field
                          disabled={disabled}
                          name="showInResult"
                          id="showInResult"
                          nomargin
                          label={translate('invoice.reimbModal.showInResult')}
                          className="no-padding-top"
                          component={CustomCheckboxComponent}
                          onChange={handleChange}
                          translate={translate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="row">
                  <div className="col-xs-6">
                    <Button variant="cancel" onClick={onHide}>
                      {translate('misc.cancel')}
                    </Button>
                  </div>
                  <div className="col-xs-6">
                    <Button variant="success" disabled={disabled} type="submit">
                      {translate('misc.confirm')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementForm)
