import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubcontratorModal from './SubcontractorModal'
import {
  searchSubcontractors,
  createSubcontracorAction,
} from '../../api/SubContractor/subContractorActions'
class ExternalBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({
      modalOpen: true,
    })
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    })
  }

  handleSubmit = (values) => {
    // const { createExternalItem, selectedBill } = this.props
  }

  render() {
    const { translate, selectedBill, upload, user, locale, subcontractors } = this.props
    const { receipts } = this.state
    const disabled = selectedBill.state !== 'OPEN'
    return (
      <div>
        <div className="col-sm-4 medium-margin">
          <button
            disabled={!selectedBill.id || selectedBill.state !== 'OPEN'}
            type="button"
            className="btn btn-accept medium-margin"
            onClick={this.openModal}
          >
            <span>
              <span className="glyphicon icon-plus-white"></span>
              {translate('invoice.addSubcontractor')}
            </span>
          </button>
        </div>
        <SubcontratorModal
          bill={selectedBill}
          show={this.state.modalOpen}
          lcoale={locale}
          translate={translate}
          closeModal={this.closeModal}
          handleSubmit={this.handleSubmit}
          onReceiptAdd={this.onReceiptAdd}
          handleDelete={this.handleDelete}
          subcontractors={subcontractors}
          upload={upload}
          receipts={receipts}
          user={user}
          locale={locale}
          disabled={disabled}
          initialValues={{
            amount: 0,
            name: '',
            fullprice: '',
            referenceNumber: '',
            accountNumber: '',
            paynow: true,
            duedate: '',
            receipts: receipts,
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedBill: state.bill.selectedBill,
    subcontractors: state.subcontractor.list,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchSubcontractorList: (data) => dispatch(searchSubcontractors(data)),
    createSubcontractorItem: (item, billId) => dispatch(createSubcontracorAction(item, billId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalBill)
