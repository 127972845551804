import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { confirmModal } from '../Modal/ConfirmModal'
import '../../components/Card/Card.scss'
import { currencyFormatter } from '../Utils/formHelpers'
import {
  approveSubcontracorAction,
  declineSubcontracorAction,
} from '../../components/api/SubContractor/subContractorActions'

import ReactTooltip from 'react-tooltip'

class ContractorRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: null,
      declineModal: null,
    }
    this.openApproveModal = this.openApproveModal.bind(this)
    this.closeApproveModal = this.closeApproveModal.bind(this)
    this.openDeclineModal = this.openDeclineModal.bind(this)
    this.closeDeclineModal = this.closeDeclineModal.bind(this)
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  openApproveModal() {
    this.setState({
      modal: true,
    })
  }

  closeApproveModal = () => {
    this.setState({
      modal: null,
    })
  }

  openDeclineModal() {
    this.setState({
      declineModal: true,
    })
  }
  closeDeclineModal = () => {
    this.setState({
      declineModal: null,
    })
  }

  approveItem = () => {
    this.props.approveSubcontractorItem(this.props.item)
    this.closeApproveModal()
  }

  declineItem = () => {
    this.props.declineSubcontractorItem(this.props.item)
    this.closeDeclineModal()
  }

  getClassNameForState(data) {
    const state = data.state
    let className = ''
    if (state === 'OPEN') {
      className = 'title green'
    } else if (state === 'CLOSED') {
      className = 'title yellow'
    } else if (state === 'PAID') {
      className = 'title green'
    } else {
      className = 'title'
    }
    return className
  }

  render() {
    const { item, translate } = this.props
    const ConfirmModal = confirmModal(
      this.state.modal,
      translate('subcontracting.approve_subcontracting_row'),
      translate,
      this.closeApproveModal,
      this.approveItem,
    )
    const DeclineModal = confirmModal(
      this.state.declineModal,
      translate('subcontracting.decline_subcontracting_row'),
      translate,
      this.closeDeclineModal,
      this.declineItem,
    )
    return (
      <div className="card dkblue">
        <div className="card-header">
          <span className="title">{item.contractor.fullName}</span>
          <span className="subtitle">{item.description}</span>
        </div>
        <ul>
          <li>
            <span className="title green">{translate('subcontracting.' + item.state)}</span>
            <span className="text">Status</span>
          </li>
          <li>
            <span className="title">{item.date}</span>
            <span className="text">{translate('invoice.dueDate')}</span>
          </li>
          <li>
            <span className="title">{currencyFormatter(item.amount)} €</span>
            <span className="text">{translate('invoice.sum')}</span>
          </li>
          <li>
            <div className="btn-group pull-right">
              {item.state === 'WAITING_SUBCONTRACTOR' ? (
                <button
                  data-for="global-decline"
                  data-tip
                  data-iscapture="true"
                  onClick={this.openDeclineModal}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-no" />
                </button>
              ) : null}
            </div>
            <div className="btn-group pull-right">
              {item.state === 'WAITING_SUBCONTRACTOR' ? (
                <button
                  data-for="global-approve"
                  data-tip
                  data-iscapture="true"
                  onClick={this.openApproveModal}
                  type="button"
                  className="btn btn-square"
                >
                  <i className="glyphicon icon-yes" />
                </button>
              ) : null}
            </div>
          </li>
        </ul>
        {ConfirmModal}
        {DeclineModal}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    approveSubcontractorItem: item => dispatch(approveSubcontracorAction(item)),
    declineSubcontractorItem: item => dispatch(declineSubcontracorAction(item)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorRow)
