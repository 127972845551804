import {
  GET_PROFILE_PIC,
  UPLOAD_PROFILE_PIC,
  UPLOAD_TAX_CARD
} from './profileUploadsActions'

const profilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROFILE_PIC + '_SUCCESS':
      return {
        ...state,
        pic: action.response
      }
    case GET_PROFILE_PIC + '_FAILURE':
      return {
        ...state,
        pic: null
      }
    case UPLOAD_PROFILE_PIC + '_SUCCESS':
      return {
        ...state,
        lastUpload: action.response
      }
    case UPLOAD_PROFILE_PIC + '_FAILURE':
      return {
        ...state,
        lastUpload: null
      }
    case UPLOAD_TAX_CARD + '_SUCCESS':
      return {
        ...state,
        lastUpload: action.response
      }
    case UPLOAD_TAX_CARD + '_FAILURE':
      return {
        ...state,
        lastUpload: null
      }
    default:
      return state
  }
}

export default profilePicReducer
