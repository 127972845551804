import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import './privacy.scss'
import { PrivacyEn } from './privacyEn'
import { PrivacyFinnish } from './privacyFi'
class Privacy extends Component {
  render() {
    const { locale } = this.props
    const selectedLang = locale.languages.find(a => a.active === true)
    let privacyProlicy = null
    if (selectedLang.code === 'fi') {
      privacyProlicy = PrivacyFinnish()
    } else {
      privacyProlicy = PrivacyEn()
    }
    return (
      <div className="row">
        <div className="dashboard privacy">
          <div className="confines">
            <div className="col-lg-12">{privacyProlicy}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale),
    locale: state.locale,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy)
