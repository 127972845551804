import React from 'react'

export const PrivacyFinnish = () => {
  return (
    <div>
      <h2>Tietosuojaseloste</h2>
      <h3>1. Rekisterinpitäjä</h3>
      Ninjou Oy, myöhempänä myös pelkkä "Ninjou" Y-tunnus 2543310-1
      <h3> 2. Yhteyshenkilö</h3>
      Jani Puustinen admin@ninjou.fi
      <h3>3. Rekisterin nimi</h3>
      Ninjou Oy:n henkilö-, markkinointi- ja asiakasrekisteri
      <h3>4. Henkilötietojen käsittelyn tarkoitus</h3>
      Ninjou Oy:n palkanmaksu- ja laskutuspalvelun toiminta. Lisäksi Ninjou Oy:n palvelun käyttäjien
      tietojen analysointi sisäistä ja ulkoista markkinointia suunniteltaessa sekä
      tuotekehityksessä.
      <h3>5. Rekisterin tietosisältö</h3>
      Ninjou Oy kerää palvelun käyttäjistä seuraavat tiedot:
      <ul>
        <li>Nimi kokonaisuudessaan</li>
        <li>Henkilöturvatunnus</li>
        <li>Kotiosoite</li>
        <li>Puhelinnumero</li>
        <li>Sähköpostiosoite</li>
        <li>Pankkitilin numero</li>
        <li>Verokortin tiedot</li>
        <li>Laskutustiedot</li>
        <li>Käyttäjän asiakasrekisterin</li>
        <li>Palkkalaskelmat</li>
        <li>Ikä</li>
        <li>
          Käyttäjän toiminta Ninjou Oy:n extranetissä sis. kulloisenkin IP-osoitteen, josta sivuja
          on selattu
        </li>
      </ul>
      <h3>6. Säännönmukaiset tietolähteet</h3>
      Ninjou Oy käyttää toiminnassaan kohdan 5. mukaista, käyttäjiltä kerättyä tietoa. Ulkopuoliset
      tietolähteet ovat mm. vakuutusyhtiöt, viranomaisista vero- ja ulosottoviranomaiset sekä muut
      viranomaistahot, jotka Suomen lainsäädännön mukaan voivat Ninjou Oy:lle tietoja luovuttaa ja
      joilta Ninjou Oy:n on tietoja on lain mukaan vastaanotettava.
      <h3>7. Tietojen vastaanottajat, tietojen luovutus</h3>
      <p>
        Ninjou Oy:n henkilötietoja käsittelevät säännönmukaisesti vain Ninjou Oy:n palveluksessa
        sopimuksella työskentelevät luonnolliset henkilöt.
      </p>
      a) Ninjou Oy luovuttaa kolmannelle taholle kohdan 5. yksilöiviä henkilötiedoista vain
      suomalaisten viranomaisten pyynnöstä, Suomen lain mukaisesti seuraavia tietoja:
      <ul>
        <li>Nimi kokonaisuudessaan</li>
        <li>Henkilöturvatunnus</li>
        <li>Kotiosoite</li>
        <li>Puhelinnumero</li>
        <li>Sähköpostiosoite</li>
        <li>Pankkitilin numero</li>
        <li>Verokortin tiedot</li>
      </ul>
      b) Ninjou käyttää käyttäjiltään keräämää tietoa digitaalisisa analyysipalveluissa. Käyttäjää
      ei voi tässä yhteydessä yksilöidä, koska käytetty tieto ei sisällä henkilötietoja.
      Analyysipalveluihin käytetään seuraavia tietoja:
      <ul>
        <li>Laskutustiedot</li>
        <li>Käyttäjän asiakasrekisteri</li>
        <li>Palkkatiedot</li>
        <li>
          Käyttäjän toiminta Ninjou Oy:n extranetissä, sis. hiiren osoittimen liikkeet, kenttiin
        </li>
        <li>
          kirjoittaminen, selaimen tiedot ja kulloisenkin IP-osoitteen, josta sivuja on selattu
        </li>
        <li>Aikaleimat</li>
      </ul>
      <h3>8. Tietojen siirto EU:n tai ETA-alueen ulkopuolelle</h3>
      Ninjou Oy ei luovuta tietoja EU:n ulkopuolelle. Yksityishenkilöön yhdistettäviä tietoja
      luovutetaan vain Suomen viranomaisten pyynnöstä.
      <h3> Rekisterin suojauksen periaatteet</h3>
      Asiakas- ja henkilötietoja säilytetään rekisterinpitäjän toimitiloissa, jotka ovat lukittu.
      Rekisteriin on pääsy vain vain Ninjou Oy:n palveluksessa toimivat henkilöt, jotka tarvitsevat
      tietoja tehtävissään. Digitaaliset käyttäjätiedot ovat suojattu teknisesti eristämällä
      tietovarastot julkisesta verkosta ja palvelu, jonka kautta tietoihin voi päästä, on suojattu
      palomuurilla ja käyttöoikeuksilla. Tietoja siirretään vain suojatun yhteyden välityksellä.
      Paperiset dokumentit siirretään pääsääntöisesti digitaaliseen muotoon ja säilytetään vahvan
      suojauksen pilvipalvelussa. Kirjanpito ja muu paperimuodossa säilytettävä aineisto säilytetään
      ulkopuolisilta lukitussa tilassa.
      <h3>10. Tietojen säilytysaika</h3>
      Kohdan 7 b:n yksilöimättömiä tietoja säilytetään ulkopuolisissa analyysipalveluissa
      maksimissaan 120 päivää. Digitaalisessa muodossa olevaa, kirjanpitolain alaista laskutus- ja
      palkkatietoa säilytetään maksimissaan 6 vuotta, jollei käyttäjä päätä asiakassuhdetta ennen
      tätä. Paperisessa muodossa olevaa laskutus- ja palkkatietoa säilytetään maksimissaan 6 vuotta.
      <h3>11. Rekisteröidyn yleiset oikeudet</h3>
      Ninjoun palveluun rekisteröityneellä yksityishenkilöllä on oikeus tarkastella, muuttaa ja
      poistaa vapaasti omia tietojaan. Ninjou ei takaa palvelun toimivuutta, jos tiedoista
      poistetaan:
      <ul>
        <li>Tilinumero</li>
        <li>Veroprosentti</li>
        <li>Osoite</li>
        <li>Puhelinnumero</li>
        <li>Sähköpostiosoite</li>
      </ul>
      <h3>12. Muut rekisteröidyn oikeudet</h3>
      Käyttäjällä on oikeus siirtää omia tietojaan haluamilleen tahoille. Käyttäjällä on oikeus
      vastustaa omien tietojensa siirtämistä ulkopuolisille tahoille, jos ulkopuolinen taho ei ole
      suomalainen viranomainen, jolle lain mukaan tietoja on luovutettava. Tästä pitää ilmoittaa
      asiakaspalveluun sähköpostilla osoitteeseen admin@ninjou.fi . Käyttäjällä on oikeus olla
      joutumatta automatisoidun päätöksenteon kohteeksi. Tästä pitää ilmoittaa asiakaspalveluun
      sähköpostilla osoitteeseen admin@ninjou.fi . Ninjoun palveluun rekisteröityneellä käyttäjällä
      on oikeus tulla kokonaan unohdetuksi asiakassuhteen päättymisen jälkeen. Alla ohjeet
      asiakassuhteen päättämiseen ja unohdetuksi tulemiseen. Asiakassuhteen voi päättää
      kirjallisesti osoitteesta www.ninjou.fi löytyvällä palautelomaketta, sähköpostitse osoitteessa
      admin@ninjou.fi . Kalenterikuukauden kuluttua, asiakassuhteen päättymisen jälkeen, Ninjou
      poistaa kaiken digitaalisessa muodossa olevan, käyttäjää koskevan ja yksilöivän tiedon
      palvelustaan. Käyttäjä ei voi tämän ajan jälkeen noutaa itseään koskevia palkkatodistuksia tai
      muita dokumentteja palvelusta, eikä Ninjou voi niitä käyttäjälle toimittaa. Paperisessa
      muodossa olevaa, yksilöivää laskutus- ja palkanmaksutietoa säilytetään kirjanpitolain
      mukaisesti 6 vuotta.
    </div>
  )
}
