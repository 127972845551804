import React, {Component} from 'react'
import {connect} from 'react-redux'
import {IndexLink, Link} from 'react-router'
import NinjouImage from '../Header/assets/ninjou_logo.png'
import Hamburger from '../Header/assets/hamburger.png'
import {getActiveLanguage, getTranslate} from 'react-localize-redux'
import './Sidebar.scss'

/* eslint jsx-a11y/anchor-has-content: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  componentDidMount() {
    const { user } = this.props
    if (!user || !user.authtoken) {
      return null
    }
  }

  toggleSidebar = () => {
    const opened = (this.props.width >= 1280 && this.state.opened === null) || this.state.opened
    this.setState({ opened: !opened })
  }

  handleClick = () => {
    if (this.props.width < 1280) {
      this.toggleSidebar()
    }
  }

  render() {
    const { user, translate } = this.props
    const logo = `url(${NinjouImage})`
    const hamb = `url(${Hamburger})`
    const opened = (this.props.width >= 1280 && this.state.opened === null) || this.state.opened
    const sidebarClasses = opened ? 'sidebar sidebar-opened' : 'sidebar sidebar-minimized'
    if (!user || !user.authtoken) {
      return <div>{this.props.children}</div>
    }
    return (
      <div className={sidebarClasses} style={{ position: 'relative' }}>
        <aside className="sidebar">
          <div className="header navbar-header">
            <IndexLink style={{ backgroundImage: logo }} to="/" className="ninjou-logo">
              Ninjou
            </IndexLink>
            <a
              style={{ backgroundImage: hamb }}
              className="sidebar-toggle"
              onClick={this.toggleSidebar}
              aria-hidden="true"
            ></a>
          </div>
          <ul className="nav panel-list">
            <li onClick={this.handleClick}>
              <IndexLink to="/" activeClassName="route--active">
                <i className="icon-home"></i>
                <span className="menu-text">{translate('sideMenu.home')}</span>
              </IndexLink>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/invoices" activeClassName="route--active">
                <i className="icon-document"></i>
                <span className="menu-text">{translate('sideMenu.invoices')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/customers" activeClassName="route--active">
                <i className="icon-clients"></i>
                <span className="menu-text">{translate('sideMenu.customers')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/archive" activeClassName="route--active">
                <i className="icon-archive"></i>
                <span className="menu-text">{translate('sideMenu.invoiceArchive')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/salaries" activeClassName="route--active">
                <i className="icon-wallet"></i>
                <span className="menu-text">{translate('sideMenu.salaries')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/tax" activeClassName="route--active">
                <i className="icon-wallet"></i>
                <span className="menu-text">{translate('sideMenu.taxInfo')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/templates" activeClassName="route--active">
                <i className="icon-heart"></i>
                <span className="menu-text">{translate('sideMenu.templates')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/calculator" activeClassName="route--active">
                <i className="icon-calculator"></i>
                <span className="menu-text">{translate('sideMenu.calculator')}</span>
              </Link>
            </li>
            <li onClick={this.handleClick}>
              <Link to="/account" activeClassName="route--active">
                <i className="icon-account"></i>
                <span className="menu-text">{translate('sideMenu.myAccount')}</span>
              </Link>
            </li>
          </ul>
          <div className="side-footer">
            <ul className="nav">
              <li>
                <Link to="/about">
                  <i className="icon-question"></i>
                  <span className="menu-text">{translate('sideMenu.about')}</span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: getActiveLanguage(state.locale).code,
    translate: getTranslate(state.locale),
    user: state.user.user,
    location: state.location || {},
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    checkUser: value => dispatch({ type: 'CHECK_USER' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
