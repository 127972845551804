import React, { Component } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import BillEditor from '../../../components/BillEditor'
import { selectBillAction } from '../../../components/api/Bill/billActions'
import { path } from 'ramda'

class InvoiceView extends Component {
  checkState(props) {
    const { bills, billId, selectedBill } = props
    let foundBill
    if (billId && bills && !selectedBill) {
      bills.forEach((bill) => {
        if (bill.id === billId) {
          foundBill = billId
        }
      })
      if (!foundBill) {
        this.props.router.push('/invoices')
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // perform any preparations for an upcoming update
    this.checkState(nextProps)
  }

  componentDidMount() {
    const { billId } = this.props
    this.props.selectBill(billId)
  }

  close() {
    this.props.router.goBack()
  }

  renderBill(bill) {
    if (bill) {
      return <BillEditor bill={bill} onClose={this.close.bind(this)} />
    }
    return <div></div>
  }

  render() {
    const { selectedBill } = this.props
    let bill = this.renderBill(selectedBill)
    return <div>{bill}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bills: state.bills.list,
    selectedBill: state.bill.selectedBill,
    translate: getTranslate(state.locale),
    info: state.info.info || {},
    user: state.user.user,
    templates: state.templates,
    allCustomers: state.info.foudCustomers,
    billId: path(['locationBeforeTransitions', 'query', 'id'], state.routing),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectBill: id => dispatch(selectBillAction(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView)
