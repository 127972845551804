import { combineReducers } from 'redux'
import locationReducer from './location'
import { routerReducer } from 'react-router-redux'
import { localeReducer } from 'react-localize-redux'
import userReducer from '../components/User/userReducer'
import infoReducer from '../components/api/Info/infoReducer'
import customersReducer from '../components/api/Customers/customersReducer'
import salariesReducer from '../components/api/Salaries/salariesReducer'
import templatesReducer from '../components/api/Templates/templatesReducer'
import billsReducer from '../components/api/Bills/billsReducer'
import profileUploadsReducer from '../components/api/ProfileUploads/profileUploadsReducer'
import calculatorReducer from '../components/api/Calculator/calculatorReducer'
import partialCalculatorReducer from '../components/api/Calculator/partialCalculatorReducer'
import notificationReducer from '../components/api/Notification/notificationReducer'
import billReducer from '../components/api/Bill/billReducer'
import registerReducer from '../routes/Register/registerReducer'
import subcontractorReducer from '../components/api/SubContractor/subbcontractorReducer'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    location: locationReducer,
    user: userReducer,
    locale: localeReducer,
    info: infoReducer,
    salaries: salariesReducer,
    profileUploads: profileUploadsReducer,
    templates: templatesReducer,
    bills: billsReducer,
    calculator: calculatorReducer,
    partialCalculator: partialCalculatorReducer,
    notification: notificationReducer,
    routing: routerReducer,
    bill: billReducer,
    customers: customersReducer,
    register: registerReducer,
    subcontractor: subcontractorReducer,
    ...asyncReducers,
  })
}

export const injectReducer = (store, {key, reducer}) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
