import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

class LoginView extends Component {

  componentDidMount() {
    this.props.logout()
  }

  shouldComponentUpdate() {
    return false
  }


  render() {
    return (
      <div className="row">
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    translate: getTranslate(state.locale)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => dispatch({ type: 'LOGOUT' })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView)
