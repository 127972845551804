import passwordLayout from './components/passwordLayout'
import passwordReset from './components/passwordReset'
import checkEmail from './components/checkEmail'
import checkEmailFailure from './components/checkEmailFailure'
import passwordChange from './components/passwordChange'
import successChange from './components/successChange'
import failedChange from './components/failedChange'
import success from './components/success'

import React from 'react'
import {Route} from 'react-router'

const register = (
  <Route path="password" component={passwordLayout}>
    <Route path="reset" component={passwordReset}></Route>
    <Route path="checkemail" component={checkEmail}></Route>
    <Route path="checkemail-failure" component={checkEmailFailure}></Route>
    <Route path="forgot/:token" component={passwordChange}></Route>
    <Route path="changed" component={successChange}></Route>
    <Route path="changed-failure" component={failedChange}></Route>
    <Route path="success" component={success}></Route>
  </Route>
)

export default register
