import React from 'react'
import {Formik} from 'formik'

const validateFn = values => {
  let errors = {}
  if (!values.password) {
    errors.password = 'validation.required'
  }
  if (values.password !== values.password2) {
    errors.password = 'account.passwords-do-not-match'
    errors.password2 = 'account.passwords-do-not-match'
  }
  if (!values.password2) {
    errors.password2 = 'validation.required'
  }
  if (!values.password2) {
    errors.password2 = 'validation.required'
  }
  return errors
}

const PasswordForm = (props) => {
  const { handleSubmit, translate } = props
  const initialValues = {
    password: '',
    password2: ''
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={validateFn}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        validateForm
        /* and other goodies */
      }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="username"
              autoComplete="username email"
              style={{ display: 'none' }}
            />
            <div className="col-xs-12 col-sm-6">
              <div className="form-group">
                <label>{translate('account.newPass')}</label>
                <div>
                  <input
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    className="form-control input-lg"
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.password && touched.password && <span className="field-error">{translate(errors.password)}</span>}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="form-group">
                <label>{translate('account.confPass')}</label>
                <div>
                  <input
                    type="password"
                    name="password2"
                    autoComplete="new-password"
                    onChange={handleChange}
                    className="form-control input-lg"
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.password2 && touched.password2 && <span className="field-error">{translate(errors.password2)}</span>}
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="pull-right">
                <button type="submit" className="btn btn-success">
                  {translate('account.savePass')}
                </button>
              </div>
            </div>
          </form>
        )}
    </Formik>
  )
}
export default PasswordForm
